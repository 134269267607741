import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../../../shared/components/dialog/base-dialog.component';
import * as echarts from 'echarts';
import {DefibrillatorService} from '../../../../../../core/services/defibrillator.service';
import {catchError, forkJoin, interval, Observable, of, Subscription} from 'rxjs';
import {EkgData} from '../../../../../../shared/models/ekgdata.model';
import {EventData} from '../../../../../../shared/models/eventdata.model';
import {HealthCaseEkgData} from '../../../../../../shared/models/healthCaseEkgData.model';
import {NGSIQuery} from '../../../../../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../../../../../shared/models/ngsi/ngsi-filter.model';
import {NGSIEntity} from '../../../../../../shared/utils/ngsi-result-util';
import {QueryOptions} from '../../../../../../shared/models/ngsi/query-options';
import {filter, map, take} from 'rxjs/operators';
import {NGSIEventType, Notification} from '../../../../../../shared/models/notification.model';
import {EcgService} from '../../../../../../core/services/data/ecg.service';

interface DataItem {
  name: String;
  value: [String, Number];
}
@Component({
  templateUrl: './defibrillator-ekgdialog.component.html',
  styleUrls: ['./defibrillator-ekgdialog.component.scss']
})
export class DefibrillatorEkgDialogComponent extends BaseDialogComponent implements OnInit, OnDestroy {

  @Input() healthCaseId: String;
  @Input() healthCaseStartDate: String;
  @Input() positionStatus: number;
  @Input() caseIndex: number;
  @Input() cBrokerEndpoint: string;
  @Input() defibrillatorId: string;
  echartsInstance: echarts.ECharts;
  healthCaseEkgDataArray: HealthCaseEkgData[];
  dataWithAttributes: DataItem[];
  entityId: String;
  entityStartDate: String;
  entityFinishTime: Date;
  defibrillatorService: DefibrillatorService;
  ecgService: EcgService;
  eventEntity: any;
  graphicDate: String;
  intervalId: any;
  intervalIdUpdate: any;
  isEkgFinish: boolean = false;
  ekgQueryCount: number = 0;
  startToolbarDate: String;
  option: any;
  isEkgLive: boolean = false;
  isScreenClosed: boolean = false;
  lastDataTime: Date;
  isWorking: boolean = false;
  updatedData: DataItem[];
  shouldUnsubscribe: boolean = false;
  downloadableData: DataItem[];
  refreshingIntervalId: Subscription;
  connectionCounter: number = 0;
  connectionTypeName: string;
  signalQuality: string;
  signalQualityIcon: string;
  colorSignalQuality: string;
  signalQualityMap: string[] = ['Kritik Sinyal', 'Düşük Sinyal', 'Normal Sinyal', 'İyi Sinyal'];
  ekgContainerTitle: string;
  connectionTextImgSource: string;
  connectionStatusImgSource: string;
  lastObservedatTime: String;

  constructor(protected injector: Injector) {
    super(injector);
    this.updatedData = [];
    this.downloadableData = [];
    this.healthCaseEkgDataArray = [];
    this.defibrillatorService = injector.get(DefibrillatorService);
    this.ecgService = this.injector.get(EcgService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.intervalId) {
      this.intervalId.unsubscribe();
    }
    if (this.intervalIdUpdate) {
      this.intervalIdUpdate.unsubscribe();
    }
    if (this.echartsInstance) {
      this.echartsInstance.dispose();
    }
    this.isScreenClosed = true;
    this.shouldUnsubscribe = true;
    this.updatedData = [];
    this.downloadableData = [];
    this.echartsInstance.dispose();
    if (this.refreshingIntervalId) {
      this.refreshingIntervalId.unsubscribe();
    }

  }

  ngOnInit(): void {
    this.dataWithAttributes = [];
    this.entityId = this.healthCaseId;
    this.isEkgLive = (this.positionStatus === 2 && this.caseIndex === 0);
    this.lastDataTime = new Date();
    this.updatedData = [];
    this.downloadableData = [];
    this.healthCaseEkgDataArray = [];
    this.drawChart();
    this.graphicDate = new Date(this.healthCaseStartDate.toString()).toISOString().split('T')[0];
    this.ecgService.initializeEcgDisplayFilterAlgo();
    if (this.isEkgLive) {
      this.subscribeWebsocket();
    } else {
      this.drawChart();
      this.entityStartDate = this.healthCaseStartDate;
      this.entityFinishTime = new Date(this.entityStartDate.toString());
      this.getEkg(1); // date transfer mode
    }
  }

  async getEkg(dataTransferMode) {
    this.createConnectionIconSources();
    const finishTime: String = new Date( this.entityFinishTime.getTime() + 2 * 60 * 1000).toISOString();

    const result = await this.defibrillatorService.getEkgValues(this.entityId, this.entityStartDate, finishTime, this.cBrokerEndpoint).subscribe(
      (response: any) => {
        if (response !== null && response[0] !== null && response[0].ekgData.length > 0) {
          const newDownloadableData: DataItem[] = response[0].ekgData.map(item => ({
            name: item.observedAt,
            value: [item.observedAt, item.value]
          }));
          this.downloadableData.push(...newDownloadableData);
          response[0].ekgData = this.ecgService.ecgDisplayFilterAlgoFlow(response[0].ekgData);
          this.entityFinishTime = new Date(finishTime.toString());
          this.healthCaseEkgDataArray.push(...this.mergeList(response));
          this.startToolbarDate =  this.healthCaseEkgDataArray[0].observedAt;
          if (!this.isEkgFinish) {
            if (this.ekgQueryCount === 0) {
              this.entityStartDate = new Date(new Date(this.entityStartDate.toString()).getTime() + 2 * 60 * 1000 + 1).toISOString();
            } else {
              this.entityStartDate = new Date(new Date(this.entityStartDate.toString()).getTime() + 2 * 60 * 1000).toISOString();
            }
            this.ekgQueryCount = this.ekgQueryCount + 1;
          }
          if (!this.isScreenClosed) {
            const p = Promise.resolve(this.updateChart(dataTransferMode));
            p.then((v) => {
              this.getEkg(1);
            });
          }
        } else {
          if (!this.isScreenClosed) {
            this.updateChart(0);
          }
          this.isEkgFinish = true;
          if (this.intervalId) {
            this.intervalId.unsubscribe();
          }
          return;
        }
      },
      (error: any) => {
        console.error('HTTP İstek Hatası:', error);
      }
    );
  }


  async getEvent() {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    let contextObservable: Observable<any> = null;

    const eventQuery: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.HEALTHCASEEVENT] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'refId'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            this.healthCaseId
          ]
        }
      }
    });

    const eventRef =  this.contextService.getEntities(eventQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));
    contextObservable = forkJoin([eventRef]).pipe(
      take(1),
      map(response => {
        return {
          eventEntity: response[0]
        };
      })
    );
    contextObservable.pipe(
      take(1)
    ).subscribe(context => {
      this.eventEntity = context.eventEntity;
    });
  }

  subscribeWebsocket(): void {
    this.lastObservedatTime = null;
    this.refreshingIntervalId = interval(1000).subscribe(() => {
      this.connectionCounter++;
      this.createConnectionIconSources();
    });
    this.socketService.subscribe('a375554a-d069-4a2f-9d85-10cce460ee55')
      .pipe(
        filter(() => !this.shouldUnsubscribe),
      )
      .subscribe(notification => {
        this.websocketCallback(notification);
      });
    this.socketService.subscribe('240ea1c2-cecb-4c0f-8459-c845b7314d41')
      .pipe(
        filter(() => !this.shouldUnsubscribe),
      )
      .subscribe(notification => {
        this.websocketCallback(notification);
      });
  }
  protected websocketCallback(notification: Notification): void {
    if (notification.eventClass === 'NGSIEvent') {
      if(notification.entityId.includes('HealthCase')) {
        if (notification.eventPayload.eventType === NGSIEventType.UPDATE_TEMPORAL && this.healthCaseId === notification.eventPayload.content.id) {
          this.lastDataTime = notification.eventPayload.content.ekgData[notification.eventPayload.content.ekgData.length-1] !== undefined ?
            new Date(notification.eventPayload.content.ekgData[notification.eventPayload.content.ekgData.length-1].observedAt) : new Date();
          this.healthCaseEkgDataArray = this.mergeListWithSubs(notification.eventPayload.content.ekgData);
          if(notification.eventPayload.content.ekgData.length > 0){
            if(this.lastDataTime.getTime() - new Date(notification.eventPayload.content.ekgData[0].observedAt).getTime() > 60000){
              this.downloadableData = this.healthCaseEkgDataArray.map(item => ({
                name: item.name,
                value: [item.name, item.value]
              }));
              return;
            }
          }
          if (!this.isWorking) {
            this.updatedData = this.ecgService.ecgDisplayFilterAlgoFlow(this.healthCaseEkgDataArray).map(item => ({
              name: item.name,
              value: [item.name, item.value]
            }));
            this.downloadableData = this.healthCaseEkgDataArray.map(item => ({
              name: item.name,
              value: [item.name, item.value]
            }));
            this.updateChartForLiveEkg();
          } else {
            const newUpdatedData: DataItem[] = this.ecgService.ecgDisplayFilterAlgoFlow(this.healthCaseEkgDataArray).map(item => ({
              name: item.name,
              value: [item.name, item.value]
            }));
            this.updatedData.push(...newUpdatedData);

            const newDownloadableData: DataItem[] = this.healthCaseEkgDataArray.map(item => ({
              name: item.name,
              value: [item.name, item.value]
            }));
            this.downloadableData.push(...newDownloadableData);
          }
        }
      } else if(notification.entityId.includes('Position')) {
        if (this.defibrillatorId === notification.eventPayload.content.refId.object.split(':')[3]) {
          this.checkConnectionStatusType(notification.eventPayload.content.iotGsmConnectionType?.value, notification.eventPayload.content.iotGsmSignalQualityLte?.value , notification.eventPayload.content.iotGsmSignalQuality2G?.value);
        }
      }
    }
  }
  getSymbolSize(color) {
    if (color === 'black') {
      return 5;
    } else {
      return 15;
    }
  }
  mergeListWithSubs(ekgData: any[]) {
    const ekgList: EkgData[] = [];
    const eventList: EventData[] = [];
    let validData = true;

    if (ekgData.length > 0 && this.lastObservedatTime !== null) {
      validData = false;
      let datePart = [];
      datePart = ekgData[0].observedAt.split('T');
      const date = this.replaceDashWithSingleSlash(datePart[0]);
      const timePart = datePart[1].split('Z')[0];
      const firstObservedAt = date + ' ' + this.removeCommas(timePart);

      if (firstObservedAt > this.lastObservedatTime) {
        validData = true;
      }
    }

    if (validData) {
      for (let i = 0; i < ekgData.length; i++) {
        const ekgValue = ekgData[i].value;
        let datePart = [];
        datePart = ekgData[i].observedAt.split('T');
        const date = this.replaceDashWithSingleSlash(datePart[0]);
        const timePart = datePart[1].split('Z')[0];
        const observedAt = date + ' ' + this.removeCommas(timePart);
        const newEkgData: { observedAt: String; ekgData: Number } = {
          ekgData: ekgValue,
          observedAt: observedAt
        };
        ekgList.push(newEkgData);
      }
      this.lastObservedatTime = ekgList[ekgList.length - 1].observedAt;
    }

    const mergedList = [];

    ekgList.forEach(ekgItem => {
      const mergedItem = { name: ekgItem.observedAt, value: ekgItem.ekgData};
      mergedList.push(mergedItem);
    });
    return mergedList;
  }

  mergeList(ekgEntity) {
    const ekgList: EkgData[] = [];
    const eventList: EventData[] = [];
    let prevObservedAt = null;

    for (let i = 0; i < ekgEntity[0].ekgData.length; i++) {
      const ekgValue = ekgEntity[0].ekgData[i].value;
      let datePart = [] ;
      datePart = ekgEntity[0].ekgData[i].observedAt.split('T');
      const date = this.replaceDashWithSingleSlash(datePart[0]);
      const timePart = datePart[1].split('Z')[0];
      const observedAt = date + ' ' + this.removeCommas(timePart);
      if (observedAt !== prevObservedAt) {
        const newEkgData = {
          ekgData: ekgValue,
          observedAt: observedAt
        };
        ekgList.push(newEkgData);
        prevObservedAt = observedAt;
      }
    }

    /*if (this.eventEntity !== undefined) {
      for (let i = 0; i < this.eventEntity.results.length; i++) {
        const eventValue = this.eventEntity.results[i].result.entity.eventValue.value;
        const eventType = this.eventEntity.results[i].result.entity.eventType.value;
        const observedAt = this.eventEntity.results[i].result.entity.eventValue.observedAt;
        const newEventData: { observedAt: String; eventType: Number; eventValue: string } = {
          eventValue: eventValue,
          eventType: eventType,
          observedAt: observedAt
        };
        eventList.push(newEventData);
      }

    }*/
    const mergedList = [];

    ekgList.forEach(ekgItem => {

      const mergedItem = { name: ekgItem.observedAt, value: ekgItem.ekgData};
      /*const matchingEvent = eventList.find(eventItem => eventItem.observedAt === ekgItem.observedAt);
      if (matchingEvent) {
        mergedItem.eventValue = matchingEvent.eventValue.toString();
        mergedItem.eventType = matchingEvent.eventType;
        mergedItem.eventColor = 'red';
      }
      mergedItem.eventValue = '-';
      mergedItem.eventType =  0;*/
      mergedList.push(mergedItem);
    });

    return mergedList;
  }

  drawChart() {
    this.createConnectionIconSources();
    const parsedData: DataItem[] = this.healthCaseEkgDataArray.map(item => ({
      name: item.name,
      value: [item.name, item.value]  //
    }));

    this.dataWithAttributes = parsedData;
    this.ekgContainerTitle = 'Ekg Bekleniyor';
    this.option =       {
      backgroundColor: 'transparent',
      xAxis: [{
        type: 'time',
        name: 'sn',
        nameLocation: 'middle',
        nameGap: 15,
        axisLabel: {
          show: false,
        }
      }
      ],
      lineStyle: {
        color: '#00FF00'
      },
      yAxis: {
        type: 'value',
        name: 'mV',
        nameLocation: 'middle',
        nameGap: 25,
        min : -5,
        max : 5
      },
      series: [{
        showSymbol: false,
        data: this.dataWithAttributes,
        type: 'line'
      }]
    };

    this.echartsInstance = echarts.init(document.getElementById('ekgChart'));
    this.echartsInstance.setOption(this.option);
  }

  updateChartForLiveEkg() {
    let dataIndex = 0;
    this.isWorking = true;
    const animationDuration = 2.5;
    let hasData = true;
    const addDataToChart = () => {
      if (hasData) {
        if (this.updatedData.length >= 3) { // 1 second data
          const first250Elements = this.updatedData.splice(0, 3);
          if (this.dataWithAttributes.length > 1000) {
            this.dataWithAttributes.splice(0, 3);
          }
          this.dataWithAttributes.push(...first250Elements);
        } else if (this.updatedData.length > 0 && this.updatedData.length < 3 ) {
          const firstNElements = this.updatedData.splice(0, this.updatedData.length);
          if (this.dataWithAttributes.length > 1000) {
            this.dataWithAttributes.splice(0, this.updatedData.length);
          }
          hasData = false;
          this.dataWithAttributes.push(...firstNElements);
        }
        this.ekgContainerTitle = 'Vaka Başlangıç Tarihi :' + this.graphicDate;
        this.echartsInstance.setOption<echarts.EChartsOption>({
          series: [
            {
              data: this.dataWithAttributes,
              smooth: false
            }
          ]
        });
        dataIndex = dataIndex + 3;
        if (!this.isScreenClosed) {
          setTimeout(addDataToChart, animationDuration);
        }
      } else {
        this.isWorking = false;
      }
    };
    addDataToChart();
  }

  updateChart(dataTransferMode: number): Promise<number> {
    return new Promise((resolve) => {

      if (dataTransferMode === 1) { // data transfer continue
        let updatedData: DataItem[];
        updatedData = this.healthCaseEkgDataArray.map(item => ({
          name: item.name,
          value: [item.name, item.value]
        }));
        this.healthCaseEkgDataArray = [];
        this.dataWithAttributes.push(...updatedData);
        if (this.intervalIdUpdate) {
          this.intervalIdUpdate.unsubscribe();
        }

        let showSliderStartIndex;
        if (this.dataWithAttributes.length > 500) {
          showSliderStartIndex = 500;
        } else {
          showSliderStartIndex = updatedData.length - 1;
        }

        if (updatedData.length > 1) {

          this.ekgContainerTitle = 'Ekg Verisi Yükleniyor';
          this.echartsInstance.setOption<echarts.EChartsOption>({
            dataZoom: [
              {
                startValue: this.dataWithAttributes[this.dataWithAttributes.length - showSliderStartIndex].name.toString()
              },
              {
                type: 'inside'
              }
            ],
            series: [
              {
                data: this.dataWithAttributes,
                smooth: true
              }
            ]
          });
        }
      } else {
        this.ekgContainerTitle = 'Vaka Başlangıç Tarihi :' + this.graphicDate;
      }
      resolve(1);
   });
  }
  replaceDashWithSingleSlash(inputString) {
    let outputString = inputString.replace(/-/g, '/');
    outputString = outputString.replace(/\/+/g, '/');
    return outputString;
  }

  removeCommas(inputString: string) {
    return inputString.replace(/,/g, '');
  }

  private isConnectionAlive() {
    const difference = new Date().getTime() - this.lastDataTime.getTime();
    console.log(difference < 20000);
    return difference < 20000;
  }

  downloadEkgData() {
    const data = this.downloadableData;
    let txtContent = 'data:text/plain;charset=utf-8,';
    data.forEach(function (rowObject) {
      const row = Object.values(rowObject).join(',');
      txtContent += row + '\r\n';
    });
    const encodedUri = encodeURI(txtContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'ekgData.txt');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private createConnectionIconSources() {
    this.connectionStatusImgSource = !this.isEkgLive ? '/assets/images/recorded-data-streaming.svg' : this.isConnectionAlive() ? '/assets/images/connection'+this.connectionCounter%3+'.svg' : '/assets/images/connectionLost.svg';
    if (!this.isEkgLive && this.dataWithAttributes.length === 0) {
      this.connectionStatusImgSource = '/assets/images/connectionLost.svg';
    }
  }

  private checkConnectionStatusType(gsmConnectionType, gsmLteQuality, gsm2gQuality) {
    if (gsmConnectionType === 7) {
      this.connectionTypeName = 'LTE';
      this.calculateSignalQualityLTE(gsmLteQuality);
    } else {
      this.connectionTypeName = '2G';
      this.calculateSignalQuality2G(gsm2gQuality);
    }
    this.getSignalQualityIcon(this.signalQuality);
  }

  private calculateSignalQuality2G(gsmSignalQuality) {
    if (gsmSignalQuality === 0) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality === 1) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality === 31) {
      this.signalQuality = this.signalQualityMap[3];
    } else {
      this.signalQuality = this.signalQualityMap[2];
    }
  }

  private calculateSignalQualityLTE(gsmSignalQuality) {
    if (gsmSignalQuality < -100) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality < -90 && gsmSignalQuality >= -100) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality < -80 && gsmSignalQuality >= -90) {
      this.signalQuality = this.signalQualityMap[2];
    } else {
      this.signalQuality = this.signalQualityMap[3];
    }
  }

  private getSignalQualityIcon(signalQuality) {
    if (signalQuality === this.signalQualityMap[0]) {
      this.signalQualityIcon = 'assets/images/critical-signal.png';
      this.colorSignalQuality = 'red';
    } else if (signalQuality === this.signalQualityMap[1]) {
      this.signalQualityIcon = 'assets/images/low-signal.png';
      this.colorSignalQuality = 'yellow';
    } else if (signalQuality === this.signalQualityMap[2]) {
      this.signalQualityIcon = 'assets/images/normal-signal.png';
      this.colorSignalQuality = 'green';
    } else {
      this.signalQualityIcon = 'assets/images/good-signal.png';
      this.colorSignalQuality = 'green';
    }
  }
}
