import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RealmItem } from '../../../shared/models/device-management/realmitem';
import { ClientItem } from '../../../shared/models/device-management/clientitem';
import {AuthService} from "../auth/auth.service";
import {UserItem} from "../../../shared/models/device-management/UserItem";
import {StorageUtil} from "../../../shared/utils/storage-util";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KeycloakService{

  private baseUrl = environment.server.deviceManagementApi+'/api/keycloak';

  constructor(private http: HttpClient, private authService: AuthService) {}

  private getHttpHeaders(): HttpHeaders {
    const accessToken = StorageUtil.getUMATicket();

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return headers;
  }

  getRealms(): Observable<RealmItem[]> {
    return this.http.get<RealmItem[]>(`${this.baseUrl}/realms`, {
      headers: this.getHttpHeaders()
    });
  }

  getClientsForRealm(realmId: string): Observable<ClientItem[]> {
    return this.http.get<ClientItem[]>(`${this.baseUrl}/clients/${realmId}`, {
      headers: this.getHttpHeaders()
    });
  }

  getUsersForRealmAndRole(realmId: string, roleName: string = 'test-user'): Observable<UserItem[]> {
    return this.http.get<UserItem[]>(`${this.baseUrl}/users/${realmId}/`+roleName, {
      headers: this.getHttpHeaders()
    });
  }
}
