import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';

@Component({
  selector: 'uruk-line-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class LineChartComponent extends ChartComponent implements OnInit {

  // labels for the dimension axis
  @Input() labels: string[];

  // labels for multi/single series data
  @Input() seriesLabels: string[];

  // list of colors for the line; if more than one color are provided, then a gradient will be created
  @Input() lineColors: string[];

  // Determines the smoothness of the line chart
  @Input() smooth: boolean = false;

  // Determines the visibility of the area below the chart
  @Input() displayArea: boolean = false;

  // Determines the visibility of circular symbols (at each x-y value) on the line chart
  @Input() displaySymbols: boolean = true;

  // Determines the visibility of split lines parallel to x-axis
  @Input() displaySplitLines: boolean = true;

  // responsiveness parameters
  private baseMarginPercentage = 6; // maximum possible margin for the minimum-dimension container
  private baseSymbolSize = 4; // minimum size of the line intersection symbols
  private baseLineWidth = 1; // minimum line width
  private marginScaleFactor = 300; // a scale factor to determine the responsive font size depending on the dimension
  private symbolSizeScaleFactor = 300; // a scale factor to determine the responsive symbol size depending on the dimension
  private lineWidthScaleFactor = 100; // a scale factor to determine the responsive line width
  private tooltipFontScaleFactor = 1.25; // a scale factor to determine the responsive font size for tooltips

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    const defaultOptions: any = {
      series: [{
        type: 'line',
        name: this.seriesName || this.defaultSeriesName,
        smooth: this.smooth,
        showSymbol: this.displaySymbols
      }],
      xAxis: {
        type: 'category',
        axisLabel: {
          fontWeight: 'bold'
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          fontWeight: 'bold',
          formatter: this.valueAxisFormatter.bind(this)
        },
        splitLine: {
          show: this.displaySplitLines
        },
      },
      grid: {
        containLabel: true
      },
      tooltip: {
        show: true,
        trigger: this.displaySymbols ? 'item' : 'axis',
        // formatter: '{a} <br/>{b} : {c}',
         formatter: this.tooltipFormatter.bind(this),
      },
    };

    // set the data if the chart is initialized with some data
    if (this.data || this.seriesLabels) {
      defaultOptions.series = this.createDataSeries(this.data, this.seriesLabels);
    }

    // set the x axis labels if provided
    if (this.labels) {
      defaultOptions.xAxis.data = this.labels;
    }

    // set legend options
    if (this.showLegend) {
      defaultOptions.legend = {
        data: this.seriesLabels,
        textStyle:{
          color: 'white'
        },
      }
    }

    // set color palette for the chart
    if (this.colors) {
      defaultOptions.colors = this.colors;
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    const viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);
    const responsiveFontSize = 0.03457 * viewportMin + 3.179; // calculated with linear regression of the best values for specific viewportMins
    const responsiveMargin = (this.baseMarginPercentage - (viewportMin - this.baseDimension) / this.marginScaleFactor).toString() + '%';
    const responsiveSymbolSize = this.baseSymbolSize + (viewportMin - this.baseDimension) / this.symbolSizeScaleFactor;
    const responsiveLineWidth = this.baseLineWidth + (viewportMin - this.baseDimension) / this.lineWidthScaleFactor;

    const responsiveOptions: any = {
      series: [{
        symbolSize: responsiveSymbolSize,
        lineStyle: {
          width: responsiveLineWidth
        }
      }],
      xAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
          margin: this.layoutService.getApplicationSize()[0] > 2000 ? 24 : 8
        },
        axisLine: {
          lineStyle: {
            width: this.layoutService.getApplicationSize()[0] > 2000 ? 2 : 1
          }
        },
        axisTick: {
          length: this.layoutService.getApplicationSize()[0] > 2000 ? 10 : 5,
          lineStyle: {
            width: this.layoutService.getApplicationSize()[0] > 2000 ? 2 : 1
          },
          alignWithLabel: true
        }
      },
      yAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
        }
      },
      grid: {
        top: responsiveMargin,
        bottom: responsiveMargin,
        right: responsiveMargin,
        left: responsiveMargin,
        containLabel: true
      },
      tooltip: {
        textStyle: {
          fontSize: responsiveFontSize * this.tooltipFontScaleFactor
        }
      },
    };

    if (this.showLegend) {
      responsiveOptions.legend = {
        textStyle: {
          fontSize: responsiveFontSize
        }
      };
    }

    return responsiveOptions;
  }

  createDataSeries(seriesData, seriesLabels) {
    const series = [];

    for (let i = 0; i < seriesData.length; i++) {
      const data = seriesData[i];
      const seriesLabel = seriesLabels[i];

      const serie: any = {
        type: 'line',
        name: seriesLabel || this.defaultSeriesName,
        smooth: this.smooth,
        showSymbol: this.displaySymbols
      };

      // set the data if the chart is initialized with some data
      if (data) {
        serie.data = data;
      }

      // create an area chart if this is set to true
      if (this.displayArea) {
        serie.areaStyle = {};
      }

      // set line colors if provided
      if (this.lineColors && this.lineColors.length > 0) {
        serie.itemStyle = {color: (this.lineColors.length > 1 ? this.createGradient(this.lineColors) : this.lineColors[0])};
      }

      series.push(serie);
    }

    return series;
  }

  setData(data: NGSIResult[]) {
    this.rawData = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);

    let dimensionData, seriesData = [];
    this.rawData.forEach(axis => {
      if (axis.dimension) {
        dimensionData = axis;
      } else if (axis.series) {
        seriesData.push(axis);
      }
    });

    // extract chart essentials
    this.data = seriesData.map(seriesItem => seriesItem.data);
    this.seriesLabels = seriesData.map(seriesItem => seriesItem.series.label);
    this.labels = dimensionData.data;

    // update chart data
    const series = this.createDataSeries(this.data, this.seriesLabels);
    this.setMultiSeriesData(series);

    // update chart data
    this.setXAxisLabels(this.labels);
  }
}
