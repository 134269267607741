<closeable-dialog class="train-dialog-container">
  <div class="dialog-header" dialog-card-header [ngStyle]="{'color': hasError ? '#FF1F00' : 'white'}">
    <span class="column-info title">{{ 'trainNumber' | translate }}</span> :
    <span class="column-info content">{{ trainId }}</span> &nbsp;
    <div class="badge" [ngClass]="'train-badge-' + trainStatus">
      {{ 'train-badge-' + trainStatus | translate }}
    </div>
  </div>


  <div dialog-card-body class="dialog-body full-height">
    <div class="main-svg-container">
      <div class="svg-container" id="svgContainer"></div>
      <i [ngStyle]="getBackIconStyle()" class="fa fa-angle-left back-icon"
         (click)="goBack()">&nbsp;{{ 'back' | translate }}</i>

      <div *ngIf="systemLabels[activeTab] && this.previousSVGPath == null">
        <div class="label-container">
          <ng-container *ngFor="let item of systemLabels[activeTab]">
            <label class="status-label">
              <span class="status-color" [ngStyle]="{'background-color': item.color}"></span>
              <span class="status-text">{{ item.label | translate }}</span>
            </label>
          </ng-container>
        </div>
      </div>
    </div>

    <nb-tabset class="main-nb-tabset" (changeTab)="onMainTabChange($event)">
      <nb-tab
        [tabIcon]="diagnosticSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
        tabTitle="{{ 'diagnosticSystem' | translate }}"
        [active]="activeTab === 'diagnosticSystem'"
        [tabId]="'diagnosticSystem'">
        <nb-tabset class="diagnostic-nb-tabset" (changeTab)="onTabChange($event)">
          <nb-tab
            [tabIcon]="tractionSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{ 'tractionSystem' | translate }}"
            [active]="activeTab === 'tractionSystem'"
            [tabId]="'tractionSystem'">
            <div class="d-flex " *ngIf="tractionTabInfo === 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'requiredTractionForce' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ requiredTractionForce }} kN</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'appliedTractionForce' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ appliedTractionForce }} kN</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'forceAppliedAx3' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ fDriveForceAx3 }} kN</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'forceAppliedAx4' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ fDriveForceAx4 }} kN</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'forceAppliedAx3' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ forceAppliedAx3 }} kN</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'forceAppliedAx4' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ forceAppliedAx4 }} kN</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'cutOffTraction' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'cutOffTraction', cutOffTraction) }">{{ isVehicleMove(cutOffTraction) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'cutOffByPass' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'cutOffByPass', cutOffByPass) }">{{ getCutOffByPassStatus(cutOffByPass) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'tractionNull' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'tractionNull', tractionNull) }">{{ isVehicleMove(tractionNull) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'motorCooling2mvcbProtection' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngClass]="'flex-50'+status[11]">{{ motorCooling2mvcbProtection | translate }} </label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 2">
              <div class="flex-50">
                <label class="column-info title">{{ 'tempTrcInv1' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[9]">{{ tempTrcInv1[selectedWagonIndex] }} &deg;C </label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'tempTrcInv2' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ tempTrcInv2[selectedWagonIndex] }} &deg;C </label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 2">
              <div class="flex-50">
                <label class="column-info title">{{ 'tempTrcRec' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ tempTrcRec[selectedWagonIndex] }} &deg;C </label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'vTrcDclink' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[6]">{{ vTrcDclink[selectedWagonIndex] }} V</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'tempMotorAx3' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[5]">{{ tempMotorAx3[selectedWagonIndex] }} &deg;C </label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'tempMotorAx4' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[10]">{{ tempMotorAx4[selectedWagonIndex] }} &deg;C </label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title">{{ 'forceAvmaxAx3' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ forceAvmaxAx3[selectedWagonIndex] / 1000 }} kN</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'forceAvmaxAx4' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngClass]="'flex-50'+status[7]">{{ forceAvmaxAx4[selectedWagonIndex] / 1000 }} kN</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title">{{ 'velMotorAx3' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ velMotorAx3[selectedWagonIndex] }} rpm</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'velMotorAx4' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[8]">{{ velMotorAx4[selectedWagonIndex] }} rpm</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="tractionTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'edBar' | translate }} :&nbsp;</label>
                <label class="column-info content">{{ edBar[selectedWagonIndex] }} kN </label>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="brakeSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{ 'brakeSystem' | translate }}"
            [active]="activeTab === 'brakeSystem'"
            [tabId]="'brakeSystem'">
            <div class="d-flex " *ngIf="brakeTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'bp' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[2]">{{ bp }} bar</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'mrp' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[3]">{{ mrp }} bar</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="brakeTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'pressureGovernorPBReleased' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorPBReleased', pressureGovernorPBReleased) }">{{ isBrakeError("pressureGovernorPBReleased", pressureGovernorPBReleased) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'pressureGovernorDetectionEmergencyBrake' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorDetectionEmergencyBrake', pressureGovernorDetectionEmergencyBrake) }">{{ isBrakeError("pressureGovernorDetectionEmergencyBrake", pressureGovernorDetectionEmergencyBrake) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="brakeTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'pressureGovernorIndirectBrakeControl' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorIndirectBrakeControl', pressureGovernorIndirectBrakeControl) }">{{ isBrakeError("pressureGovernorIndirectBrakeControl", pressureGovernorIndirectBrakeControl) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'diagBrDirect' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'diagBrDirect', diagBrDirect) }">{{ isBrakeError("diagBrDirect", diagBrDirect) | translate }}</label>
              </div>
            </div>

            <div class="d-flex" *ngIf="brakeTabInfo == 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'majorBrPark' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'majorBrPark', majorBrPark) }">{{ errorMap[majorBrPark] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="brakeTabInfo == 2 || brakeTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'majorBrWsp' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'majorBrWsp', majorBrWsp[selectedWagonIndex]) }">{{ errorMap[majorBrWsp[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'ebcu' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'ebcu', ebcu[selectedWagonIndex]) }">{{ errorMap1[ebcu[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>

            <div class="d-flex" *ngIf="brakeTabInfo == 2 || brakeTabInfo == 3">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'majorBrPark' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'majorBrPark', majorBrParkList[selectedWagonIndex]) }">{{ errorMap[majorBrParkList[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'diagBrDirect' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'diagBrDirect', diagBrDirectList[selectedWagonIndex]) }">{{ isBrakeError("diagBrDirect", diagBrDirectList[selectedWagonIndex]) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="brakeTabInfo == 2 || brakeTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'diagBrCPress' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'diagBrCPress', diagBrCPress[selectedWagonIndex]) }">{{ errorMap[diagBrCPress[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="powerVoltageSystem ? { icon: 'alert-triangle', options: { color: '#FF1F00', class: 'nb-tab-custom-icon' } } : null"
            tabTitle="{{ 'powerVoltageSystem' | translate }}"
            [active]="activeTab === 'powerVoltageSystem'"
            [tabId]="'powerVoltageSystem'">
            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'majorUnderVoltageError' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ majorUnderVoltageError }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'catenaryVoltage' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ catenaryVoltage }} kV</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'yuksekAkimKaynakliVakumHatasi' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'yuksekAkimKaynakliVakumHatasi', yuksekAkimKaynakliVakumHatasi) }">{{ errorMap[yuksekAkimKaynakliVakumHatasi] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'hvConfigError' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvConfigError', hvConfigError) }">{{ errorMap[hvConfigError] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'pantoABar' | translate }} :&nbsp; </label>
                <label class="column-info content" [ngClass]="'flex-50'+status[1]">{{ pantoABar }} A</label>
              </div>
              <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'tumPanolarIzole' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tumPanolarIzole', tumPanolarIzole) }">{{ errorMap[tumPanolarIzole] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title">{{ 'pantografDustu' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'pantografDustu', pantografDustu) }">{{ errorMap[pantografDustu] | translate }}</label>
              </div>
              <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'diagPantoComp' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'diagPantoComp', diagPantoComp) }">{{ errorMap[diagPantoComp] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'pTrain' | translate }} :&nbsp;</label>
                <label class="column-info content">{{ pTrain }} kW</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'majorMvError' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'majorMvError', majorMvError) }">{{ errorMap[majorMvError] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'vcbCloseSka1' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'vcbCloseSka', vcbCloseSka1) }">{{ getVacuumStatus(vcbCloseSka1) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'vcbCloseSka2' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'vcbCloseSka', vcbCloseSka2) }">{{ getVacuumStatus(vcbCloseSka2) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'pressurePs1SafeguardAddSka1' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'pressurePs1SafeguardAddSka', pressurePs1SafeguardAddSka1) }">{{ getPantographStatus(pressurePs1SafeguardAddSka1) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'pressurePs1SafeguardAddSka2' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'pressurePs1SafeguardAddSka', pressurePs1SafeguardAddSka2) }">{{ getPantographStatus(pressurePs1SafeguardAddSka2) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'hvDisconnectorCloseSKA1' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseSKA', hvDisconnectorCloseSKA1) }">{{ getDisconnectorStatus(hvDisconnectorCloseSKA1) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title">{{ 'hvDisconnectorCloseSKA2' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseSKA', hvDisconnectorCloseSKA2) }">{{ getDisconnectorStatus(hvDisconnectorCloseSKA2) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'hvDisconnectorCloseOA2' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseOA', hvDisconnectorCloseOA2) }">{{ getDisconnectorStatus(hvDisconnectorCloseOA2) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 2">
              <div class="flex-50">
                <label class="column-info title">{{ 'tractionTransformerOilFlow' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOilFlow', tractionTransformerOilFlow[selectedWagonIndex]) }">{{ errorMap[tractionTransformerOilFlow[selectedWagonIndex]] | translate }}</label>
              </div>
              <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'tractionTransformerOilLevel' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOilLevel', tractionTransformerOilLevel[selectedWagonIndex]) }">{{ errorMap[tractionTransformerOilLevel[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 2">
              <div class="flex-50">
                <label class="column-info title">{{ 'tractionTransformerOverPressure' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOverPressure', tractionTransformerOverPressure[selectedWagonIndex]) }">{{ errorMap[tractionTransformerOverPressure[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'tractionTransformerTemp' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerTemp', tractionTransformerTemp[selectedWagonIndex]) }">{{ tractionTransformerTemp[selectedWagonIndex] }} &deg;C </label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title">{{ 'tempApsInv' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempApsInv', tempApsInv[selectedWagonIndex]) }">{{ tempApsInv[selectedWagonIndex] }} &deg;C </label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'tempApsRec' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempApsRec', tempApsRec[selectedWagonIndex]) }">{{ tempApsRec[selectedWagonIndex] }} &deg;C </label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'vApsDclink' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'vApsDclink', vApsDclink[selectedWagonIndex]) }">{{ vApsDclink[selectedWagonIndex] }} V</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 4">
              <div class="flex-50">
                <label class="column-info title">{{ 'measuringBatteryVoltage' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'measuringBatteryVoltage', measuringBatteryVoltage[selectedWagonIndex]) }">{{ measuringBatteryVoltage[selectedWagonIndex] }} V</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'tempBattery' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempBattery', tempBattery[selectedWagonIndex]) }">{{ tempBattery[selectedWagonIndex] }}
                  &deg;C</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="powerVoltageTabInfo == 4">
              <div class="flex-50">
                <label class="column-info title">{{ 'aBatteryCharger' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ aBatteryCharger[selectedWagonIndex] }} A</label>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="airConditionSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'airConditionSystem' | translate}}"
            [active]="activeTab === 'airConditionSystem'"
            [tabId]="'airConditionSystem'">
            <div class="d-flex " *ngIf="airConditionTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title">{{ 'driverAirConditioningTemperature' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ driverAirConditioningTemperature }}  C&deg;</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'passengerAirConditioningMode' | translate }} :&nbsp; </label>
                <label class="column-info content">{{ getAirConditionMod(passengerAirConditioningMode) | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="airConditionTabInfo === 1">
              <label class="column-info title"> {{ 'hvacsInhibition' | translate }} :&nbsp; </label>
            </div>

            <div class="d-flex " *ngIf="airConditionTabInfo === 1">
              <table id="hvacsInhibition-table" class="styled-table">
                <thead>
                <tr [ngClass]="'header'">
                  <th>{{ 'SKA1' | translate }}</th>
                  <th>{{ 'OA1' | translate }}</th>
                  <th>{{ 'OA2' | translate }}</th>
                  <th>{{ 'OA3' | translate }}</th>
                  <th>{{ 'SKA2' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ errorMap[hvacsInhibitionList[0]] | translate }}</td>
                  <td>{{ errorMap[hvacsInhibitionList[1]] | translate }}</td>
                  <td>{{ errorMap[hvacsInhibitionList[2]] | translate }}</td>
                  <td>{{ errorMap[hvacsInhibitionList[3]] | translate }}</td>
                  <td>{{ errorMap[hvacsInhibitionList[4]] | translate }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex " *ngIf="airConditionTabInfo == 2">
              <div class="flex-50">
                <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', driverMajorHvac[selectedWagonIndex]) }">{{ errorMap[driverMajorHvac[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', driverMinorHvac[selectedWagonIndex]) }">{{ errorMap[driverMinorHvac[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="airConditionTabInfo == 3">
              <div class="flex-50">
                <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', passengerMajorHvac1[selectedWagonIndex]) }">{{ errorMap[passengerMajorHvac1[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', passengerMinorHvac1[selectedWagonIndex]) }">{{ errorMap[passengerMinorHvac1[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="airConditionTabInfo == 4">
              <div class="flex-50">
                <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', passengerMajorHvac2[selectedWagonIndex]) }">{{ errorMap[passengerMajorHvac2[selectedWagonIndex]] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', passengerMinorHvac2[selectedWagonIndex]) }">{{ errorMap[passengerMinorHvac2[selectedWagonIndex]] | translate }}</label>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="doorSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'doorSystem' | translate}}"
            [active]="activeTab === 'doorSystem'"
            [tabId]="'doorSystem'">
            <div class="d-flex " *ngIf="doorTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'door1LevelFault' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'door1LevelFault', door1LevelFault) }">{{ errorMap[door1LevelFault] | translate }}</label>
              </div>
              <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'door2LevelFault' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'door2LevelFault', door2LevelFault) }">{{ errorMap[door2LevelFault] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="doorTabInfo == 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'slDoorControlByPass' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'slDoorControlByPass', slDoorControlByPass) }">{{ getDoorBypassStatus(slDoorControlByPass) | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'kapi1SeviyeAriza' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'kapi1SeviyeAriza', kapi1SeviyeAriza) }">{{ errorMap[kapi1SeviyeAriza] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="doorTabInfo == 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'prmAsansorKapi1Devrede' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'prmAsansorKapi1Devrede', prmAsansorKapi1Devrede) }">{{ errorMap[prmAsansorKapi1Devrede] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'doorLoopOkLed' | translate }} :&nbsp;</label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'doorLoopOkLed', doorLoopOkLed) }">{{ errorMap[doorLoopOkLed] | translate }}</label>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="otherSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'otherSystem' | translate}}"
            [active]="activeTab === 'otherSystem'"
            [tabId]="'otherSystem'">
            <div class="d-flex " *ngIf="otherTabInfo === 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'toiletIsNotActive' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletIsNotActive', toiletIsNotActive) }">{{ errorMap[toiletIsNotActive] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'toiletBowlFull' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletBowlFull', toiletBowlFull) }">{{ errorMap[toiletBowlFull] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="otherTabInfo === 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'toiletWaterLevelLow' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletWaterLevelLow', toiletWaterLevelLow) }">{{ errorMap[toiletWaterLevelLow] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'toiletWastewaterTankFull' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletWastewaterTankFull', toiletWastewaterTankFull) }">{{ errorMap[toiletWastewaterTankFull] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="otherTabInfo === 1">
              <div class="flex-50">
                <label class="column-info title"> {{ 'toiletSosSignal' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletSosSignal', toiletSosSignal) }">{{ errorMap[toiletSosSignal] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="otherTabInfo === 1">
              <label class="column-info title"> {{ 'interiorLightingMode' | translate }} :&nbsp; </label>
            </div>

            <div class="d-flex " *ngIf="otherTabInfo === 1">
              <table id="interiorLighting-table" class="styled-table">
                <thead>
                <tr [ngClass]="'header'">
                  <th>{{ 'SKA1' | translate }}</th>
                  <th>{{ 'OA1' | translate }}</th>
                  <th>{{ 'OA2' | translate }}</th>
                  <th>{{ 'OA3' | translate }}</th>
                  <th>{{ 'SKA2' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ getInteriorLightingMode(interiorLightingModeList[0]) | translate }}</td>
                  <td>{{ getInteriorLightingMode(interiorLightingModeList[1]) | translate }}</td>
                  <td>{{ getInteriorLightingMode(interiorLightingModeList[2]) | translate }}</td>
                  <td>{{ getInteriorLightingMode(interiorLightingModeList[3]) | translate }}</td>
                  <td>{{ getInteriorLightingMode(interiorLightingModeList[4]) | translate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="communicationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'communicationSystem' | translate}}"
            [active]="activeTab === 'communicationSystem'"
            [tabId]="'communicationSystem'">
            <nb-tabset>
              <nb-tab tabTitle="{{'mvbCommunication' | translate}}" [active]="activeTab === 'mvbCommunication'"
                      [tabId]="'mvbCommunication'" style="margin-bottom: 100px">
                <table id="mvbCom-table">
                  <thead>
                  <tr [ngClass]="'header'">
                    <th>{{ 'SKA1' | translate }}</th>
                    <th>{{ 'OA1' | translate }}</th>
                    <th>{{ 'OA2' | translate }}</th>
                    <th>{{ 'OA3' | translate }}</th>
                    <th>{{ 'SKA2' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let mvbCom of mvbComList">
                    <td [ngClass]="{'no-border': mvbCom.ska1 === ''}">{{ mvbCom.ska1 | translate }}</td>
                    <td [ngClass]="{'no-border': mvbCom.oa1 === ''}">{{ mvbCom.oa1 | translate }}</td>
                    <td [ngClass]="{'no-border': mvbCom.oa2 === ''}">{{ mvbCom.oa2 | translate }}</td>
                    <td [ngClass]="{'no-border': mvbCom.oa3 === ''}">{{ mvbCom.oa3 | translate }}</td>
                    <td [ngClass]="{'no-border': mvbCom.ska2 === ''}">{{ mvbCom.ska2 | translate }}</td>
                  </tr>
                  </tbody>
                </table>

                <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 20px;">
                  <label style="display: flex; align-items: center; margin-right: 20px;">
                    <span style="width: 4px; height: 16px; background-color: #31C048; margin-right: 10px;"></span>
                    <span style="color: gray;">{{ 'noError' | translate }}</span>
                  </label>
                  <label style="display: flex; align-items: center;">
                    <span style="width: 4px; height: 16px; background-color: #FF0012; margin-right: 10px;"></span>
                    <span style="color: gray;">{{ 'error' | translate }}</span>
                  </label>
                </div>
              </nb-tab>

              <nb-tab tabTitle="{{'canCommunication' | translate}}" [active]="activeTab === 'canCommunication'"
                      [tabId]="'canCommunication'">
                <table id="canCom-table">
                  <thead>
                  <tr class="header">
                    <th colspan="4">SKA-1 RIOM</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let canCom of camComList">
                    <td [ngClass]="{'no-border': canCom.riom1 === ''}">{{ canCom.riom1 | translate }}</td>
                    <td [ngClass]="{'no-border': canCom.riom2 === ''}">{{ canCom.riom2 | translate }}</td>
                    <td [ngClass]="{'no-border': canCom.riom3 === ''}">{{ canCom.riom3 | translate }}</td>
                    <td [ngClass]="{'no-border': canCom.riom4 === ''}">{{ canCom.riom4 | translate }}</td>
                  </tr>
                  </tbody>
                </table>


                <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 20px;">
                  <label style="display: flex; align-items: center; margin-right: 20px;">
                    <span style="width: 4px; height: 16px; background-color: #31C048; margin-right: 10px;"></span>
                    <span style="color: gray;">{{ 'noError' | translate }}</span>
                  </label>
                  <label style="display: flex; align-items: center;">
                    <span style="width: 4px; height: 16px; background-color: #FF0012; margin-right: 10px;"></span>
                    <span style="color: gray;">{{ 'error' | translate }}</span>
                  </label>
                </div>
              </nb-tab>
            </nb-tabset>
          </nb-tab>

          <nb-tab
            [tabIcon]="signalizationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{'signalizationSystem' | translate}}"
            [active]="activeTab === 'signalizationSystem'"
            [tabId]="'signalizationSystem'">
            <div class="d-flex " *ngIf="signalizationTabInfo === 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'etcsIsolationByPass' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'etcsIsolationByPass', etcsIsolationByPass) }">{{ errorMap[etcsIsolationByPass] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'ertmsCutOff' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'ertmsCutOff', ertmsCutOff) }">{{ errorMap1[ertmsCutOff] | translate }}</label>
              </div>
            </div>

            <div class="d-flex " *ngIf="signalizationTabInfo === 1">
              <div [ngClass]="'flex-50'">
                <label class="column-info title"> {{ 'atsByPass' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'atsByPass', atsByPass) }">{{ errorMap[atsByPass] | translate }}</label>
              </div>
              <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                <label class="column-info title"> {{ 'ertmsServiceBrake' | translate }} :&nbsp; </label>
                <label class="column-info content"
                       [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'ertmsServiceBrake', ertmsServiceBrake) }">{{ errorMap[ertmsServiceBrake] | translate }}</label>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab
        [tabIcon]="status[4] === '-red' ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
        tabTitle="{{ 'prognosticSystem' | translate }}"
        [active]="activeTab === 'prognosticSystem'"
        [tabId]="'prognosticSystem'">
        <nb-tabset (changeTab)="onTabChange($event)" class="prognostic-nb-tabset">
          <nb-tab
            [tabIcon]="status[4] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{'predictiveMaintenance' | translate}}" [active]="activeTab === 'predictiveMaintenance'"
            [tabId]="'predictiveMaintenance'">
            <div class="d-flex ">
              <div class="flex-50">
                <label [ngClass]="'flex-50'+status[4]" [style.white-space]="'pre-line'"> {{ predictiveMaintenanceInfo | translate }} </label>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
    </nb-tabset>
  </div>

  <div dialog-card-footer class="footer">
    <div class="footer-left">
      <label class="column-info title">{{ 'ascLimit' | translate }} : &nbsp; </label>
      <label [ngClass]="'flex-50'+status[0]"> {{ ascLimit }} km/h </label> <br/>
      <label class="column-info title">{{ 'coordinate' | translate }} : </label>
      <label class="column-info content">{{ location }}</label>
    </div>
    <div class="footer-right">
      <label class="column-info title">{{ 'speed' | translate }} : </label>
      <label class="column-info content">{{ speed }} km/h</label> <br/>
      <label class="action-button" (click)="getFirstData()">
        <nb-icon [icon]="'arrow-back'" pack="eva" nbTooltip="Geriye Dön"></nb-icon>
      </label>
      <label class="action-button" (click)="refreshData()">
        <nb-icon [icon]="'refresh-outline'" pack="eva" nbTooltip="Yenile"></nb-icon>
      </label>
      <label *ngIf="isLiveMode" class="column-info title">{{ 'liveMode' | translate }} </label>
      <label *ngIf="!isLiveMode" class="column-info title">{{ 'firstMode' | translate }} </label>
    </div>
  </div>
</closeable-dialog>
