import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {UrukMap} from '../../models/map/uruk-map.interface';
import * as L from 'leaflet';
import {marker} from 'leaflet';
import {environment} from '../../../../environments/environment';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {Coordinate} from '../../models/generic/coordinate.model';
import {BaseComponent} from '../base.component';
import {LayerController} from '../../../core/controllers/layer.controller';
import {Geometry} from '../../enums/ngsi-query.enum';
import {MapSettings} from '../../models/visualization/map-settings.model';
import {MapTileType} from "../../enums/map-framework.enum";

@Component({
  selector: 'leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent extends BaseComponent implements UrukMap, OnInit {
  @Input() layers: LayerController[];
  @Input() mapSettings: MapSettings;
  @Input() mapType: MapTileType;
  @Output() onMapInitialized: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapBoundariesChanged: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapClicked: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();
  @Output() onShapeDrawn: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();

  /**
   * Reference to the leaflet map object
   */
  map: L.Map;

  /**
   * Reference to the leaflet draw object
   */
  drawControl: L.Draw;

  /**
   * Reference to the current tile layer (e.g. OpenStreeMap, etc)
   */
  tileLayer;

  /**
   * Array that keeps markers and shapes that are not a part of regular layers given as input. Shape objects that are created as a result of drawing activities are also also kept in this list
   */
  public markers = [];

  /**
   * An initial zoom/center and set of layers. Changes to leafletOptions are ignored after they are initially set.
   * This is because these options are passed into the map constructor, so they can't be changed anyways.
   */
  mapOptions: any;
  /**
   * Leaflet draw options to hide the toolbar.
   */
  drawOptions = {
    position: 'bottomright',
    draw: {
      polygon: false,
      rectangle: false,
      polyline: false,
      circle: false,
      marker: false,
      circlemarker: false
    },
    edit: false
  };

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.initializeMapOptions();
  }

  /**
   * Called when leaflet map is ready to use
   */

  onMapReady(map: L.Map) {
    // get a reference to the map instance
    this.map = map;
    // emit the coordinate of the clicked point on the map
    this.map.on('click', (event) => {
      this.onMapClicked.emit(new Coordinate({latitude: event.latlng.lat, longitude: event.latlng.lng}));
    });
    if(this.mapType && this.mapType === MapTileType.SATELLITE){
      // get a reference to tile layer
      this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrlSatellite, {
        minZoom: 0,
        maxZoom: environment.map.leaflet.maxZoom
      }).addTo(this.map);
    }else{
      // get a reference to tile layer
      this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrlRoadMap, {
        minZoom: 0,
        maxZoom: environment.map.leaflet.maxZoom
      }).addTo(this.map);
    }

    // emit map initialize event
    this.onMapInitialized.emit(this.getGeoQuery());

    // if map is initialized with a specific zoom level, broadcast it
    if(localStorage.getItem('mapZoomProvider') !== null){
      if(localStorage.getItem("mapZoomProvider") === 'leaflet'){
        this.onZoomChange(localStorage.getItem("mapZoomLevel"));
      }else if(localStorage.getItem("mapZoomProvider") === 'mapLibre'){
        this.onZoomChange(Math.round(Number(localStorage.getItem("mapZoomLevel"))) + 1);
      }
      return;
    }
    if (this.mapSettings?.zoomLevel) {
      this.onZoomChange(this.mapSettings.zoomLevel);
    }
  }

  /**
   * Called when map move ends
   */
  onMapMoveEnd(data : L.LeafletEvent) {
    // broadcast map change event when map is moved only if there are no drawn shapes
    localStorage.setItem('mapCoordinateLat', this.map.getCenter().lat);
    localStorage.setItem('mapCoordinateLng', this.map.getCenter().lng);
    if (this.getFirstDrawnShape() === null) {
      this.onMapBoundariesChanged.emit(this.getGeoQuery());
    }
  }

  onZoomChange(event): void {
    this.layoutService.onMapZoomChange(event);
    localStorage.setItem("mapZoomProvider", "leaflet");
    localStorage.setItem("mapZoomLevel", event);
  }

  /**
   * Called when polygon draw extension is ready to use
   * @param drawControl
   */
  onDrawReady(drawControl) {
    this.drawControl = drawControl;

    // localize the controls for leaflet-draw
    this.handleLocalization();
  }

  onDrawCircleInitiated() {
    // https://stackoverflow.com/questions/15775103/leaflet-draw-mapping-how-to-initiate-the-draw-function-without-toolbar
    this.drawShape(new L.Draw.Circle(this.map, this.drawControl.options.circle));
  }

  onDrawRectangleInitiated(): void {
    this.drawShape(new L.Draw.Rectangle(this.map, this.drawControl.options.rectangle));
  }

  onDrawPolygonInitiated(): void {
    this.drawShape(new L.Draw.Polygon(this.map, this.drawControl.options.polygon));
  }

  /**
   * Starts and editing session to draw a shape
   * @param shape Shape to be drawn: Can be rectangle, circle or polygon
   */
  drawShape(shape) {
    // first remove the existing shape
    this.removeDrawnShapes();
    // https://stackoverflow.com/questions/15775103/leaflet-draw-mapping-how-to-initiate-the-draw-function-without-toolbar
    shape.enable();
  }

  /**
   * Called automatically when a polygon has just been drawn
   */
  onShapeCreated(data) {
    data.layer.addTo(this.map);
    // save the shapes in an storage for later reference
    this.markers.push(data.layer);

    // broadcast the information containing the geological boundaries of the drawn shape
    this.onShapeDrawn.emit(this.getGeoQuery());
  }

  onDrawnShapeRemoved(): void {
    this.removeDrawnShapes();
  }

  /**
   * Helper method to remove the shape
   */
  private removeDrawnShapes() {
    this.markers.forEach(m => this.map.removeLayer(m));
    this.markers = [];
  }


  onZoomIn(): void {
    this.map.zoomIn();
  }

  onZoomOut(): void {
    this.map.zoomOut();
  }

  panAndZoom(coordinate: Coordinate, zoomLevel: number): void {
    this.map.setZoomAround(coordinate.toLatLongArray(), zoomLevel);
  }

  pan(coordinate: Coordinate): void {
    this.map.panTo(coordinate.toLatLongArray());
  }

  onMapAreaChanged(): void {
    this.map.invalidateSize();
  }

  addIconMarker(coordinate: Coordinate, options: any): void {
    const icon = new L.Icon.Default();
    icon.options.shadowSize = [0, 0];
    const m = marker(coordinate.toLatLongArray(), {icon : icon});
    this.markers.push(m);
    m.addTo(this.map);
  }

  addGeoQueryAsShape(geoQuery: NGSIGeoQuery): void {
    const m: any = this.transformGeoQuery(geoQuery);
    this.markers.push(m);
    m.addTo(this.map);
  }

  fitBounds(bounds: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  setMaxBounds(bounds?: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  addGeoQueryAsBackgroundShape(ngsiGeoQuery: NGSIGeoQuery): void {
    throw new Error('Method not implemented.');
  }

  clearBackgroundShapes(): void {
    throw new Error('Method not implemented.');
  }

  drawnShapeExists(): boolean {
    throw new Error('Method not implemented.');
  }

  clearMarkers(): void {
    this.markers.forEach(m => this.map.removeLayer(m));
    this.markers = [];
  }

  public getBounds(): Coordinate[] {
    const bounds: any = this.map.getBounds();
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const west = bounds.getWest();
    const east = bounds.getEast();
    // First and last coordinates must be the same to construct a loop for MongoDB queries
    return [
      new Coordinate({longitude: west, latitude: north}),
      new Coordinate({longitude: east, latitude: north}),
      new Coordinate({longitude: east, latitude: south}),
      new Coordinate({longitude: west, latitude: south}),
      new Coordinate({longitude: west, latitude: north})
    ];
  }

  getShapeCoordinates(shape: any): Coordinate[] {
    let coordinates = [];

    // Leaflet maintains coordinates at two levels. For polygons, coordinates that we are looking for are located at the first child of parent array.
    if (shape.length === 1) {
      coordinates = shape[0];
    }

    // convert LatLng to Coordinate
    const convertedCoordinates: Coordinate[] = coordinates.map(coordinate => new Coordinate({longitude: coordinate.lng, latitude: coordinate.lat}));

    // close the loop
    convertedCoordinates.push(convertedCoordinates[0]);
    return convertedCoordinates;
  }

  /**
   * Returns necessary information to query entities based on the visible area or a selected polygon
   */
  public getGeoQuery(): NGSIGeoQuery {
    // if there is a drawn object, geoquery will be created based it boundaries
    // for now we only consider the first item of the shapes as there is no requirement to consider multiple shapes
    const drawnShape: any = this.getFirstDrawnShape();
    if (drawnShape) {
      if (drawnShape instanceof L.Polygon || drawnShape instanceof L.Rectangle) {
        const coordinates: Coordinate[] = this.getShapeCoordinates(drawnShape.getLatLngs());
        return NGSIGeoQuery.createPolygonQuery(coordinates);

      } else if (drawnShape instanceof L.Circle) {
        const coordinate = drawnShape.getLatLng();
        const center = new Coordinate({longitude: coordinate.lng, latitude: coordinate.lat});
        const radius = Math.round(drawnShape.getRadius()); // in meters
        return NGSIGeoQuery.createCircularQuery(center, radius, true); // TODO isMax should be configurable
      }
    }
    // otherwise geoquery will be created based on the visible area of the map
    else {
      const coordinates = this.getBounds();
      return NGSIGeoQuery.createPolygonQuery(coordinates);
    }
  }

  /**
   * Retrieves the first shape, if any, from the list of markers
   * @private
   */
  private getFirstDrawnShape(): any {
    const shapes: any[] = this.markers
      .filter(m => m instanceof L.Polygon || m instanceof L.Circle || m instanceof L.Rectangle);
    if (shapes?.length > 0) {
      return shapes[0];
    } else {
      return null;
    }
  }

  public transformGeoQuery(query: NGSIGeoQuery): any {
    if (query.geometry === Geometry.POINT) {
      return L.circle(query.coordinates, query.maxMinDistance.distance);
    } else {
      return L.polygon(query.coordinates);
    }
  }

  /**
   * Sets the current localization for the leaflet-draw controls
   */
  private handleLocalization() {
    this.translateService.get(['Radius', 'Click and drag to draw circle.', 'Click and drag to draw rectangle.',
      'Click to start drawing shape.', 'Click to continue drawing shape.', 'Click first point to close this shape.',
      '<strong>Error:</strong> shape edges cannot cross!', 'Release mouse to finish drawing.']).subscribe(translateResults => {

      L.drawLocal = {
        draw: {
          toolbar: {
            buttons: {}
          },
          handlers: {
            circle: {
              tooltip: {
                start: translateResults['Click and drag to draw circle.']
              },
              radius: translateResults['Radius']
            },
            circlemarker: {
              tooltip: {}
            },
            marker: {
              tooltip: {}
            },
            polygon: {
              tooltip: {
                start: translateResults['Click to start drawing shape.'],
                cont: translateResults['Click to continue drawing shape.'],
                end: translateResults['Click first point to close this shape.']
              }
            },
            polyline: {
              error: translateResults['<strong>Error:</strong> shape edges cannot cross!']
            },
            rectangle: {
              tooltip: {
                start: translateResults['Click and drag to draw rectangle.']
              }
            },
            simpleshape: {
              tooltip: {
                end: translateResults['Release mouse to finish drawing.']
              }
            }
          }
        }
      };
    });
  }

  /**
   * Initializes the map options. They are retrieved from map settings if available. Otherwise, environment settings
   * are used.
   * */
  private initializeMapOptions() {
    const center = this.mapSettings ? {
      lat: localStorage.getItem("mapCoordinateLat") !== null ? localStorage.getItem("mapCoordinateLat")  : this.mapSettings.point.coordinates[0],
      lng: localStorage.getItem("mapCoordinateLng") !== null ? localStorage.getItem("mapCoordinateLng") : this.mapSettings.point.coordinates[1]
    } : {lat: environment.map.leaflet.center.lat, lng: environment.map.leaflet.center.lng};
    this.mapOptions = {
      center: L.latLng(center),
      zoom: this.mapSettings?.zoomLevel ? this.mapSettings.zoomLevel : environment.map.leaflet.defaultZoom,
      zoomControl: false,
      maxZoom: environment.map.leaflet.maxZoom,
      minZoom: environment.map.leaflet.minZoom,
      maxBounds: [ [environment.map.bounds[1], environment.map.bounds[0]], [environment.map.bounds[3], environment.map.bounds[2]] ],
      attributionControl: false
    };
    if(localStorage.getItem("mapZoomProvider") === 'leaflet'){
      this.mapOptions.zoom = localStorage.getItem("mapZoomLevel");
    }else if(localStorage.getItem("mapZoomProvider") === 'mapLibre'){
      this.mapOptions.zoom = Math.round(Number(localStorage.getItem("mapZoomLevel"))) + 1;
    }
  }
}
