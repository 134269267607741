import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { UrukError } from 'app/shared/models/error/uruk-error.model';
/**
 * Http interceptor to catch server errors and create a toastr for translated error message.
 */
@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  toastrService: NbToastrService;
  constructor(private readonly injector: Injector
  ) {
    this.toastrService = injector.get(NbToastrService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.error) {
            // can not import TranslateService in the constructor because it depens on HttpClient which creates circular dependency 
            const translateService = this.injector.get(TranslateService);

            const error = new UrukError(errorResponse.error);
            const parameters = error.createParametersObject();

            if (error.errorCode) {
              const errorMessage = translateService.instant(`httpErrors.${error.errorCode}`, parameters);
              this.toastrService.show(errorMessage, translateService.instant('Error'), { status: 'danger', duration: 5000, destroyByClick: false });
            }
          }
          return throwError(errorResponse);
        })
      )
  }
}