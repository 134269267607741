import {BaseMetaModel} from './base/base-meta-model.model';
export class TrainDefinition extends BaseMetaModel {

  fDriveApplied: string;
  voltPantoSka1: string;
  hmiJourneyNo: string;
  location: string;
  speed: string;
  distanceTraveled: string;
  ascLimit: string;
  tractionBar: string;
  tractionCutOffLed: string;
  majorUnderVoltageError: string;
  mediumVoltageBackupEnabled2Vehicles: string;
  catenaryVoltage: string;
  pantoABar: string;
  pTrain: string;
  bp: string;
  mrp: string;
  edBar: string;
  emergencyStatusBrakeLed: string;
  driverAirConditioningTemperature: string;
  externalTemperature: string;
  door1LevelFault: string;
  door2LevelFault: string;
  doorLoopOkLed: string;
  predictiveMaintenanceCode: string;
  tempTrcInv1: string;
  tempTrcInv2: string;
  tempTrcRec: string;
  tempApsRec: string;
  tempApsInv: string;
  tempMotorAx3: string;
  tempMotorAx4: string;
  tractionTransformerTemp: string;
  tractionDcLinkOk: string;
  vTrcDclink: string;
  ApsDcLinkOk: string;
  vApsDclink: string;
  forceAvmaxAx3: string;
  forceAvmaxAx4: string;
  velMotorAx3: string;
  velMotorAx4: string;
  lcuMaintenance1: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.fDriveApplied = data.fDriveApplied;
    this.voltPantoSka1 = data.voltPantoSka1;
    this.hmiJourneyNo = data.hmiJourneyNo;
    this.location = data.location;
    this.speed = data.speed;
    this.distanceTraveled = data.distanceTraveled;
    this.ascLimit = data.ascLimit;
    this.tractionBar = data.tractionBar;
    this.tractionCutOffLed = data.tractionCutOffLed;
    this.majorUnderVoltageError = data.majorUnderVoltageError;
    this.mediumVoltageBackupEnabled2Vehicles = data.mediumVoltageBackupEnabled2Vehicles;
    this.catenaryVoltage = data.catenaryVoltage;
    this.pantoABar = data.pantoABar;
    this.pTrain = data.pTrain;
    this.bp = data.bp;
    this.mrp = data.mrp;
    this.edBar = data.edBar;
    this.emergencyStatusBrakeLed = data.emergencyStatusBrakeLed;
    this.driverAirConditioningTemperature = data.driverAirConditioningTemperature;
    this.externalTemperature = data.externalTemperature;
    this.door1LevelFault = data.door1LevelFault;
    this.door2LevelFault = data.door2LevelFault;
    this.doorLoopOkLed = data.doorLoopOkLed;
    this.predictiveMaintenanceCode = data.predictiveMaintenanceCode;
    this.tempTrcInv1 = data.tempTrcInv1;
    this.tempTrcInv2 = data.tempTrcInv2;
    this.tempTrcRec = data.tempTrcRec;
    this.tempApsRec = data.tempApsRec;
    this.tempApsInv = data.tempApsInv;
    this.tempMotorAx3 = data.tempMotorAx3;
    this.tempMotorAx4 = data.tempMotorAx4;
    this.tractionTransformerTemp = data.tractionTransformerTemp;
    this.tractionDcLinkOk = data.tractionDcLinkOk;
    this.vTrcDclink = data.vTrcDclink;
    this.ApsDcLinkOk = data.ApsDcLinkOk;
    this.vApsDclink = data.vApsDclink;
    this.forceAvmaxAx3 = data.forceAvmaxAx3;
    this.forceAvmaxAx4 = data.forceAvmaxAx4;
    this.velMotorAx3 = data.velMotorAx3;
    this.velMotorAx4 = data.velMotorAx4;
    this.lcuMaintenance1 = data.lcuMaintenance1;
  }

}
