<ng-container>
  <div class="ekg-container" style="background:#181818">
    <div class="ekg-header-container">
      <div class="ekg-title-container">
        <label class="column-info content">  {{this.ekgContainerTitle}} &nbsp; </label>
      </div>
      <div class="defibrillator-signal-container">
        <label class="column-info content">  {{this.connectionTypeName}} &nbsp; </label>
        <label>
          <img src="{{this.signalQualityIcon}}">
        </label>
        <label style="color:{{this.colorSignalQuality}};" class="column-info content"> &nbsp; {{this.signalQuality}} </label>
      </div>
      <div class="ekg-data-connection-container">
        <img class="icon-entity" src="{{this.connectionStatusImgSource}}">
        <img class="icon-entity" (click)="downloadEkgData()" src="/assets/images/download.svg" [nbTooltip]="'Download Data'">
      </div>
    </div>
    <div class="ekg-chart-container">
      <div id="ekgChart" style="width: 1000px; height: 500px;"></div>
    </div>
  </div>
</ng-container>
