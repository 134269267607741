<closeable-dialog class="defibrillator-test-dialog-container">
  <div class="dialog-header title" dialog-card-header>
    <label [ngClass]="'column-info content'">{{'testTime' | translate}} :  {{ defibrillatorTest.testDay+'.'+defibrillatorTest.testMonth+
    '.'+defibrillatorTest.testYear+','+defibrillatorTest.testHour+':'+defibrillatorTest.testMinute}} &nbsp;</label>

    <label [ngClass]="'column-info content'">{{'testType' | translate}} :  {{ defibrillatorTest.testType}}</label>
  </div>
    <div dialog-card-body class="dialog-body full-height">
      <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'padTestResult' | translate}} : </label>
            <label [ngClass]="'column-info content-pad-' + defibrillatorTest.padTestResult ">{{defibrillatorTest.padTestResult === 1 ? ('testPadOk' | translate)
              :(defibrillatorTest.padTestResult === 0 ? ('testPadError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
              <label class="action-button" *ngIf="defibrillatorTest.padTestResult === 0" (click)="openInfoDialog('aed-ped.png', 'padTestResult')">
                <i class="fa fa-info-circle" ></i>
              </label>
            </label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'cryptoTestResult' | translate}} :</label>
            <label [ngClass]="'column-info content-' + defibrillatorTest.cryptoTestResult">{{defibrillatorTest.cryptoTestResult === 1 ? ('testCryptoOk' | translate)
              :(defibrillatorTest.cryptoTestResult === 0 ? ('testCryptoError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
              <label class="action-button" *ngIf="defibrillatorTest.cryptoTestResult === 0" (click)="openInfoDialog('aed-battery.png', 'cryptoTestResult')">
                <i class="fa fa-info-circle" ></i>
              </label>
            </label>
          </div>
        </div>

        <div  class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'audioTestResult' | translate}} :</label>
            <label [ngClass]="'column-info content-' + defibrillatorTest.audioTestResult ">{{defibrillatorTest.audioTestResult === 1 ?  ('testOk' | translate)
              :(defibrillatorTest.audioTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
              <label class="action-button" *ngIf="defibrillatorTest.audioTestResult === 0" (click)="openInfoDialog('aed-speaker-error.png', 'audioTestResult')">
                <i class="fa fa-info-circle" ></i>
              </label>
            </label>
          </div>

          <div class="flex-50">
            <label class="column-info title"> {{'adsTestResult' | translate}} : </label>
            <label [ngClass]="'column-info content-'+defibrillatorTest.adsTestResult">{{defibrillatorTest.adsTestResult === 1 ? ('testOk' | translate)
              :(defibrillatorTest.adsTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
              <label class="action-button" *ngIf="defibrillatorTest.adsTestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'adsTestResult')">
                <i class="fa fa-info-circle" ></i>
              </label>
            </label>
          </div>
        </div>

        <div  class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'hBridgeCircuit1TestResult' | translate}} : </label>
            <label [ngClass]="'column-info content-'+defibrillatorTest.hBridgeCircuit1TestResult ">{{defibrillatorTest.hBridgeCircuit1TestResult === 1 ? ('testOk' | translate)
              :(defibrillatorTest.hBridgeCircuit1TestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
                <label class="action-button" *ngIf="defibrillatorTest.hBridgeCircuit1TestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'hBridgeCircuit1TestResult')">
                  <i class="fa fa-info-circle" ></i>
                </label>
            </label>
          </div>

          <div class="flex-50">
            <label class="column-info title"> {{'capacitorVoltageTestResult' | translate}} :</label>
            <label [ngClass]="'column-info content-' + defibrillatorTest.capacitorVoltageTestResult">{{defibrillatorTest.capacitorVoltageTestResult === 1 ? ('testOk' | translate)
              :(defibrillatorTest.capacitorVoltageTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
            </label>
            <label>
                <label class="action-button" *ngIf="defibrillatorTest.capacitorVoltageTestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'capacitorVoltageTestResult')">
                  <i class="fa fa-info-circle" ></i>
                </label>
            </label>
          </div>
        </div>

    <div  class="d-flex ">
      <div class="flex-50">
        <label class="column-info title"> {{'hBridgeCircuit2TestResult' | translate}} : </label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.hBridgeCircuit2TestResult">{{defibrillatorTest.hBridgeCircuit2TestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.hBridgeCircuit2TestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.hBridgeCircuit2TestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'hBridgeCircuit2TestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>

      <div class="flex-50">
        <label class="column-info title"> {{'ecgTestResult' | translate}} :</label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.ecgTestResult">{{defibrillatorTest.ecgTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.ecgTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.ecgTestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'ecgTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>
    </div>

    <div  class="d-flex ">
      <div class="flex-50">
        <label class="column-info title"> {{'onOffButtonTestResult' | translate}} : </label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.onOffButtonTestResult">{{defibrillatorTest.onOffButtonTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.onOffButtonTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.onOffButtonTestResult === 0" (click)="openInfoDialog('aed-left-button.png', 'onOffButtonTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>

      <div class="flex-50">
        <label class="column-info title"> {{'chargeCircuit1TestResult' | translate}} :</label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.chargeCircuit1TestResult">{{defibrillatorTest.chargeCircuit1TestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.chargeCircuit1TestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.chargeCircuit1TestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'chargeCircuit1TestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>
    </div>

    <div  class="d-flex ">
      <div class="flex-50">
        <label class="column-info title"> {{'shockButtonTestResult' | translate}} : </label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.shockButtonTestResult ">{{defibrillatorTest.shockButtonTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.shockButtonTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.shockButtonTestResult === 0" (click)="openInfoDialog('aed-right-button.png', 'shockButtonTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>

      <div class="flex-50">
        <label class="column-info title"> {{'chargeCircuit2TestResult' | translate}} :</label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.chargeCircuit2TestResult">{{defibrillatorTest.chargeCircuit2TestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.chargeCircuit2TestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.chargeCircuit2TestResult === 0" (click)="openInfoDialog('aed-main-board-error.png', 'chargeCircuit2TestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-50">
        <label class="column-info title">{{ 'ads4v6Result' | translate }}:</label>
        <label [ngClass]="'column-info content-' + ads4v6ResultSuccess">
          {{ defibrillatorTest.ads4v6Result !== undefined ? defibrillatorTest.ads4v6Result  + ' V': '-'}}
        </label>
      </div>
      <div class="flex-50">
        <label class="column-info title">{{ 'dacSetVoltage' | translate }}:</label>
        <label [ngClass]="'column-info content-' + dacSetVoltageSuccess">
          {{ defibrillatorTest.dacSetVoltage !== undefined ? defibrillatorTest.dacSetVoltage + ' V' : '-' }}
        </label>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-50">
        <label class="column-info title">{{ 'chargingVoltage' | translate }}:</label>
        <label [ngClass]="'column-info content-' + chargingVoltageSuccess">
          {{ defibrillatorTest.chargingVoltage !== undefined ?  defibrillatorTest.chargingVoltage + ' V': '-'}}
        </label>
      </div>
      <div class="flex-50">
        <label class="column-info title">{{ 'chargeTime' | translate }}:</label>
        <label [ngClass]="'column-info content-' + chargeTimeSuccess">
          {{ defibrillatorTest.chargeTime !== undefined ?  defibrillatorTest.chargeTime + ' s' : '-'}}
        </label>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-50">
        <label class="column-info title">{{ 'hBridge1' | translate }}:</label>
        <label [ngClass]="'column-info content-' + hBridge1Success">
          {{ defibrillatorTest.hBridge1 !== undefined ?  defibrillatorTest.hBridge1 + ' mV' : '-'}}
        </label>
      </div>
      <div class="flex-50">
        <label class="column-info title">{{ 'hBridge2' | translate }}:</label>
        <label [ngClass]="'column-info content-' + hBridge2Success">
          {{ defibrillatorTest.hBridge2 !== undefined ? defibrillatorTest.hBridge2 +' mV': '-'}}
        </label>
      </div>
    </div>
    <div class="flex-50">
      <br />
      <label class="column-info title"> {{'IOT' | translate}} :</label>
    </div>

    <div  class="d-flex ">
      <div class="flex-50">
        <label class="column-info title"> {{'iotAedConnectionTestResult' | translate}} : </label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.iotAedConnectionTestResult ">{{defibrillatorTest.iotAedConnectionTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.iotAedConnectionTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.iotAedConnectionTestResult === 0" (click)="openInfoDialog('aed-iot-connection.png', 'iotAedConnectionTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>

      <div class="flex-50">
        <label class="column-info title"> {{'iotAccelerometerTestResult' | translate}} :</label>
        <label [ngClass]="'column-info content-' +defibrillatorTest.iotAccelerometerTestResult">{{defibrillatorTest.iotAccelerometerTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.iotAccelerometerTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.iotAccelerometerTestResult === 0" (click)="openInfoDialog('aed-iot.png', 'iotAccelerometerTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>
    </div>

    <div  class="d-flex ">
      <div class="flex-50">
        <label class="column-info title"> {{'iotInternalMemoryTestResult' | translate}} : </label>
        <label [ngClass]="'column-info content-' + defibrillatorTest.iotInternalMemoryTestResult">{{defibrillatorTest.iotInternalMemoryTestResult === 1 ? ('testOk' | translate)
          :(defibrillatorTest.iotInternalMemoryTestResult === 0 ? ('testError' | translate) : ('noTestResult' | translate))}}
        </label>
        <label>
          <label class="action-button" *ngIf="defibrillatorTest.iotInternalMemoryTestResult === 0" (click)="openInfoDialog('aed-iot.png', 'iotInternalMemoryTestResult')">
            <i class="fa fa-info-circle" ></i>
          </label>
        </label>
      </div>
    </div>
  </div>
</closeable-dialog>


