
<div *ngIf= "env.map.tileType !== MapTileType.DEFAULT" class="layer-panel">
<nb-actions *ngIf="showLayerSelections" size="small">
  <nb-action class="control-button" (click)="onChangeMapType(MapTileType.DEFAULT)" [nbPopover]="'Default Map' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
    <div class="icon-container"><i class="fa fa-map fa-lg"></i></div>
  </nb-action>
</nb-actions>
</div>
<div *ngIf= "env.map.tileType !== MapTileType.SATELLITE" class="layer-panel">
  <nb-actions *ngIf="showLayerSelections" size="small">
    <nb-action class="control-button" (click)="onChangeMapType(MapTileType.SATELLITE)" [nbPopover]="'Satellite' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="fa fa-satellite fa-lg"></i></div>
    </nb-action>
  </nb-actions>
</div>
<div *ngIf= "env.map.tileType !== MapTileType.ROADMAP" class="layer-panel">
  <nb-actions *ngIf="showLayerSelections" size="small" >
    <nb-action class="control-button" (click)="onChangeMapType(MapTileType.ROADMAP)" [nbPopover]="'Road Map' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="fa fa-road fa-lg"></i></div>
    </nb-action>
  </nb-actions>
</div>
<div class="layer-panel">
  <nb-actions *ngIf="showDrawingFunctions" size="small">
      <nb-action class="control-button" (click)="showLayerSelections = !showLayerSelections" [nbPopover]="'Map Types' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
        <div class="icon-container"><i class="fa fa-layer-group fa-lg"></i></div>
      </nb-action>

    <nb-action *ngIf="enableDrawingCircles && env.map.framework === MapFramework.LEAFLET" class="control-button" (click)="onDrawCircleClicked()" [nbPopover]="'Draw Circle' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="far fa-circle fa-lg"></i></div>
    </nb-action>

    <!--
    <nb-action *ngIf="env.map.framework === MapFramework.MAPLIBRE" class="control-button" (click)="onDrawRectangleClicked()" [nbPopover]="'Draw Rectangle' | translate" nbTooltipStatus="basic" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <i class="fa fa-square fa-lg"></i>
    </nb-action>
    -->

    <nb-action class="control-button" (click)="onDrawPolygonClicked()" [nbPopover]="'Draw Polygon' | translate" nbTooltipStatus="basic" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="fa fa-draw-polygon fa-lg"></i></div>
    </nb-action>

    <nb-action class="control-button" (click)="onClearDrawingsClicked()" [nbPopover]="'Remove Drawings' | translate" nbTooltipStatus="basic" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="fa fa-trash fa-lg"></i></div>
    </nb-action>

    <nb-action class="control-button expand-toolbox" (click)="showDrawingFunctions = !showDrawingFunctions">
      <nb-icon icon="arrowhead-left-outline"></nb-icon>
    </nb-action>
  </nb-actions>

  <nb-actions *ngIf="!showDrawingFunctions" size="small" class="">
    <nb-action class="control-button" (click)="showDrawingFunctions = !showDrawingFunctions">
      <div class="icon-container">
        <uruk-svg [width]="'100%'"
                  [height]="'100%'"
                  [viewbox]="'0 0 19 19'"
                  [paths]="['M5.97266 0.000878587C5.73991 0.00775359 5.50856 0.0540036 5.28906 0.141504L1.25781 1.75478C0.498812 2.05878 0 2.79421 0 3.61221V15.456C0 16.871 1.42819 17.8384 2.74219 17.3134L6.03125 15.9989L11.3379 17.7665C11.7869 17.9165 12.2739 17.9024 12.7129 17.7274L12.8457 17.6728L10.5527 15.3798L6.6875 14.1063C6.2775 13.9723 6 13.5891 6 13.1571V3.39346C6 2.71246 6.6655 2.23124 7.3125 2.44424L11.3125 3.7626C11.7225 3.8976 12 4.27982 12 4.71182V9.99893H13.793C14.456 9.99893 15.0815 10.2563 15.5605 10.7313L18 13.1708V2.41103C18 0.996035 16.5718 0.029566 15.2578 0.555566L11.9688 1.87002L6.66406 0.102441C6.43956 0.0274411 6.20541 -0.00599641 5.97266 0.000878587ZM12 10.0438V10.0497C12.011 10.0477 12.009 10.0458 12 10.0438ZM12.5 11.9989C12.224 11.9989 12 12.2229 12 12.4989V13.7919C12 13.9249 12.0535 14.0524 12.1465 14.1454L17.1465 19.1454L19.1465 17.1454L14.1465 12.1454C14.0525 12.0524 13.926 11.9989 13.793 11.9989H12.5ZM19.8535 17.8524L17.8535 19.8524L18.8535 20.8524C19.0485 21.0474 19.3655 21.0474 19.5605 20.8524L20.8535 19.5595C21.0485 19.3635 21.0485 19.0474 20.8535 18.8524L19.8535 17.8524Z']"
                  [svgContainerClass]="'uruk-icon'">
        </uruk-svg>
      </div>
    </nb-action>
    <nb-action class="control-button expand-toolbox" (click)="showDrawingFunctions = !showDrawingFunctions">
      <nb-icon icon="arrowhead-right-outline"></nb-icon>
    </nb-action>
  </nb-actions>
</div>
