import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth/auth.service';
import { DefibrillatorUpdatedData } from 'app/shared/models/defibrillatorUpdatedData.model';
import {KeycloakService} from "./device-management/keycloak.service";
import {UserRole} from "../../shared/enums/user-role.enum";

/**
 * Service providing ekg values
 */
@Injectable({
  providedIn: 'root'
})
export class DefibrillatorService {

  private cbrokerEndpoint = null;
  private ingestionEndpoint = null;
  private keycloakService = null;
  private companyInfos : string[] = []

  constructor(private injector: Injector,
              private httpClient: HttpClient,
              private authService: AuthService) {

    this.cbrokerEndpoint = `${environment.server.contextBrokerApi}/realms/${this.authService.getRealmId()}/ngsi-ld/v1`;
    this.ingestionEndpoint = `${environment.server.ingestionApi}/${this.authService.getRealmId()}/ngsi-ld/v1`;
    this.keycloakService = injector.get(KeycloakService);
  }

  /**
   * Get ekg values
   */
  getEkgValues(entityId: any, startTime: any, endTime: any, cBrokerEndpoint:string = null) {
    const entityType = 'HealthCase';
    const attributes = 'ekgData';

    let urlParameter = '?type=' + entityType;
    urlParameter += '&id=' + entityId;

    if (attributes && attributes.length > 0) {
      urlParameter += '&attrs=' + attributes;
    }

    urlParameter += '&timerel=between&time=' + startTime;

    urlParameter += '&endTime=' + endTime;


    const url: string = cBrokerEndpoint ? `${cBrokerEndpoint}/temporal/entities${urlParameter}` : `${this.cbrokerEndpoint}/temporal/entities${urlParameter}`;
    return this.httpClient.get(url);
  }
  updateDefibrillatorInfo(updatedData: DefibrillatorUpdatedData) {
    const url = `${this.ingestionEndpoint}/temporal/entities/urn:ngsi-ld:Defibrillator:${updatedData.id}/attr`;
    updatedData.id = 'urn:ngsi-ld:Defibrillator:' + updatedData.id;
    return this.httpClient.post(url, JSON.stringify(updatedData)).subscribe(
      response => {
      },
      error => {
        throw new Error(error);
      }
    );

  }
  checkEkgData(entityId: any) {
    const entityType = 'HealthCase';
    const attributes = 'ekgData';

    let urlParameter = '?type=' + entityType;
    urlParameter += '&id=' + entityId;

    if (attributes && attributes.length > 0) {
      urlParameter += '&attrs=' + attributes;
    }

    const url: string = `${this.cbrokerEndpoint}/entities${urlParameter}`;
    return this.httpClient.get(url);
  }

  getUserAttributes(): string[] {
    this.keycloakService.getUsersForRealmAndRole(this.authService.getRealmId(), UserRole.REGULAR_USER).subscribe(userItems => {
      userItems.forEach(user => {
        if(user.attributes['companyId'].length !== 0 || user.attributes['companyName'].length !== 0){
            let info : string = user.attributes['companyId'][0]+'-'+user.attributes['companyName'][0];
            if(!this.companyInfos.includes(info)){
              this.companyInfos.push(info);
            }
        }
      })
    });
    return this.companyInfos;
  }
}
