import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';

@Component({
  selector: 'uruk-ring-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class RingChartComponent extends ChartComponent implements OnInit {

  // Radius of the gauge
  @Input() radius: string | string[];

  // responsiveness parameters
  private baseLineWidth = 30; // minimum possible thickness for the lines
  private baseLineBorderWidth = 2; // minimum possible border width of the lines to separate each other
  private lineScaleFactor = 10; // a scale factor to determine the responsive line width
  private lineBorderWithScaleFactor = 50; // a scale factor to determine the responsive line border with

  // default values
  private defaultRadius = '90%';

  constructor(injector: Injector) {
    super(injector);

    // FIXME remove below
    this.data = [{
      value: 20,
      name: 'Perfect',
    },
      {
        value: 80,
        name: 'Good',
      },
      {
        value: 60,
        name: 'Commonly',
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    const defaultOptions: any = {
      series: [{
        type: 'gauge',
        name: this.seriesName || this.defaultSeriesName, // KPI Name
        radius: this.radius || this.defaultRadius,
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderColor: 'rgba(39, 39, 39, 0.9)',
            borderWidth: 15,
          }
        },
        axisLine: {
          lineStyle: {
            color: [[1, 'rgba(39, 39, 39, 0)']]
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        title: {
          show: false
        },
        detail: {
          show: false,
        }
      }]
    };

    // set the data if the chart is initialized with some data
    if (this.data) {
      defaultOptions.series[0].data = this.data;
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    const viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);

    const responsiveLineWidth = this.baseLineWidth + (viewportMin - this.baseDimension) / this.lineScaleFactor;
    const responsiveLineBorderWidth = this.baseLineBorderWidth + (viewportMin - this.baseDimension) / this.lineBorderWithScaleFactor;

    return {
      series: [{
        progress: {
          itemStyle: {
            borderWidth: responsiveLineBorderWidth
          }
        },
        axisLine: {
          lineStyle: {
            width: responsiveLineWidth
          }
        }
      }]
    };
  }

  setData(data: NGSIResult[]) {
    this.rawData = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);

    // TODO: handle multidimensional/multiseries charts
    let dimensionData, seriesData;
    this.rawData.forEach(axis => {
      if (axis.dimension) {
        dimensionData = axis;
      } else if (axis.series) {
        seriesData = axis;
      }
    });

    this.seriesName = seriesData.series.label;
    this.data = seriesData.data.map((value, index) => {
      return {
        name: dimensionData.data[index],
        value: value
      };
    });

    this.setSeriesData(this.data);
  }
}
