<closeable-dialog class="train-dialog-container">
  <label class="dialog-header title" dialog-card-header>
    {{ 'train' | translate }} &nbsp;
  </label>
  <label class="dialog-header title" dialog-card-header [ngStyle]="{'color':hasError ? '#FF1F00' : 'white'}">
    ( {{ 'trainID' | translate }} - {{ hmiVehicleNo }} , {{ 'journeyNo' | translate }} - {{ hmiJourneyNo }} )
  </label>

  <div dialog-card-footer class="dialog-body full-height">
    <div class="main-svg-container">
      <div class="svg-container" id="svgContainer"></div>
      <i class="fa fa-arrow-left back-icon" (click)="goBack()">{{ 'back' | translate }}</i>
    </div>

     <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab [tabIcon]="tractionSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'tractionSystem' | translate}}" [active]="activeTab === 'tractionSystem'" [tabId]="'tractionSystem'">
        <div class="d-flex " *ngIf="tractionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'requiredTractionForce' | translate }} : </label>
            <label class="column-info content">{{ requiredTractionForce }} kN</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'appliedTractionForce' | translate }} : </label>
            <label class="column-info content">{{ appliedTractionForce }} kN</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'forceAppliedAx3' | translate }} : </label>
            <label class="column-info content">{{ fDriveForceAx3 }} N</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'forceAppliedAx4' | translate }} : </label>
            <label class="column-info content">{{ fDriveForceAx4 }} N</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'forceAppliedAx3' | translate }} : </label>
            <label class="column-info content">{{ forceAppliedAx3 }} N</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'forceAppliedAx4' | translate }} : </label>
            <label class="column-info content">{{ forceAppliedAx4 }} N</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'cutOffTraction' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'cutOffTraction', cutOffTraction) }">{{ isVehicleMove(cutOffTraction) | translate }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'cutOffByPass' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'cutOffByPass', cutOffByPass) }">{{ cutOffByPass }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'tractionNull' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(tractionSystemRules, 'tractionNull', tractionNull) }">{{ isVehicleMove(tractionNull) | translate }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 2">
          <div class="flex-50">
            <label class="column-info title">{{ 'tempTrcInv1' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[9]">{{ tempTrcInv1[selectedWagonIndex] }} &deg;C </label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'tempTrcInv2' | translate }} : </label>
            <label class="column-info content">{{ tempTrcInv2[selectedWagonIndex] }} &deg;C </label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 2">
          <div class="flex-50">
            <label class="column-info title">{{ 'tempTrcRec' | translate }} : </label>
            <label class="column-info content">{{ tempTrcRec[selectedWagonIndex] }} &deg;C </label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'vTrcDclink' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[6]">{{ vTrcDclink[selectedWagonIndex] }} V</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'tempMotorAx3' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[5]">{{ tempMotorAx3[selectedWagonIndex] }} &deg;C </label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'tempMotorAx4' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[10]">{{ tempMotorAx4[selectedWagonIndex] }} &deg;C </label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title">{{ 'forceAvmaxAx3' | translate }} : </label>
            <label class="column-info content">{{ forceAvmaxAx3[selectedWagonIndex] / 1000 }} kN</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'forceAvmaxAx4' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[7]">{{ forceAvmaxAx4[selectedWagonIndex] / 1000 }} kN</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title">{{ 'velMotorAx3' | translate }} : </label>
            <label class="column-info content">{{ velMotorAx3[selectedWagonIndex] }} rpm</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'velMotorAx4' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[8]">{{ velMotorAx4[selectedWagonIndex] }} rpm</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="tractionTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'edBar' | translate }} :</label>
            <label class="column-info content">{{ edBar[selectedWagonIndex] }} kN </label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="brakeSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'brakeSystem' | translate}}" [active]="activeTab === 'brakeSystem'" [tabId]="'brakeSystem'">
        <div class="d-flex " *ngIf="brakeTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'bp' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[2]">{{ bp }} bar</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'mrp' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[3]">{{ mrp }} bar</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="brakeTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'pressureGovernorPBReleased' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorPBReleased', pressureGovernorPBReleased) }">{{ isBrakeError("pressureGovernorPBReleased", pressureGovernorPBReleased) | translate }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'pressureGovernorDetectionEmergencyBrake' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorDetectionEmergencyBrake', pressureGovernorDetectionEmergencyBrake) }">{{ isBrakeError("pressureGovernorDetectionEmergencyBrake", pressureGovernorDetectionEmergencyBrake) | translate }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="brakeTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'pressureGovernorIndirectBrakeControl' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'pressureGovernorIndirectBrakeControl', pressureGovernorIndirectBrakeControl) }">{{ isBrakeError("pressureGovernorIndirectBrakeControl", pressureGovernorIndirectBrakeControl) | translate }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'diagBrDirect' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'diagBrDirect', diagBrDirect) }">{{ diagBrDirect }}</label>
          </div>
        </div>

        <div class="d-flex" *ngIf="brakeTabInfo == 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'majorBrPark' | translate }} : </label>
            <label class="column-info content">{{ majorBrPark }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="brakeTabInfo == 2 || brakeTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'majorBrWsp' | translate }} : </label>
            <label class="column-info content">{{ majorBrWsp[selectedWagonIndex] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'test-Ep Fren Hatası' | translate }} : </label>
            <label class="column-info content">{{ 0 }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="brakeTabInfo == 2 || brakeTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'diagBrCPress' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(brakeSystemRules, 'diagBrCPress', diagBrCPress[selectedWagonIndex]) }">{{ diagBrCPress[selectedWagonIndex] }}</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="status[1] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'powerVoltageSystem' | translate}}" [active]="activeTab === 'powerVoltageSystem'" [tabId]="'powerVoltageSystem'">
        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'majorUnderVoltageError' | translate }} : </label>
            <label class="column-info content">{{ majorUnderVoltageError }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'catenaryVoltage' | translate }} : </label>
            <label class="column-info content">{{ catenaryVoltage }} kV</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'yuksekAkimKaynakliVakumHatasi' | translate }} : </label>
            <label class="column-info content">{{ errorMap[yuksekAkimKaynakliVakumHatasi] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'hvConfigError' | translate }} : </label>
            <label class="column-info content">{{ errorMap[hvConfigError] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'pantoABar' | translate }} : </label>
            <label class="column-info content" [ngClass]="'flex-50'+status[1]">{{ pantoABar }} A</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'tumPanolarIzole' | translate }} : </label>
            <label class="column-info content">{{ errorMap[tumPanolarIzole] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title">{{ 'pantografDustu' | translate }} : </label>
            <label class="column-info content">{{ errorMap[pantografDustu] }}</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'diagPantoComp' | translate }} : </label>
            <label class="column-info content">{{ errorMap[diagPantoComp] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'pTrain' | translate }} :</label>
            <label class="column-info content">{{ pTrain }} kW</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'vcbCloseSkax' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'vcbCloseSkax', vcbCloseSkax) }">{{ vcbCloseSkax }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'pressurePs1SafeguardAddSkax' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'pressurePs1SafeguardAddSkax', pressurePs1SafeguardAddSkax) }">{{ getPantographStatus(pressurePs1SafeguardAddSkax) | translate}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'majorMvError' | translate }} : </label>
            <label class="column-info content">{{ errorMap[majorMvError] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'hvDisconnectorCloseSKA1' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseSKA1', hvDisconnectorCloseSKA1) }">{{ hvDisconnectorCloseSKA1 }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title">{{ 'hvDisconnectorCloseSKA2' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseSKA2', hvDisconnectorCloseSKA2) }">{{ hvDisconnectorCloseSKA2 }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'hvDisconnectorCloseOA2' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'hvDisconnectorCloseOA2', hvDisconnectorCloseOA2) }">{{ hvDisconnectorCloseOA2 }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 2">
          <div class="flex-50">
            <label class="column-info title">{{ 'tractionTransformerOilFlow' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOilFlow', tractionTransformerOilFlow[selectedWagonIndex]) }">{{ tractionTransformerOilFlow[selectedWagonIndex] }}</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'tractionTransformerOilLevel' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOilLevel', tractionTransformerOilLevel[selectedWagonIndex]) }">{{ tractionTransformerOilLevel[selectedWagonIndex] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 2">
          <div class="flex-50">
            <label class="column-info title">{{ 'tractionTransformerOverPressure' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerOverPressure', tractionTransformerOverPressure[selectedWagonIndex]) }">{{ tractionTransformerOverPressure[selectedWagonIndex] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'tractionTransformerTemp' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tractionTransformerTemp', tractionTransformerTemp[selectedWagonIndex]) }">{{ tractionTransformerTemp[selectedWagonIndex] }}&deg;C </label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title">{{ 'tempApsInv' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempApsInv', tempApsInv[selectedWagonIndex]) }">{{ tempApsInv[selectedWagonIndex] }} &deg;C </label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'tempApsRec' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempApsRec', tempApsRec[selectedWagonIndex]) }">{{ tempApsRec[selectedWagonIndex] }} &deg;C </label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'vApsDclink' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'vApsDclink', vApsDclink[selectedWagonIndex]) }">{{ vApsDclink[selectedWagonIndex] }} V</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 4">
          <div class="flex-50">
            <label class="column-info title">{{ 'measuringBatteryVoltage' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'measuringBatteryVoltage', measuringBatteryVoltage[selectedWagonIndex]) }">{{ measuringBatteryVoltage[selectedWagonIndex] }} V</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'tempBattery' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(powerVoltageSystemRules, 'tempBattery', tempBattery[selectedWagonIndex]) }">{{ tempBattery[selectedWagonIndex] }} &deg;C</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="powerVoltageTabInfo == 4">
          <div class="flex-50">
            <label class="column-info title">{{ 'aBatteryCharger' | translate }} : </label>
            <label class="column-info content">{{ aBatteryCharger[selectedWagonIndex] }} A</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="airConditionSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'airConditionSystem' | translate}}" [active]="activeTab === 'airConditionSystem'" [tabId]="'airConditionSystem'">
        <div class="d-flex " *ngIf="airConditionTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title">{{ 'driverAirConditioningTemperature' | translate }} : </label>
            <label class="column-info content">{{ driverAirConditioningTemperature }}  C&deg;</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'passengerAirConditioningMode' | translate }} : </label>
            <label class="column-info content">{{ getAirConditionMod(passengerAirConditioningMode) | translate }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="airConditionTabInfo == 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'hvacsInhibition_X' | translate }} : </label>
            <label class="column-info content">{{ errorMap[hvacsInhibition_X] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="airConditionTabInfo == 2">
          <div class="flex-50">
            <label class="column-info title"> {{ 'majorHvac' | translate }} :</label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', driverMajorHvac[selectedWagonIndex]) }">{{ driverMajorHvac[selectedWagonIndex] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'minorHvac' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', driverMinorHvac[selectedWagonIndex]) }">{{ driverMinorHvac[selectedWagonIndex] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="airConditionTabInfo == 3">
          <div class="flex-50">
            <label class="column-info title"> {{ 'majorHvac' | translate }} :</label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', passengerMajorHvac1[selectedWagonIndex]) }">{{ passengerMajorHvac1[selectedWagonIndex] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'minorHvac' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', passengerMinorHvac1[selectedWagonIndex]) }">{{ passengerMinorHvac1[selectedWagonIndex] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="airConditionTabInfo == 4">
          <div class="flex-50">
            <label class="column-info title"> {{ 'majorHvac' | translate }} :</label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'majorHvac', passengerMajorHvac2[selectedWagonIndex]) }">{{ passengerMajorHvac2[selectedWagonIndex] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'minorHvac' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(airConditionSystemRules, 'minorHvac', passengerMinorHvac2[selectedWagonIndex]) }">{{ passengerMinorHvac2[selectedWagonIndex] }}</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="doorSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'doorSystem' | translate}}" [active]="activeTab === 'doorSystem'" [tabId]="'doorSystem'">
        <div class="d-flex " *ngIf="doorTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'door1LevelFault' | translate }} :</label>
            <label class="column-info content">{{ door1LevelFault }}</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'door2LevelFault' | translate }} : </label>
            <label class="column-info content">{{ door2LevelFault }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="doorTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'ickapi1SeviyeAriza' | translate }} :</label>
            <label class="column-info content">{{ errorMap[ickapi1SeviyeAriza] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'kapi1SeviyeAriza' | translate }} :</label>
            <label class="column-info content">{{ errorMap[kapi1SeviyeAriza] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="doorTabInfo == 1">
          <div class="flex-50">
            <label class="column-info title"> {{ 'prmAsansorKapi1Devrede' | translate }} :</label>
            <label class="column-info content">{{ errorMap[prmAsansorKapi1Devrede] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'doorLoopOkLed' | translate }} :</label>
            <label class="column-info content">{{ doorLoopOkLed }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="doorTabInfo == 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'slDoorControlByPass' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(doorSystemRules, 'slDoorControlByPass', slDoorControlByPass) }">{{ getDoorBypassStatus(slDoorControlByPass) | translate }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="false">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'ickapi2SeviyeAriza' | translate }} : </label>
            <label class="column-info content">{{ errorMap[ickapi2SeviyeAriza] }}</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'kapi2SeviyeAriza' | translate }} : </label>
            <label class="column-info content">{{ errorMap[kapi2SeviyeAriza] }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="false">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'prmAsansorKapi2Devrede' | translate }} : </label>
            <label class="column-info content">{{ errorMap[prmAsansorKapi2Devrede] }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'externalTemperature' | translate }} : </label>
            <label class="column-info content">{{ externalTemperature }}  C&deg;</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="otherSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'otherSystem' | translate}}" [active]="activeTab === 'otherSystem'" [tabId]="'otherSystem'">
        <div class="d-flex " *ngIf="otherTabInfo === 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'interiorLightingMode' | translate }} : </label>
            <label class="column-info content">{{ getInteriorLightingMode(interiorLightingMode) | translate }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'toiletIsNotActive' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletIsNotActive', toiletIsNotActive) }">{{ toiletIsNotActive }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="otherTabInfo === 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'toiletWaterLevelLow' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletWaterLevelLow', toiletWaterLevelLow) }">{{ toiletWaterLevelLow }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'toiletBowlFull' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletBowlFull', toiletBowlFull) }">{{ toiletBowlFull }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="otherTabInfo === 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'toiletWastewaterTankFull' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletWastewaterTankFull', toiletWastewaterTankFull) }">{{ toiletWastewaterTankFull }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'toiletSosSignal' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(otherSystemRules, 'toiletSosSignal', toiletSosSignal) }">{{ toiletSosSignal }}</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab [tabIcon]="communicationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'communicationSystem' | translate}}" [active]="activeTab === 'communicationSystem'"
              [tabId]="'communicationSystem'">
        <nb-tabset>
          <nb-tab tabTitle="{{'mvbCommunication' | translate}}" [active]="activeTab ==='mvbCommunication'"
                  [tabId]="'mvbCommunication'">
            <table id="mvbCom-table">
              <thead>
              <tr [ngClass]="'header'">
                <th>{{ 'SKA1' | translate }}</th>
                <th>{{ 'OA1' | translate }}</th>
                <th>{{ 'OA2' | translate }}</th>
                <th>{{ 'OA3' | translate }}</th>
                <th>{{ 'SKA2' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let mvbCom of mvbComList">
                <td [ngClass]="{'no-border': mvbCom.ska1 === ''}">{{ mvbCom.ska1 }}</td>
                <td [ngClass]="{'no-border': mvbCom.oa1 === ''}">{{ mvbCom.oa1 }}</td>
                <td [ngClass]="{'no-border': mvbCom.oa2 === ''}">{{ mvbCom.oa2 }}</td>
                <td [ngClass]="{'no-border': mvbCom.oa3 === ''}">{{ mvbCom.oa3 }}</td>
                <td [ngClass]="{'no-border': mvbCom.ska2 === ''}">{{ mvbCom.ska2 }}</td>
              </tr>
              </tbody>
            </table>

            <div class="flex-50" style="margin-top: 20px;">
              <label class="column-info title float-right"
                     style="margin-right: 20px;">{{ 'NORMAL' | translate }}</label>
              <label class="column-info title float-right"
                     style="color: red; margin-right: 20px;">{{ 'HATA ' | translate }}</label>
            </div>
          </nb-tab>

          <nb-tab tabTitle="{{'canCommunication' | translate}}" [active]="activeTab === 'canCommunication'"
                  [tabId]="'canCommunication'">
            <table id="canCom-table">
              <thead>
              <tr class="header">
                <th colspan="4">SKA-1 RIOM</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let canCom of camComList">
                <td [ngClass]="{'no-border': canCom.riom1 === ''}">{{ canCom.riom1 }}</td>
                <td [ngClass]="{'no-border': canCom.riom2 === ''}">{{ canCom.riom2 }}</td>
                <td [ngClass]="{'no-border': canCom.riom3 === ''}">{{ canCom.riom3 }}</td>
                <td [ngClass]="{'no-border': canCom.riom4 === ''}">{{ canCom.riom4 }}</td>
              </tr>
              </tbody>
            </table>

            <div class="flex-50" style="margin-top: 20px;">
              <label class="column-info title float-right"
                     style="margin-right: 20px;">{{ 'NORMAL' | translate }}</label>
              <label class="column-info title float-right"
                     style="color: red; margin-right: 20px;">{{ 'HATA ' | translate }}</label>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
       <nb-tab  [tabIcon]="status[4] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
                tabTitle="{{'predictiveMaintenance' | translate}}" [active]="activeTab === 'predictiveMaintenance'" [tabId]="'predictiveMaintenance'">
         <div  class="d-flex ">
           <div class="flex-50">
             <label [ngClass]="'flex-50'+status[4]"> {{predictiveMaintenanceInfo | translate}} </label>
           </div>
         </div>
       </nb-tab>

       <nb-tab [tabIcon]="signalizationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}"
              tabTitle="{{'signalizationSystem' | translate}}" [active]="activeTab === 'signalizationSystem'"
              [tabId]="'signalizationSystem'">
        <div class="d-flex " *ngIf="signalizationTabInfo === 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'etcsIsolationByPass' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'etcsIsolationByPass', etcsIsolationByPass) }">{{ etcsIsolationByPass }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'ertmsCutOff' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'ertmsCutOff', ertmsCutOff) }">{{ ertmsCutOff }}</label>
          </div>
        </div>

        <div class="d-flex " *ngIf="signalizationTabInfo === 1">
          <div [ngClass]="'flex-50'">
            <label class="column-info title"> {{ 'atsByPass' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'atsByPass', atsByPass) }">{{ atsByPass }}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{ 'ertmsServiceBrake' | translate }} : </label>
            <label class="column-info content"
                   [ngStyle]="{ 'color': getDynamicColor(signalizationSystemRules, 'ertmsServiceBrake', ertmsServiceBrake) }">{{ ertmsServiceBrake }}</label>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>

  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info content">{{ 'ascLimit' | translate }} : &nbsp; </label>
      <label [ngClass]="'flex-50'+status[0]"> {{ ascLimit }} km/h </label> <br/>
      <label class="column-info title">{{ 'coordinate' | translate }} : </label>
      <label class="column-info content">{{ location }}</label>
    </div>
    <div dialog-card-footer class="footer-right">
      <label class="column-info content">{{ 'speed' | translate }} : </label>
      <label class="column-info content">{{ speed }} km/h</label> <br/>
      <label class="action-button" (click)="getFirstData()">
        <nb-icon [icon]="'arrow-back'" pack="eva" nbTooltip="Geriye Dön"></nb-icon>
      </label>
      <label class="action-button" (click)="refreshData()">
        <nb-icon [icon]="'refresh-outline'" pack="eva" nbTooltip="Yenile"></nb-icon>
      </label>
      <label *ngIf="isLiveMode" class="column-info content">{{ 'liveMode' | translate }} </label>
      <label *ngIf="!isLiveMode" class="column-info content">{{ 'firstMode' | translate }} </label>
    </div>
  </div>
</closeable-dialog>
