import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';
import {AxisSettings} from '../../../models/visualization/axis-settings.model';
import {DataSeries} from '../../../models/visualization/data-series.model';
import {DataFormatUtil} from '../../../utils/data-format-util';

import domtoimage from 'dom-to-image';

@Component({
  selector: 'uruk-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent extends ChartComponent implements OnInit, OnDestroy {

  // The value of the data
  @Input() data: string;

  // The class of the icon
  @Input() icon: string;

  // reference to the DOM element
  @ViewChild('indicator', { read: ElementRef }) indicatorChart:ElementRef;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setData(data: NGSIResult[]) {
    const chartData = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);
    if (chartData.length > 0) {
      const seriesData = chartData[0];
      if (seriesData.data.length > 0 && this.chartSettings.seriesSettings.length > 0) {
        const value = seriesData.data[0];
        const chartSeries: AxisSettings = this.chartSettings.seriesSettings[0];
        const kpiSeries: DataSeries = this.kpi.series[chartSeries.index];

        const formatted = DataFormatUtil.formatValue(value, kpiSeries.format, chartSeries.precision, chartSeries.thousandSeparator);
        this.data = formatted + ' ' + kpiSeries.unit;
      }
    }
  }

  exportImage() {
    const node = this.indicatorChart.nativeElement;
    domtoimage.toPng(node, {
      style: {
        'background': 'rgba(39, 39, 39, 0.6)'
      }
    })
      .then((dataUrl) => {
        this.downloadFile(dataUrl, this.panelTitle + '.png');
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  exportCSV() {
    if (this.data) {
      const csvData = this.panelTitle + '\n' + this.data + '\n';

      // download csv file
      // add a BOM as first characters in the file to force Excel to use UTF-8
      this.downloadFile('data:text/csv;charset=utf-8,%EF%BB%BF' + csvData, this.panelTitle + '.csv');
    }
  }

  defaultChartOptions() {
  }

  responsiveChartOptions() {
  }
}
