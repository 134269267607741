<closeable-dialog class="defibrillator-dialog-container" [displayFooter]="showFooter">
  <div class="dialog-header title" dialog-card-header><label>{{ 'automaticExternalDefibrillator' | translate }}  &nbsp;</label>
    <div class="badge" [ngClass]="'defibrillator-badge-' + defibrillatorStatus"> {{ 'defibrillator-badge-' + defibrillatorStatusMessage | translate }}
      <label>
        <img src="{{this.chargedBatteryIcon}}" width="11" height="11" *ngIf="isBatteryErrorAED && !isLastTestTimeNotValid && !showTestError">
      </label>
    </div>
  </div>

  <div dialog-card-body class="dialog-body full-height">
    <div class="centered-image">
      <img class="img1" src="assets/images/full-automatic-display-aed.png" width="196" height="250"  *ngIf="isFullAutomaticDisplay"/>
      <img class="img2" src="assets/images/full-automatic-non-display-aed.png" width="196" height="250" *ngIf="isFullAutomaticNonDisplay"/>
      <img class="img3" src="assets/images/half-automatic-display-aed.png" width="196" height="250" *ngIf="isHalfAutomaticDisplay"/>
      <img class="img4" src="assets/images/half-automatic-non-display-aed.png" width="196" height="250" *ngIf="isHalfAutomaticNonDisplay"/>
    </div>
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{'summary' | translate}}" [active]="activeTab === 'summary'" [tabId]="'summary'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'General Information' | translate}} : </label>
          </div>
          <div class="flex-50">
            <label class="column-info title">{{'IOT Module' | translate}} : </label>
          </div>
        </div>


        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'AEDDeviceModel' | translate}} : </label>
            <label class="column-info content">{{AEDDeviceModel === 0 ?
              ('AEDDeviceModelHalf' | translate) : 'AEDDeviceModelFull' | translate }}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleSerialNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleSerialNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'AEDSerialNumber' | translate}} : </label>
            <label class="column-info content">{{AEDSerialNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleSoftwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleSoftwareRevisionNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'batterySerialNumber' | translate}} : </label>
            <label class="column-info content">{{batterySerialNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleHardwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleHardwareRevisionNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'hardwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{hardwareRevisionNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'simOperatorName' | translate}} : </label>
            <label [ngClass]="'column-info content'">{{simOperatorName}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'softwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{softwareRevisionNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'simTelNo' | translate}} :</label>
            <label class="column-info content">{{simTelNo}}</label>
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-50 ">
            <div  class="d-flex ">
              <div class="flex-50">
                <label class="column-info title"> {{'AEDDeviceLCD' | translate}} :</label>
                <label class="column-info content">{{AEDDeviceLCD === 0 ?
                  ('lcdNonScreen' | translate) : 'lcdScreen' | translate}}</label>
              </div>
            </div>

            <div  class="d-flex ">
              <div class="flex-50">
                <label class="column-info title"> {{'primaryLanguagePack' | translate}} :</label>
                <label class="column-info content">{{primaryLanguagePack}}</label>
              </div>
            </div>

            <div  class="d-flex ">
              <div class="flex-50">
                <label class="column-info title"> {{'secondaryLanguagePack' | translate}} :</label>
                <label class="column-info content">{{secondaryLanguagePack}}</label>
              </div>
            </div>

            <div  class="d-flex" style="flex-wrap: nowrap;">
              <div class="flex-50" style="flex: 1; align-items: center;">
              <label class="column-info title"> {{'adultPadExpirationDate' | translate}} :</label>
                <label class="column-info content">{{(adultTempQuery?.displayText ? adultTempQuery.displayText : adultPadExpirationDate) | wordForWordTranslate}}</label>
                <div *ngIf="!adultTempQuery" class="calendar-button" (click)="handlePadDateSelection('adult')" [title]="'Select dates' | translate">
                  <i class="fa fa-calendar"></i>
                </div>
                <span *ngIf="adultTempQuery" class="action-button approve"
                      [title]="'Approve Selection' | translate" (click)="updateAdultPadDate()">
                  <i class="fa fa-check"></i>
                </span>

                <span *ngIf="adultTempQuery" class="action-button cancel"
                      [title]="'Cancel Selection' | translate" (click)="adultTempQuery = undefined">
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>

            <div  class="d-flex" style="flex-wrap: nowrap;">
              <div class="flex-50" style="flex: 1; align-items: center;">
                <label class="column-info title"> {{'pediatricPadExpirationDate' | translate}} :</label>
                <label class="column-info content">{{(pediatricTempQuery?.displayText ? pediatricTempQuery.displayText : pediatricPadExpirationDate) | wordForWordTranslate}}</label>
                <div *ngIf="!pediatricTempQuery" class="calendar-button" (click)="handlePadDateSelection('pediatric')" [title]="'Select dates' | translate">
                  <i class="fa fa-calendar"></i>
                </div>
                <span *ngIf="pediatricTempQuery" class="action-button approve"
                      [title]="'Approve selection' | translate" (click)="updatePediatricPadDate()">
                  <i class="fa fa-check"></i>
                </span>
                <span *ngIf="pediatricTempQuery" class="action-button cancel"
                      [title]="'Cancel selection' | translate" (click)="pediatricTempQuery = undefined">
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>

            <div  class="d-flex" style="flex-wrap: nowrap;">
              <div class="flex-50" style="flex: 1; align-items: center;">
                <label class="column-info title"> {{'Guarantee Start Date' | translate}} :</label>
                <label class="column-info content">{{(guaranteeTempQuery?.displayText ? guaranteeTempQuery.displayText : guaranteeStartDate) | wordForWordTranslate}}</label>
                <div *ngIf="!guaranteeTempQuery && !userService.isRegularUser()" class="calendar-button" (click)="handlePadDateSelection('guarantee')" [title]="'Select dates' | translate">
                  <i class="fa fa-calendar"></i>
                </div>
                <span *ngIf="guaranteeTempQuery" class="action-button approve"
                      [title]="'Approve selection' | translate" (click)="updateGuaranteeStartDate()">
                  <i class="fa fa-check"></i>
                </span>
                <span *ngIf="guaranteeTempQuery" class="action-button cancel"
                      [title]="'Cancel selection' | translate" (click)="guaranteeTempQuery = undefined">
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>

            <div  class="d-flex" style="flex-wrap: nowrap;">
              <div class="flex-50" style="flex: 1; align-items: center;">
                <label class="column-info title"> {{'Company Name' | translate}} :</label>
                <nb-select class="custom-nb-select" [disabled]="!(userService.isDistributorUser() || userService.isRealmManager())" (selectedChange)="companyChanged($event)" [(selected)]="companyName" size="tiny" selectWidth [options]="companyNameOptions" [placeholder]="'Company Name' | translate">
                  <nb-option *ngFor="let company of companyNameOptions" [value]="company">
                    {{ company }}
                  </nb-option>
                </nb-select>
                <span *ngIf="isCompanyChanged" class="action-button approve"
                      [title]="'Approve Selection' | translate" (click)="updateCompanyName()">
                  <i class="fa fa-check"></i>
                </span>

                <span *ngIf="isCompanyChanged" class="action-button cancel"
                      [title]="'Cancel Selection' | translate" (click)="companyName = initialCompanyName; isCompanyChanged=false">
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="flex-50">
            <label class="column-info title">{{ 'description' | translate }} :</label>

            <div class="relative-container">
              <textarea class="description-container" id="descriptionInput" [attr.readonly]="isReadOnlyMode ? 'readonly' : null" [(ngModel)]="description" placeholder="{{ 'description' | translate }}"></textarea>
              <div class="icon-container" *ngIf="isReadOnlyMode" (click)="enterEditMode()">
                <i class="fas fa-pencil-alt"></i>
              </div>
              <div class="icon-container save-icon" *ngIf="!isReadOnlyMode" (click)="saveChanges()">
                <i class="fas fa-save"></i>
              </div>
            </div>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{'bit' | translate}}" [active]="activeTab === 'bit'" [tabId]="'bit'">
        <table id="defibrillator-table">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'testTime' | translate}}</th>
            <th>{{'testType' | translate}}</th>
            <th>{{'IOTModuleBatteryVoltageLevel' | translate}}</th>
            <th>{{'AEDDeviceBatteryVoltageLevel' | translate}}</th>
            <th>{{'testResult' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let defibrillatorTest of paginatedDefibrillatorTests">
            <td>{{ defibrillatorTest.testDay+'.'+defibrillatorTest.testMonth+ '.'+defibrillatorTest.testYear+','+defibrillatorTest.testHour+':'+defibrillatorTest.testMinute}}</td>
            <td>{{ defibrillatorTest.testType }}</td>
            <td [ngClass]= defibrillatorTest.colorIOTModuleBatteryVoltageLevel>{{ defibrillatorTest.IOTModuleBatteryVoltageLevel }} V</td>
            <td [ngClass]= defibrillatorTest.colorAEDDeviceBatteryVoltageLevel>{{ defibrillatorTest.AEDDeviceBatteryVoltageLevel }} V</td>
            <td [ngClass]= defibrillatorTest.colorTestResult>{{ calculateTestResultDescription(defibrillatorTest.testResult , defibrillatorTest.padTestResult)}}</td>
            <div class="action-button">
              <td><i class="fa fa-eye"(click)="openTestDialog(defibrillatorTest)">  </i></td>
            </div>
          </tr>
          </tbody>
        </table>
      </nb-tab>
      <nb-tab *ngIf="isVisibleHealthCaseTab" tabTitle="{{'Case'|translate}}" [active]="activeTab === 'case'" [tabId]="'case'">
        <table id="healthcase-table">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'healthCaseId' | translate}}</th>
            <th>{{'caseDate' | translate}}</th>
            <th>{{'caseHour' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let healthCases of paginatedHealthCases; let i = index">
            <td>{{ healthCases.healthCaseId }}</td>
            <td>{{ healthCases.caseDate }}</td>
            <td>{{ healthCases.caseHour }}</td>
            <div class="action-button">
              <td><i class="fa fa-eye"(click)="openEkgDialog(healthCases, i)">  </i></td>
            </div>
          </tr>
          </tbody>
        </table>
      </nb-tab>
    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{defibrillatorLocation}} </label>
    </div>
    <div *ngIf="showSignalQuality" dialog-card-footer class="footer-right">
      <label class="column-info content">  {{this.connectionTypeName}} &nbsp; </label>
      <label>
        <img src="{{this.signalQualityIcon}}">
      </label>
      <label style="color:{{this.colorSignalQuality}};" class="column-info content"> &nbsp;  {{this.signalQuality}}</label>
    </div>
  </div>
</closeable-dialog>


