<closeable-dialog class="electricBus-dialog-container">
    <div class="dialog-header" dialog-card-header>
      <label class="column-info title">{{'bus' | translate}}</label>
      <label [ngClass]="'column-info content'+status[0]">({{plate}}, {{id}})</label>
    </div>

    <div dialog-card-body class="dialog-body full-height">
      <div>
        <img class="img1" src="assets/images/electricbus.png" width="700" height="300" />
        <img class="img2" src="assets/images/green-rectangle.png" width="90" height="75" *ngIf="isAccelerationPedalPositionGreen"/>
        <img class="img2" src="assets/images/yellow-rectangle.png" width="90" height="75" *ngIf="isAccelerationPedalPositionYellow"/>
        <img class="img2" src="assets/images/red-rectangle.png" width="90" height="75" *ngIf="isAccelerationPedalPositionRed"/>
        <img class="img3" src="assets/images/green-rectangle.png" width="110" height="75" *ngIf="isVehicleSpeedGreen"/>
        <img class="img3" src="assets/images/yellow-rectangle.png" width="110" height="75" *ngIf="isVehicleSpeedYellow"/>
        <img class="img3" src="assets/images/red-rectangle.png" width="110" height="75" *ngIf="isVehicleSpeedRed"/>
        <img class="img4" src="assets/images/green-rectangle.png" width="130" height="75" *ngIf="isBatterySOCGreen"/>
        <img class="img4" src="assets/images/yellow-rectangle.png" width="130" height="75" *ngIf="isBatterySOCYellow"/>
        <img class="img4" src="assets/images/red-rectangle.png" width="130" height="75" *ngIf="isBatterySOCRed"/>
      </div>


      <nb-tabset (changeTab)="onTabChange($event)">
        <nb-tab [tabIcon]="status[1] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : status[1] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
                tabTitle="{{'AKB/SKB' | translate}}" [active]="activeTab === 'akb/skb'" [tabId]="'akb/skb'">
          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title">{{'systemWorkingMode' | translate}} : </label>
              <label class="column-info content">{{systemOperatingMode}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'activeGear' | translate}} : </label>
              <label class="column-info content">{{activeGear}}</label>
            </div>
          </div>
          <div  class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'torqueRequest' | translate}} :</label>
              <label class="column-info content">{{torqueRequest}}</label>
            </div>
            <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'accelerationPedalPosition' | translate}} : </label>
              <label [ngClass]="'column-info content'+status[1]">{{acceleratorPedalPosition}}</label>
            </div>
          </div>

          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'akbState' | translate}} :</label>
              <label class="column-info content">{{akbState}}</label>
            </div>
          </div>
        </nb-tab>
        <nb-tab [tabIcon]="status[2] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : status[2] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
                tabTitle="{{'Motor' | translate}}" [active]="activeTab === 'motor'" [tabId]="'motor'">
          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'motorActualConsumptionOrRegen' | translate}} :</label>
              <label class="column-info content">{{motorActualConsumptionOrRegen}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title">{{'distanceTraveledTrip' | translate}} :</label>
              <label class="column-info content">{{distanceTraveledTrip}}</label>
            </div>
          </div>
          <div class="d-flex ">
            <div [ngClass]="'flex-50'">
              <label class="column-info title"> {{'vehicleSpeed' | translate}} :</label>
              <label [ngClass]="'column-info content'+status[2]">{{vehicleSpeed}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'distanceTraveledTotal' | translate}} :</label>
              <label class="column-info content">{{distanceTraveledTotal}}</label>
            </div>
          </div>

          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'actualMotorTorque' | translate}} :</label>
              <label class="column-info content">{{actualMotorTorque}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'actualMotorSpeed' | translate}} :</label>
              <label class="column-info content">{{actualMotorSpeed}}</label>
            </div>
          </div>
        </nb-tab>
        <nb-tab [tabIcon]="status[3] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : status[3] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
                tabTitle="{{'battery' | translate}}" [active]="activeTab === 'battery'" [tabId]="'battery'">
          <div class="d-flex ">
            <div [ngClass]="'flex-50'">
              <label class="column-info title"> {{'batterySOC' | translate}} :</label>
              <label [ngClass]="'column-info content'+status[3]">{{batterySOC}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'batteryRange' | translate}} :</label>
              <label class="column-info content">{{batteryRange}}</label>
            </div>
          </div>
          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'minimumCellTemperature' | translate}} :</label>
              <label class="column-info content">{{minimumCellTemperature}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'maximumCellTemperature' | translate}} :</label>
              <label class="column-info content">{{maximumCellTemperature}}</label>
            </div>
          </div>

          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'hvBatteryVoltage' | translate}} :</label>
              <label class="column-info content">{{hvBatteryVoltage}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'hvBatteryCurrent' | translate}} :</label>
              <label class="column-info content">{{hvBatteryCurrent}}</label>
            </div>
          </div>
        </nb-tab>
      </nb-tabset>
    </div>
    <div dialog-card-footer class="footer">
      <div dialog-card-footer class="footer-left">
        <label class="column-info title">{{'coordinate' | translate}}: </label>
        <label class="column-info content">{{location}}</label>
      </div>
    </div>
</closeable-dialog>
