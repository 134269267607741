import {BucketResult} from '../models/schema/aggregation/bucket-result';
import {OperatorType, SeasonalOperatorParam, TimeIntervalUnit} from '../enums/ngsi-query.enum';
import {NGSIResult} from '../models/ngsi/ngsi-result';
import {KPI} from '../models/kpi.model';
import {ChartSettings} from '../models/visualization/chart-settings.model';
import {AggregationResult} from '../models/schema/aggregation/aggregation-result';
import {AxisSettings} from '../models/visualization/axis-settings.model';
import {DimensionSeriesData} from '../models/report/dimension-series-data.model';
import {DataFormatUtil} from './data-format-util';
import {ChartDataFormat} from '../enums/chart-data-format';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {QueryOperator} from '../models/query/query-operator.model';
import {QueryOperatorParamValue} from '../models/query/query-operator-param-value.model';
import {UrukAggregationQuery} from '../models/ngsi/uruk-aggregation-query.model';
import {FormUtil} from './form-util';
import {NGSIResultUtil} from './ngsi-result-util';
import {EntityResult} from '../models/schema/entity-result';
import {UrukCrossAggQuery} from '../models/ngsi/uruk-cross-agg-query.model';

/**
 * Utility class to extract data from {@link NGSIResult}s based on associated {@link KPI} and {@link ChartSettings}
 */
@Injectable({
  providedIn: 'root'
})
export class DataExtractionUtil {
  constructor(private translateService: TranslateService) {
  }

  /**
   * Extracts data from {@link NGSIResult}s based on associated {@link KPI} and {@link ChartSettings}
   * @param kpi
   * @param chartSettings
   * @param results
   */
  public extractData(kpi: KPI, chartSettings: ChartSettings, results: NGSIResult[]): DimensionSeriesData[] {
    const extractedResults: DimensionSeriesData[] = [];
    // populate settings
    // dimensions
    for (let i = 0; i < chartSettings.dimensionSettings.length; i++) {
      const dimensionData: DimensionSeriesData = new DimensionSeriesData();
      dimensionData.dimension = kpi.dimensions[chartSettings.dimensionSettings[i].index];
      dimensionData.chartAxisSetting = chartSettings.dimensionSettings[i];
      extractedResults.push(dimensionData);
    }
    // series
    for (let i = 0; i < chartSettings.seriesSettings.length; i++) {
      const seriesData: DimensionSeriesData = new DimensionSeriesData();
      seriesData.series = kpi.series[chartSettings.seriesSettings[i].index];
      seriesData.chartAxisSetting = chartSettings.seriesSettings[i];
      extractedResults.push(seriesData);
    }

    if (results.length > 0) {
      // parse results based on the type of the result
      const firstResult: NGSIResult = results[0];

      // aggregation results
      if (firstResult.result instanceof AggregationResult) {
        for (let i = 0; i < chartSettings.seriesSettings.length; i++) {
          const seriesData: DimensionSeriesData = extractedResults[i];
          seriesData.data.push(this.extractAggregationResult(results[0].getAggregationResult(), chartSettings.seriesSettings[i]));
          extractedResults[i] = seriesData;
        }

        // process root-level buckets recursively
      } else if (firstResult.result instanceof BucketResult) {
        results.forEach(result => {
          this.extractBucketResult(kpi, 0, [], chartSettings, result.getBucketResult(), extractedResults);
        });

        // entity results
      } else {
        results.forEach(result => {
          this.extractEntityResult(chartSettings, result, extractedResults);
        });
      }
    }

    // TODO: handle multidimensional/multiseries charts
    // translate dimension labels
    extractedResults.forEach(result => {
      if (result.dimension && result.data?.length) {
        this.translateService.get(result.data).subscribe(translations => {
          //result.data = Object.values(translations);
          // set the translated label for each data
          result.data = result.data.map(data => translations[data])
        });

      //  result.data = result.data.map(data => this.translateService.instant(data));
      }
    });

    return extractedResults;
  }

  /**
   * Extracts data for a {@link BucketResult} recursively. It populates the columnsData as the buckets are processed
   * @param kpi KPI for which the results are obtained
   * @param dimensionIndex Index of the processed dimension
   * @param extractedRowValues Values extracted so for for the processed dimensions
   * @param chartSettings All chart settings that are referred to access dimension or series settings
   * @param bucketResult Bucket result
   * @param columnsData Data structure containing the eventual data to be extracted
   * @private
   */
  private extractBucketResult(kpi: KPI,
                              dimensionIndex: number,
                              extractedRowValues: string[],
                              chartSettings: ChartSettings,
                              bucketResult: BucketResult,
                              columnsData: DimensionSeriesData[]): void {

    const chartDimensionSettings: AxisSettings = columnsData[dimensionIndex].chartAxisSetting;
    // if we reach the last dimension, extract dimension label from key and values for each series from the actual value
    if (this.isLastDimension(kpi, dimensionIndex)) {
      // extract value for the dimension
      let bucketAggregation: QueryOperator;

      if (kpi.query instanceof UrukAggregationQuery) {
        bucketAggregation = (kpi.query as UrukAggregationQuery).bucketAgg[dimensionIndex];
      } else if (kpi.query instanceof UrukCrossAggQuery) {
        // TODO: below logic handles left and inner joins ([0]), consider handling other join types (e.g. right) as well
        bucketAggregation = (kpi.query as UrukCrossAggQuery).subQueries[0].bucketAgg[dimensionIndex];
      }

      const dataType: ChartDataFormat = this.getDataType(bucketAggregation, columnsData[dimensionIndex]);
      const dimensionValue: any = DataFormatUtil.formatValue(
        this.extractKeyValue(bucketAggregation, chartDimensionSettings, bucketResult), dataType, chartDimensionSettings.precision, chartDimensionSettings.thousandSeparator
      );

      // extract values for series
      const seriesValues: any[] = chartSettings.seriesSettings.map(seriesSettings => {
        return this.extractAggregationResult(bucketResult.aggregationResult, seriesSettings);
      });

      const rowValues: any[] = extractedRowValues.concat([dimensionValue]).concat(seriesValues);

      // put each value into corresponding dimension or series data array
      // if there is at least a null or empty value, skip the row completely
      if (!rowValues.some(value => FormUtil.isEmptyValue(value))) {
        for (let i: number = 0; i < columnsData.length; i++) {
          columnsData[i].data.push(rowValues[i]);
        }
      }

    } else {
      const bucketAggregation: QueryOperator = (kpi.query as UrukAggregationQuery).bucketAgg[dimensionIndex];
      const dataType: ChartDataFormat = kpi.dimensions[dimensionIndex].keys[chartDimensionSettings.path].dataType;
      const dimensionValue: any = DataFormatUtil.formatValue(
        this.extractKeyValue(bucketAggregation, chartDimensionSettings, bucketResult), dataType, chartDimensionSettings.precision, chartDimensionSettings.thousandSeparator
      );

      // fetch the dimension settings for the next dimension
      // chartDimensionSettings = chartSettings.dimensionSettings.find(settings => settings.index === (dimensionIndex + 1));
      bucketResult.bucketResults.forEach(subResult => {
        this.extractBucketResult(kpi, dimensionIndex + 1, extractedRowValues.concat([dimensionValue]), chartSettings, subResult, columnsData);
      });
    }
  }

  /**
   * Returns the data format for the given aggregation operation.
   * @param aggregationOperation the query operator for aggration
   * @param columnsData dimension series data
   * @return the corresponding chart data format.
   * */
  private getDataType(aggregationOperation: QueryOperator, columnsData: DimensionSeriesData): ChartDataFormat {
    const operator: string = aggregationOperation.op;
    switch (operator) {
      case OperatorType.TIME_INTERVAL: {
        if (aggregationOperation.params[0] instanceof QueryOperatorParamValue) {
          const paramValue: QueryOperatorParamValue = aggregationOperation.params[0] as QueryOperatorParamValue;
          const timeIntervalUnit = paramValue.value[paramValue.value.length - 1];
          switch (timeIntervalUnit) {
            case TimeIntervalUnit.DAY:
              return ChartDataFormat.DATE;
            case TimeIntervalUnit.HOUR:
              return ChartDataFormat.HOUR;
            case TimeIntervalUnit.MONTH:
              return ChartDataFormat.MONTH;
            case TimeIntervalUnit.YEAR:
              return ChartDataFormat.YEAR;
          }
        }
      }
    }
    return columnsData.getDataType();
  }

  private extractEntityResult(chartSettings: ChartSettings, result: NGSIResult, columns: DimensionSeriesData[]): void {
    const values: any[] = [];
    for (let i = 0; i < columns.length; i++) {
      const entityResult: EntityResult = result.getEntityResult();
      let value: any = NGSIResultUtil.extractEntityAttributeValue(entityResult.entity, columns[i].chartAxisSetting.path, entityResult.queryOptions);

      if (i < chartSettings.dimensionSettings.length) {
        const dataType: ChartDataFormat = columns[i].getDataType();
        const precision: number = columns[i].chartAxisSetting.precision;
        const thousandSeparator: boolean = columns[i].chartAxisSetting.thousandSeparator;
        value = DataFormatUtil.formatValue(value, dataType, precision, thousandSeparator);
      }

      if (value === null) {
        return null;
      }
      values.push(value);
    }

    for (let i = 0; i < values.length; i++) {
      columns[i].data.push(values[i]);
    }
  }

  private extractAggregationResult(result: AggregationResult, seriesSetting: AxisSettings): any {
    return result.result[seriesSetting.path];
  }

  private isLastDimension(kpi: KPI, dimensionIndex: number): boolean {
    if (kpi.dimensions.length === dimensionIndex + 1) {
      return true;
    } else {
      return false;
    }
  }

  private extractKeyValue(aggregationOperation: QueryOperator, chartDimensionSettings: AxisSettings, bucketResult: BucketResult): any {
    const operator: string = aggregationOperation.op;
    switch (operator) {
      case OperatorType.SEASONAL: {
        return this.extractValueForSeasonalOperator(aggregationOperation.params[0] as QueryOperatorParamValue, bucketResult.key);
      }
      case OperatorType.GROUP_BY: {
        // TODO comment on executing ngsi path on key field
        return this.extractValueForGroupByOperator(chartDimensionSettings, bucketResult.key);
      }
      case OperatorType.TIME_INTERVAL: {
        return this.extractValueForTimeIntervalOperator(bucketResult.key);
      }
      case OperatorType.MAX_N:{
        return this.extractValueForMaxNOperator(chartDimensionSettings, bucketResult.key);
      }
    }
    console.error('Unhandled aggregation operator', operator);
    return '';
  }

  private extractValueForMaxNOperator(chartDimensionSetting: AxisSettings, key: any): any {
    //console.log(chartDimensionSetting.path+" "+key.linecode);

    return key[chartDimensionSetting.path];
  }

  private extractValueForGroupByOperator(chartDimensionSetting: AxisSettings, key: any): any {
    return key[chartDimensionSetting.path];
  }

  private extractValueForTimeIntervalOperator(key: any): any {
    return key.ts;
  }

  private extractValueForSeasonalOperator(param: QueryOperatorParamValue, key: any): any {
    const season: any = key.season;
    if (param.value === SeasonalOperatorParam.DAY_OF_WEEK) {
      // TODO uncomment the following line when the charts adopt the data extraction util
      return this.getDayOfWeekSeasonalValue(season);
      // return season;
    }
    console.error('Unhandled seasonal operator parameter', param);
    return '';
  }

  private getDayOfWeekSeasonalValue(result: number): string {
    switch (result) {
      case 0.0:
        return this.translateService.instant('Pazar');
      case 1.0:
        return this.translateService.instant('Pazartesi');
      case 2.0:
        return this.translateService.instant('Salı');
      case 3.0:
        return this.translateService.instant('Çarşamba');
      case 4.0:
        return this.translateService.instant('Perşembe');
      case 5.0:
        return this.translateService.instant('Cuma');
      case 6.0:
        return this.translateService.instant('Cumartesi');
    }
    console.warn('Invalid day of week seasonal value:', result);
    return '';
  }
}
