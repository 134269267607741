import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import {UserService} from '../data/user.service';
import {StorageUtil} from '../../../shared/utils/storage-util';
import {StringUtil} from '../../../shared/utils/string-util';
import {BEARER_TOKEN_PREFIX, Headers, RestParameters, RestUtil} from '../../../shared/utils/rest-util';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {APP_BASE_HREF} from '@angular/common';
import {PasswordPolicy} from '../../../shared/models/password/password-policy.model';

enum KEYCLOAK_GRANT_TYPE {
  REFRESH_TOKEN = 'refresh_token',
  UMA_TOKEN = 'urn:ietf:params:oauth:grant-type:uma-ticket'
}

enum KEYCLOAK_ERROR_DESCRIPTION {
  SESSION_NOT_ACTIVE = 'Session not active',
  INVALID_BEARER_TOKEN = 'Invalid bearer token'
}

export const REALM_ID_PLACEHOLDER = '${REALM_ID}';

/**
 * Handles authentication & authorization
 */
@Injectable()
export class AuthService {

  // keeps the realm id which is passed to service calls
  private realmId: string = environment.auth.master_realm_id;
  // keeps the realm id for keycloak services
  private keycloakRealmId: string = 'master';
  // keeps whether the timer is set for the token expiration
  private isTimeoutSetForTokenExpiration: boolean = false;

  constructor(private httpClient: HttpClient, private userService: UserService, @Inject(APP_BASE_HREF) private baseHref: string) {
    // get the realm id by removing the forward slash from base href
    this.realmId = baseHref.substring(1);
    // set the keycloak realm id
    this.keycloakRealmId = this.realmId === environment.auth.master_realm_id ? 'master' : this.realmId;
    const jwt = this.getJWTContent(StorageUtil.getUMATicket());
    if (jwt) {
      if (jwt.iss.endsWith(this.keycloakRealmId)) {
        this.userService.setUserData(jwt);
      }
      // the token does not belong to the realm to which user wants to access
      else {
        // authenticate user for the requested realm
        this.authenticate();
      }
    }
  }

  /**
   * Returns the active realm id.
   * @param keycloak if true, keycloak realm id is returned
   * */
  public getRealmId(keycloak = false): string {
    return keycloak ? this.keycloakRealmId :this.realmId;
  }

  /**
   * Determine whether user is authenticated or not
   */
  isAuthenticated(): boolean {
    // get token from the browser's local storage
    const token = StorageUtil.getUMATicket();
    if (token) {
      // check if user has a valid JWT token
      const jwt = this.getJWTContent(token);
      if (jwt) {
        // get the token expiration time and check if it is expired or not
        const expirationTime = jwt.exp * 1000;
        if (expirationTime && !isNaN(expirationTime)) {
          // current user is authorized as long as we still have time until the expiration time
          const isValid = expirationTime > new Date().getTime();
          // if the token is valid and no timeout is set for its expiration, handle its expiration
          if (isValid && !this.isTimeoutSetForTokenExpiration) {
            this.handleTokenExpiration(expirationTime);
          }
          return isValid;
        }
        // Token expired
        return false;
      }
      // Token is not valid
      return false;
    }
    // User does not have a token
    return false;
  }

  /**
   * Navigates user to the appropriate authentication server with specific security measures
   */
  authenticate() {
    // clear the tokens
    StorageUtil.removeUMATicket();
    StorageUtil.removeRefreshToken();
    // Create state & nonce
    // 'state' is there to protect the end user from cross site request forgery(CSRF) attacks.
    // 'nonce' binds the tokens with the client. It serves as a token validation parameter introduced in OpenID Connect specification
    const state = StringUtil.generateRandomString(10);
    const nonce = StringUtil.generateCryptographicallyRandomString();

    // save 'state' & 'nonce' to the session storage so that we can check if these values are altered (replay attack) after authenticating user
    StorageUtil.setState(state);
    StorageUtil.setNonce(nonce);

    // set security parameters
    const parameters = {};
    parameters[RestParameters.RESPONSE_TYPE] = environment.auth.response_type;
    parameters[RestParameters.CLIENT_ID] = environment.auth.clientId;
    parameters[RestParameters.REDIRECT_URI] = window.location.origin + '/' + this.realmId + '/' + environment.auth.redirect_endpoint;
    parameters[RestParameters.SCOPE] = environment.auth.scope;
    parameters[RestParameters.STATE] = state;
    parameters[RestParameters.NONCE] = StringUtil.hash53(nonce);

    // create url with parameters
    const urlParameters = RestUtil.createURLParameters(parameters);
    const url = `${environment.auth.base_url.replace(REALM_ID_PLACEHOLDER, this.keycloakRealmId)}/${environment.auth.authorization_endpoint}?${urlParameters}`

    // navigate user to the authentication server
    window.location.href = url;
  }

  /**
   * Logs the active user out and clears the tokens from local storage.
   * */
  public logout() {
    const url = `${environment.auth.base_url.replace(REALM_ID_PLACEHOLDER, this.keycloakRealmId)}/${environment.auth.logout_endpoint}`

    const body = new HttpParams()
      .set(RestParameters.CLIENT_ID, environment.auth.clientId)
      .set(RestParameters.REFRESH_TOKEN, StorageUtil.getRefreshToken());

    this.httpClient.post(url, body).subscribe(() => {
      this.authenticate();
    });
  }

  /**
   * Validates given JWT
   * @param token JWT to be validated.
   */
  validateJWT(token: string): boolean {
    try {
      jwt_decode(token, { header: true }) as any;
      return true;
    } catch (error) {
      // The existing token may have been altered; therefore, invalidated
      return false;
    }
  }

  /**
   * Returns the body part of a JWT token. This method validates the token first and then return the body part of this token.
   * If the token is not valid or a decoding error occurs a 'null' value is returned
   * @param token
   */
  getJWTContent(token: string) {
    if (this.validateJWT(token)) {
      let jwt;
      try {
        jwt = jwt_decode(token) as any;
        return jwt;
      } catch (error) {
        // The existing token may have been altered; therefore, invalidated
        console.log('JWT decode error:', error.message);
        return null;
      }
    }

    // token is not valid, return null
    return null;
  }

  /**
   * Retrieves the UMA token using the given access token. It stores uma token and refresh token in the local storage.
   * */
  getUMATicket(accessToken: any, realmId: string = null): Observable<any> {
    const url = `${environment.auth.base_url.replace(REALM_ID_PLACEHOLDER, realmId ? realmId : this.keycloakRealmId)}/${environment.auth.token_endpoint}`

    const body = new HttpParams()
      .set(RestParameters.GRANT_TYPE, KEYCLOAK_GRANT_TYPE.UMA_TOKEN)
      .set(RestParameters.AUDIENCE, environment.auth.uma_token_audience);

    const headers = new HttpHeaders()
      .set(Headers.AUTHORIZATION, `${BEARER_TOKEN_PREFIX} ${accessToken}`);

    return this.httpClient.post(url, body, {headers: headers})
      .pipe(map((response: any) => {
        // set tokens
        if(!realmId) {
          StorageUtil.setUMATicket(response.access_token);
          StorageUtil.setRefreshToken(response.refresh_token);

          // handle the token expiration
          const timeInMS = response.expires_in * 900; // multiple it with 900 to refresh token before its expiration
          this.handleTokenExpiration(new Date().getTime() + timeInMS); // pass the time when it will expire
        }

        return response;
      }), catchError(error => {
        // reauthenticate the user to get a valid bearer token
        if (error.status === 401 && error.error?.error_description === KEYCLOAK_ERROR_DESCRIPTION.INVALID_BEARER_TOKEN) {
          this.authenticate();
        }
        throw new Error('Error while getting UMA token');
      }));
  }

  /**
   * Refreshes the UMA token. Firstly, it calls the corresponding Keycloak service to refresh token and takes an access
   * token. Passing it to {@link getUMATicket} method, it refreshes UMA token.
   * */
  refreshUMATicket() {
    const url = `${environment.auth.base_url.replace(REALM_ID_PLACEHOLDER, this.keycloakRealmId)}/${environment.auth.token_endpoint}`

    const body = new HttpParams()
      .set(RestParameters.GRANT_TYPE, KEYCLOAK_GRANT_TYPE.REFRESH_TOKEN)
      .set(RestParameters.CLIENT_ID, environment.auth.clientId)
      .set(RestParameters.REFRESH_TOKEN, StorageUtil.getRefreshToken());

    this.httpClient.post(url, body).subscribe((response: any) => {
      this.getUMATicket(response.access_token).subscribe();
    }, (error) => {
      // when the user session is expired, reauthenticate the user
      if (error.status === 400 && error.error?.error_description === KEYCLOAK_ERROR_DESCRIPTION.SESSION_NOT_ACTIVE) {
        this.authenticate();
      }
    });
  }

  /**
   * Retrieves the password policy of given realm. If no realm is specified, password policy of the active realm
   * is retrieved.
   * @param realmId the realm id.
   * */
  public getRealmPasswordPolicy(realmId:string = null): Observable<PasswordPolicy[]> {
    const url = `${environment.auth.admin_url.replace(REALM_ID_PLACEHOLDER, realmId ? realmId : this.keycloakRealmId)}`

    return this.httpClient.get(url).pipe(map(response => {
      if (response["passwordPolicy"]) {
        const policies: string[] = response["passwordPolicy"].split(" and ");
        return policies.map(policy => new PasswordPolicy(policy));
      }
      return [];
    }))
  }

  /**
   * Calls {@link refreshUMATicket} method when the token is expired.
   *
   * @param expirationTime expiration time of token in ms
   * */
  private handleTokenExpiration(expirationTime) {
    const timeout = new Date(expirationTime).getTime() - new Date().getTime();
    // set the timeout to refresh token when it is expired.
    this.isTimeoutSetForTokenExpiration = true;
    setTimeout(() => {
      this.refreshUMATicket();
    }, timeout);
  }

  public getUmaToken(realmId: string, realmClient: string, realmSecret: string, username : string, password: string) : Observable<any>{
    const tokenEndpoint = `${environment.auth.base_url.replace('${REALM_ID}', realmId)}/${environment.auth.token_endpoint}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams()
      .set('client_id', realmClient)
      .set('client_secret', realmSecret)
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    // Make the POST request and handle the response
    return this.httpClient.post(tokenEndpoint, body.toString(), { headers })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error;
        })
      );
  }
}
