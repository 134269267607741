import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageWithMapComponent} from '../components/page-with-map.component';
import {MapComponent} from '../../../shared/components/map/map.component';
import {environment} from '../../../../environments/environment';
import {CreatePageDialogComponent} from '../../dialog/create-page-dialog/create-page-dialog.component';
import {AddPanelDialogComponent, AddPanelState} from '../../dialog/add-panel-dialog/add-panel-dialog.component';
import {take, takeUntil} from 'rxjs/operators';
import {AddLayerDialogComponent} from '../../dialog/add-layer-dialog/add-layer-dialog.component';
import {AddPageIconDialogComponent} from '../../dialog/add-page-icon-dialog/add-page-icon-dialog.component';
import {Page} from '../../../shared/models/page.model';
import {UserPreference} from '../../../shared/models/user-preference.model';
import {PageGroup} from '../../../shared/models/page-group.model';
import {ObjectUtil} from '../../../shared/utils/object-util';
import {UrukTemporalQuery} from '../../../shared/models/query/uruk-temporal-query.model';
import {
  GenericConfirmationDialogComponent
} from '../../dialog/confirmation-dialog/generic-confirmation-dialog.component';
import {Observable, of} from 'rxjs';
import {TagTypeUtil} from '../../../shared/enums/tag-type.enum';
import {GridsterItem} from 'angular-gridster2';
import {CONDITIONALLY_DRAGGABLE_GRIDSTER_OPTIONS} from '../../../shared/constants/configuration.constants';
import {EventService} from '../../../core/services/event.service';
import {GridLocationLayerIndex} from '../../../shared/models/visualization/grid-location.model';

@Component({
  selector: 'generic-template-page',
  templateUrl: './generic-template-page.component.html',
  styleUrls: ['./generic-template-page.component.scss']
})
export class GenericTemplatePageComponent extends PageWithMapComponent implements OnInit, OnDestroy {

  // Panel Locations
  PANEL_LOCATION_LEFT = 'left';
  PANEL_LOCATION_RIGHT = 'right';

  panelCount = 3; // number of panels in one of the side containers

  /**
   * Identifier for managing subscriptions
   */
  subscriptionId: string;

  // keeps the root menu item for active page
  public rootMenuItem: string = '';
  // keeps whether breadcrumb icons are visible
  public breadcrumbIconsVisible: boolean = false;
  //  keeps whether the current page is the default for user.
  public isDefaultPage: boolean;
  // whether notifications' tooltip should be displayed
  public displayNotifications: boolean = false;
  // keeps the original page in edit mode
  private originalPage: Page = null;

  // whether the page is in read-only view mode or edit mode
  displayMode: 'view' | 'edit' = 'view';

  shapeToolsetClass: string = 'filled';
  zoomButtonsClass: string = 'filled';

  // user preferences
  userPreferences: UserPreference;

  _cbrokerService = this.contextService;
  // gridster options
  options = CONDITIONALLY_DRAGGABLE_GRIDSTER_OPTIONS;
  // gridster dashboard with panels
  dashboard: Array<GridsterItem>;
  public gridLocationLayerIndex = GridLocationLayerIndex;
  @ViewChild('map') urukmap: MapComponent;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.menuService.setMenuVisibility(true);
    super.ngOnInit();

    // create the dashboard based on the page template
    this.pageTemplateService.getPageTemplate(this.page.templateType).subscribe(template => {
      this.dashboard = template.items
        .map(item => {
        return {id: item.id, cols: item.cols, rows: item.rows, y: item.y, x: item.x, layerIndex: item.layerIndex};
      });
    });

    this.updateBottomLayerElementClasses();

    // set the temporal query for the page
    if (this.page.defaultTemporalPeriod) {
      // discard 'P' at the beginning
      const period = this.page.defaultTemporalPeriod.substring(1);
      this.onTemporalQueryChanged(UrukTemporalQuery.createTemporalQueryForPeriod(period));
    } else {
      this.onTemporalQueryChanged(UrukTemporalQuery.today());
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  handleEventSubscriptions() {
    super.handleEventSubscriptions();

    // subscribe to user preferences updates
    this.userService.onUserPreferencesChanged()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(userPreferences => {
      // set user preferences
      this.userPreferences = userPreferences;
      // check whether it is a default page
      this.isDefaultPage = this.userPreferences.defaultPageId === this.page.id;
    });

    this.menuService.pathToSelectedMenuItem
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(pathToSelectedMenuItem => {
        if (pathToSelectedMenuItem?.length) {
          this.rootMenuItem = this.menuService.getRootMenuItem(pathToSelectedMenuItem[0]).title;
        }
      });

    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.MAP_INITIALIZED: {
            // resize the map when it is initialized. Otherwise, the map will not cover the Gridster item.
            this.urukmap.map.onMapAreaChanged();
          }
        }
      });
  }

  onTemporalQueryChanged(updatedQuery: UrukTemporalQuery): void {
    this.contextService.temporalContext.next(updatedQuery);
  }

  /********************** Page Functionalities **********************/

  /**
   * Opens {@link CreatePageDialogComponent} to allow users to create new pages
   * */
  openCreatePageDialog() {
    this.dialogService.open(CreatePageDialogComponent);
  }

  onPanelsVisibilityToggled(): void {
    super.onPanelsVisibilityToggled();
    this.updateBottomLayerElementClasses();
  }

  /**
   * Adjusts the visibility of the bottom layer elements based on the availability of the panels in the bottom-most locations and display mode of the page.
   * @private
   */
  private updateBottomLayerElementClasses(): void {
    // if panels are not visible remove the class so that the buttons move to the sides
    if (!this.panelsVisible) {
      this.shapeToolsetClass = '';
      this.zoomButtonsClass = '';
      return;
    }

    // if the page is in edit mode, side containers will always be filled with panels
    if (this.displayMode === 'edit') {
      this.shapeToolsetClass = 'filled';
      this.zoomButtonsClass = 'filled';
      return;
    }

    // check there is a panel in the last location
    this.shapeToolsetClass = Array.from(Array(this.panelCount).keys())
      .some(index => !this.getPanelAtLocation(this.PANEL_LOCATION_LEFT, index.toString())) ? '' : 'filled';

    this.zoomButtonsClass = Array.from(Array(this.panelCount).keys())
      .some(index => !this.getPanelAtLocation(this.PANEL_LOCATION_RIGHT, index.toString())) ? '' : 'filled';
  }

  /* Methods to handle edit mode */

  /**
   * Opens edit mode for page.
   * */
  public openEditMode() {
    // save the original page
    this.originalPage = ObjectUtil.deepCopy(Page, this.page);
    // panels should be visible in edit mode
    this.panelsVisible = true;
    // layers should be visible (i.e., selected) in edit mode
    this.layerControllers.forEach(layerController => layerController.setVisibility(true));
    // change display mode
    this.displayMode = 'edit';
    // update bottom layer element classes
    this.updateBottomLayerElementClasses();
  }

  /**
   * Closes edit mode and discards page updates
   * */
  public closeEditMode() {
    // discard page updates
    this.page = this.originalPage;
    // map panels since they might be deleted
    this.mapPanels();
    // update panel data
    this.updatePanelData();
    // initialize layers
    this.initializeLayers();
    // change display mode to view
    this.displayMode = 'view';
    // update bottom layer element classes
    this.updateBottomLayerElementClasses();
  }

  /**
  * Opens a dialog {@link AddLayerDialogComponent} to add new layers to page.
  * */
  public openAddLayerDialog() {
    // open dialog
    const dialogRef = this.dialogService.open(AddLayerDialogComponent, {
      context: {
        layerIds: this.page.layerIds,
        domains: this.page.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag))
      }
    });

    // retrieve the selected layer ids on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(layerIds => {
        if (layerIds) {
          // set layer ids
          this.page.layerIds = layerIds;
          // set layer controllers
          this.initializeLayers();
        }
      });
  }

  /**
   * Opens a dialog {@link AddPageIconDialogComponent} to select an icon for page.
   * */
  public openIconSelectionDialog() {
    // open dialog
    const dialogRef = this.dialogService.open(AddPageIconDialogComponent, {
      context: {
        selectedIcon: this.page.icon
      }
    });

    // retrieve the selected icon on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(icon => {
        if (icon) {
          // set page icon
          this.page.icon = icon;
        }
      });
  }

  /**
   * Deletes the current page if the operation is confirmed via {@link GenericConfirmationDialogComponent}.
   * */
  public deletePage() {
    // open the dialog
    const dialogRef = this.dialogService.open(GenericConfirmationDialogComponent, {
      context: {
        title: 'Delete Page',
        body: this.translateService.instant('Are you sure you want to delete page ?', {name: this.page.title})
      }
    });

    // retrieve the created panel on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          let observable: Observable<any> = of({});
          // delete the page if it is a personal page of a standard user
          if (this.page.owner) {
            observable = this.pageService.deletePage(this.page.id);
          }
          // remove the page from user preference
          observable.pipe(
            take(1)
          ).subscribe(() => {
            // copy user preference
            const userPreference = ObjectUtil.deepCopy(UserPreference,this.userPreferences);
            // page is included in page id list of user preference
            if (userPreference.pageIds.includes(this.page.id)) {
              // find index
              const index = userPreference.pageIds.findIndex(pageId => pageId === this.page.id);
              // remove it from array
              userPreference.pageIds.splice(index, 1);
            }
            // page belongs to a page group
            else {
              // find page group
              const pageGroup: PageGroup = userPreference.pageGroups.find(group => group.pageIds.includes(this.page.id));
              // find index
              const index = pageGroup.pageIds.findIndex(pageId => pageId === this.page.id);
              // remove it from page group
              pageGroup.pageIds.splice(index, 1);
            }

            // mark another page as default when the current one is deleted
            if (this.isDefaultPage) {
              let newDefaultPageId = userPreference.pageIds.length ? userPreference.pageIds[0] : '';
              // get the default page from page groups
              if (!newDefaultPageId && userPreference.pageGroups.length) {
                const pageGroup: PageGroup = userPreference.pageGroups.find(group => group.pageIds.length);
                if (pageGroup) {
                  newDefaultPageId = pageGroup.pageIds[0];
                }
              }
              userPreference.defaultPageId = newDefaultPageId;
            }

            // update user preference
            this.userPreferenceService.updateUserPreference(this.userService.userId.getValue(), userPreference).subscribe(() => {
              // navigate user to the default page if exists
              if (userPreference.defaultPageId) {
                this.router.navigate([`/${environment.routes.pages}/${userPreference.defaultPageId}`]);
              } else {
                this.eventService.broadcastNavigateToNoPageTemplateEvent();
              }
            }, error => console.error(error));
          });
        }
      });
  }

  /**
   * Saves the page updates.
   * */
  public save() {
    // update page
    this.pageService.updatePage(this.page).subscribe(updatedPage => {
      // set page
      this.page = updatedPage;
      // change display mode to view
      this.displayMode = 'view';
      // update bottom layer element classes
      this.updateBottomLayerElementClasses();
    }, error => {
      console.error(error);
    });
  }

  /**
   * Returns the panel at given location at given index
   * @param item gridster item representing the panel
   * @param index index of the panel at the given location
   */
  protected getPanelAtLocation(item: any, index: string) {
    return this.panels[item.id];
  }

  /**
   * Opens a dialog {@link AddPanelDialogComponent} to add panel to specified location.
   * @param item gridster item representing the panel
   * @param index index of panel in specified location
   * @return panel location
   * */
  protected addPanelToLocation(item: any, index: string): string {
    // get panel location
    const panelLocation = item.id;

    // open the dialog
    const dialogRef = this.dialogService.open(AddPanelDialogComponent, {
      context: {
        panelLocation: panelLocation
      }
    });

    // retrieve the created panel on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(panel => {
        if (panel) {
          // add panel to the page
          this.page.panels.push(panel);
          // update panels map with new panel
          this.panels[panelLocation] = panel;
        }
      });

    return panelLocation;
  }

  clearLayerExecutionsOnDestroy(): void {
    // do nothing here as the layer KPI executions are cleared when the LayerThumbnail components are destroyed
  }

  /**
   * Broadcast the resize event of the panel at the given location.
   * @param id the identifier of panel location
   * */
  public onPanelResized(id) {
    this.eventService.broadcastPanelResizedEvent(id, this.page.id);
  }

  /**
   * Resize the map when corresponding Gridster item is resized.
   */
  public onMapResized() {
    this.urukmap.map.onMapAreaChanged();
  }
}
