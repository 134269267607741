import {AfterViewInit, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {TimeUtil} from '../../../../../shared/utils/time-util';
import {MvbCommunication} from '../../../../../shared/models/mvbCommunication.model';
import {CanCommunication} from '../../../../../shared/models/canCommunication.model';
import {NGSIEventType, Notification} from '../../../../../shared/models/notification.model';
import {TrainNotification} from '../../../../../shared/models/trainNotification.model';
import {TrainDefinition} from '../../../../../shared/models/trainDefinition.model';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';

const ASELSAN_TRAIN_SOURCE_URL = 'http://aselsan.com.tr';

@Component({
  selector: 'train-dialog-template',
  templateUrl: './train-dialog-template.component.html',
  styleUrls: ['./train-dialog-template.component.scss']
})
export class TrainDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

  // Context variables
  entity: any;
  positionEntity: any;
  hmiVehicleNo: any;
  location: any;
  fDriveApplied: any[] = [];
  pressBp: any;
  pressMr: any;
  vAscReq: any;
  voltPantoSka1: any[] = [];
  hmiJourneyNo: any;
  dotSeparatedDate: string;
  date: any;
  trainId: any;
  isAselsanTrain = undefined;
  trainRef: any;
  status: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
  speed: any;
  distanceTraveled: any[] = [];
  ascLimit: any;
  tractionBar: any;
  tractionCutOffLed: any;
  majorUnderVoltageError: any; // power voltage 1
  mediumVoltageBackupEnabled2Vehicles: any;
  catenaryVoltage: any; // power voltage 1
  pantoABar: any; // power voltage 1
  pTrain: any; // power voltage 1
  vcbCloseSka1: any; // power voltage 1
  vcbCloseSka2: any; // power voltage 1
  pressurePs1SafeguardAddSka1: any; // power voltage 1
  pressurePs1SafeguardAddSka2: any; // power voltage 1
  majorMvError: any; // power voltage 1
  hvDisconnectorCloseSKA1: any; // power voltage 1
  hvDisconnectorCloseSKA2: any; // power voltage 1
  hvDisconnectorCloseOA2: any; // power voltage 1
  tractionTransformerOilFlow: any [] = []; // power voltage 2
  tractionTransformerOilLevel: any [] = []; // power voltage 2
  tractionTransformerOverPressure: any [] = []; // power voltage 2
  tractionTransformerTemp: any[] = []; // power voltage 2
  bp: any; // brake 1
  mrp: any; // brake 1
  pressureGovernorPBReleased: any; // brake 1
  pressureGovernorDetectionEmergencyBrake: any; // brake 1
  pressureGovernorIndirectBrakeControl: any; // brake 1
  diagBrDirect: any; // brake 1
  diagBrDirectList: any[] = []; // brake 2
  majorBrPark: any; // brake 1
  majorBrParkList: any[] = []; // brake 2
  edBar: any[] = []; // traction 3
  emergencyStatusBrakeLed: any[] = [];
  driverAirConditioningTemperature: any; // air condition 1
  passengerAirConditioningMode: any; // air condition 1
  externalTemperature: any;
  door1LevelFault: any; // door 1
  door2LevelFault: any; // door 1
  doorLoopOkLed: any; // door 1
  slDoorControlByPass: any; // door 1
  hasError: boolean = false;
  isError: boolean = false;
  hasProducedError: boolean = false;
  activeTab: string = '';
  errorMap: string[] = ['noError', 'error'];
  errorMap1: string[] = ['error', 'noError'];
  diagComp1: any;
  diagComp2: any;
  kismiPnomatikFrenKaybi: any;
  erisilebilirKompresorYok: any;
  enAz1EbcuHatali: any;
  mrDusuk: any;
  frenUygulamaHatasi: any[] = []; // brake 2
  frenBirakmaHatasi: any;
  diagBrCPress: any[] = []; // brake 2
  havaSuspansiyonHatas: any;
  majorBrWsp: any[] = []; // brake 2
  ebcu: any[] = []; // brake 2
  kumlamaHatasi: any;
  parkFreniHatasi: any[] = []; // brake 2
  birdenFazlaVakumDevrede: any; // power voltage
  tumPanolarIzole: any; // power voltage 1
  birdenFazlaPantoKaldirilamaz: any;
  pantografDustu: any; // power voltage 1
  yuksekAkimKaynakliVakumHatasi: any; // power voltage 1
  diagPantoComp: any; // power voltage 1
  hvConfigError: any; // power voltage 1
  ortaGerilimKontaktorKontrolHatasi: any;
  ortaGerilimHatasiKabinDegisikligi: any;
  cutOffDongusuBaypasEdilmistir: any;
  siviSogutmaBakim: any;
  siviSogutmaAcilBakim: any;
  minorAriza: any;
  majorAriza: any;
  agirAriza: any;
  ickapi1SeviyeAriza: any; // door 1
  ickapi2SeviyeAriza: any;
  kapi1SeviyeAriza: any; // door 1
  kapi2SeviyeAriza: any;
  prmAsansorKapi1Devrede: any; // door 1
  prmAsansorKapi2Devrede: any;
  driverMajorHvac: any[] = []; // air condition 2
  passengerMajorHvac1: any[] = []; // air condition 3
  passengerMajorHvac2: any[] = []; // air condition 4
  driverMinorHvac: any[] = []; // air condition 2
  passengerMinorHvac1: any[] = []; // air condition 3
  passengerMinorHvac2: any[] = []; // air condition 4
  hvacsInhibitionList: any[] = []; // air condition 1
  tkysHaberlesmeHatasi: any;
  tkysFrenHaberlesmeHatasi: any;
  tkysCerHaberlesmeHatasi: any;
  wtbHaberlesmeHatasi: any;
  predictiveMaintenanceCode: any;
  predictiveMaintenanceInfo: any;
  mvbComList: MvbCommunication[] = [];
  camComList: CanCommunication[] = [];
  trainNotificationList: TrainNotification[] = [];
  tempTrcInv1: any[] = []; // traction 2
  tempTrcInv2: any[] = []; // traction 2
  tempTrcRec: any[] = []; // traction 2
  tempApsRec: any[] = []; // power voltage 3
  tempApsInv: any[] = []; // power voltage 3
  tempMotorAx3: any[] = []; // traction 3
  tempMotorAx4: any[] = []; // traction 3
  tractionDcLinkOk: any[] = [];
  vTrcDclink: any[] = []; // traction 2
  ApsDcLinkOk: any[] = [];
  vApsDclink: any[] = []; // power voltage 3
  measuringBatteryVoltage: any[] = []; // power voltage 4
  tempBattery: any[] = []; // power voltage 4
  aBatteryCharger: any[] = []; // power voltage 4
  forceAvmaxAx3: any[] = []; // traction 3
  forceAvmaxAx4: any[] = []; // traction 3
  velMotorAx3: any[] = []; // traction 3
  velMotorAx4: any[] = []; // traction 3
  lcuMaintenance1: any[] = [];
  requiredTractionForce: any; // traction 1
  appliedTractionForce: any; // traction 1
  fDriveForceAx3: any; // traction 1
  fDriveForceAx4: any; // traction 1
  forceAppliedAx3: any; // traction 1
  forceAppliedAx4: any; // traction 1
  cutOffTraction: any; // traction 1
  cutOffByPass: any; // traction 1
  tractionNull: any; // traction 1
  etcsIsolationByPass: any; // signalization 1
  ertmsCutOff: any; // signalization 1
  atsByPass: any; // signalization 1
  ertmsServiceBrake: any; // signalization 1
  interiorLightingModeList: any[] = [0, 0, 0, 0, 0]; // other system
  contactorHalfLightList: any[] = []; // other system
  contactorEmergencyLightList: any[] = []; // other system
  toiletIsNotActive: any; // other 1
  toiletWaterLevelLow: any; // other 1
  toiletBowlFull: any; // other 1
  toiletWastewaterTankFull: any; // other 1
  toiletSosSignal: any; // other 1
  notificationCount: number = 1;
  isLiveMode: number = 0;
  firstTrainData: TrainDefinition;
  liveTrainData: TrainDefinition;
  tractionSystem: any = false;
  brakeSystem: any = false;
  powerVoltageSystem: any = false;
  airConditionSystem: any = false;
  doorSystem: any = false;
  otherSystem: any = false;
  communicationSystem: any = false;
  predictiveMaintenance: any = false;
  signalizationSystem: any = false;
  currentSVGPath: string | null = null;
  previousSVGPath: string | null = null;
  defaultSVGPath: string = '';
  selectedWagonIndex: number = 1;
  tractionTabInfo: number = 1;
  brakeTabInfo: number = 1;
  powerVoltageTabInfo: number = 1;
  airConditionTabInfo: number = 1;
  doorTabInfo: number = 1;
  otherTabInfo: number = 1;
  motorCooling2mvcbProtection: any;
  communicationTabInfo: number = 1;
  signalizationTabInfo: number = 1;
  innerDoorError: any = []; // door
  outerDoorStatus: any = []; // door
  doorFaultPriorityAActive: any = []; // door
  doorFaultPriorityBActive: any = []; // door
  doorFaultPriorityCActive: any = []; // door
  stepFaultPriorityAActive: any = []; // door
  stepFaultPriorityBActive: any = []; // door
  stepFaultPriorityCActive: any = []; // door
  doorInsulationList: any = []; // door
  redColor = '#FF6B6B';
  greenColor = '#31C048';
  blueColor = '#4D96FF';
  orangeColor = '#FFA556';
  whiteColor = '#FFFFFF';
  pinkColor = '#FF8FB1';
  yellowColor = '#FFD93D';
  grayColor = '#B0B0B0';
  trainStatus: any;
  shouldUnsubscribe: boolean = false;
  prognosticSystem: any = false;
  diagnosticSystem: any = false;

  boolMap = new Map<number, string>();

  // Define SVG file paths
  svgPaths = {
    'tractionSystem': 'assets/images/train-traction.svg',
    'brakeSystem': 'assets/images/train-brake.svg',
    'powerVoltageSystem': 'assets/images/train-powervoltage.svg',
    'airConditionSystem': 'assets/images/train-aircondition.svg',
    'doorSystem': 'assets/images/train-door.svg',
    'otherSystem': 'assets/images/train-other.svg',
    'communicationSystem': 'assets/images/train-communication.svg',
    'predictiveMaintenance': 'assets/images/predictive-maintenance.svg',
    'signalizationSystem': 'assets/images/train-signalization.svg',
  };

  wagonIndex = {
    'ska1': 0,
    'oa1': 1,
    'oa2': 2,
    'oa3': 3,
    'ska2': 4
  };

  systemLabels = {
    tractionSystem: [
      {color: this.greenColor, label: 'noError'},
      {color: this.redColor, label: 'error'}
    ],
    brakeSystem: [
      {color: this.grayColor, label: 'ePBrakeFree'},
      {color: this.greenColor, label: 'ePBrakeEngaged'},
      {color: this.redColor, label: 'ePBrakeError'}
    ],
    powerVoltageSystem: [
      {color: this.grayColor, label: 'passive'},
      {color: this.greenColor, label: 'active'},
      {color: this.orangeColor, label: 'minorError'},
      {color: this.redColor, label: 'majorError'}
    ],
    airConditionSystem: [
      {color: this.grayColor, label: 'passive'},
      {color: this.greenColor, label: 'active'},
      {color: this.orangeColor, label: 'minorError'},
      {color: this.redColor, label: 'majorError'},
      {color: this.pinkColor, label: 'communicationError'}
    ],
    doorSystem: [
      {color: this.grayColor, label: 'doorClosed'},
      {color: this.yellowColor, label: 'doorIsolated'},
      {color: this.greenColor, label: 'doorOpen'},
      {color: this.blueColor, label: 'doorActive'},
      {color: this.orangeColor, label: 'minorError'},
      {color: this.redColor, label: 'majorError'},
      {color: this.pinkColor, label: 'communicationError'}
    ],
    otherSystem: [
      {color: this.greenColor, label: 'noError'},
      {color: this.redColor, label: 'error'}
    ],
    communicationSystem: [
      {color: this.greenColor, label: 'noError'},
      {color: this.redColor, label: 'majorError'}
    ],
    predictiveMaintenance: [
      {color: this.greenColor, label: 'noError'},
      {color: this.redColor, label: 'error'}
    ],
    signalizationSystem: [
      {color: this.greenColor, label: 'noError'},
      {color: this.redColor, label: 'error'}
    ]
  };

  constructor(injector: Injector, private translate: TranslateService) {
    super(injector);

    this.activeTab = 'tractionSystem'; // Default active tab value
    this.defaultSVGPath = 'assets/images/train-traction.svg'; // Default svg path
    this.translate.get(['noError', 'error']).subscribe(translations => {
      // Adding translations to the map
      this.boolMap.set(0, translations['noError']);
      this.boolMap.set(1, translations['error']);
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeWebsocket();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.shouldUnsubscribe = true;
  }

  getBackIconStyle() {
    const paths = [
      'assets/images/inner-door-unit.svg',
      'assets/images/outer-door1-unit.svg',
      'assets/images/outer-door2-unit.svg'
    ];

    // If currentSVGPath is one of the specified paths, the color will be 'black', otherwise the default style
    return paths.includes(this.currentSVGPath) ? { fontsize: '16px', color: '#808080', fontWeight: 'bold', backgroundColor: 'white' } : {};
  }

  public onTabChange(event) {
    // Reset the tab information to default values when a tab change event occurs
    this.setDefaultTabInfo();

    // Set the activeTab variable to the ID of the selected tab
    this.activeTab = event.tabId;

    // Load the corresponding SVG and modify it based on the selected tab
    this.loadAndModifySVG(this.getSvgPath(), true);
  }

  public onMainTabChange(event) {
    this.setDefaultTabInfo();
    if (event.tabId === 'diagnosticSystem') {
      this.activeTab = 'tractionSystem';
    } else {
      this.activeTab = 'predictiveMaintenance';
    }

    // Load the corresponding SVG and modify it based on the selected tab
    this.loadAndModifySVG(this.getSvgPath(), true);
  }

  private setDefaultTabInfo() {
    // Set default values for all system information related to the tabs
    this.tractionTabInfo = 1;
    this.brakeTabInfo = 1;
    this.powerVoltageTabInfo = 1;
    this.airConditionTabInfo = 1;
    this.doorTabInfo = 1;
    this.otherTabInfo = 1;
    this.communicationTabInfo = 1;
    this.predictiveMaintenance = 1;
    this.signalizationTabInfo = 1;
  }

  private getSvgPath(): string {
    // Return the SVG path corresponding to the active tab, or the default path if none is found
    return this.svgPaths[this.activeTab] || this.defaultSVGPath;
  }

  private getWagonId(element: SVGGraphicsElement): string {
    // Find the parent 'g' element of the clicked SVG element and return its ID
    const parentG = element.parentElement?.closest('g');
    if (parentG && parentG.id) {
      return parentG.id;
    } else {
      console.log('No ID or no parent g element found');
    }
  }

  private loadAndModifySVG(svgPath: string, resetFlags: boolean = false) {

    if (this.currentSVGPath !== svgPath) {
      // Store the previous SVG path and load the new one
      this.previousSVGPath = this.currentSVGPath;
      this.currentSVGPath = svgPath;
    }

    // Optionally reset the previousSVGPath if resetFlags is true
    if (resetFlags) {
      this.previousSVGPath = null;
    }

    // Fetch the SVG file from the given path
    fetch(svgPath)
      .then(response => response.text())
      .then(data => this.handleSVGData(data))
      .catch(error => console.error('An error occurred while loading the SVG: ', error));
  }

  private handleSVGData(data: string) {
    // Get the container to load the SVG and the back icon element
    const svgContainer = document.getElementById('svgContainer');
    const backIcon = document.querySelector('.back-icon') as HTMLElement;

    // If the container is found, load the SVG and modify it
    if (svgContainer) {
      svgContainer.innerHTML = data;
      this.updateBackIconVisibility(backIcon); // Show or hide the back icon
      this.modifyGroups(svgContainer);        // Modify 'g' elements in the SVG
      this.modifyPaths(svgContainer);         // Modify 'path' elements in the SVG
      this.modifyRects(svgContainer);         // Modify 'rect' elements in the SVG
      this.modifyTexts(svgContainer);         // Modify 'text' elements in the SVG
    }
  }

  // Show the back icon if there is a previous SVG loaded
  private updateBackIconVisibility(backIcon: HTMLElement) {
    if (this.previousSVGPath) {
      backIcon.style.display = 'block'; // Make the back icon visible
    } else {
      backIcon.style.display = 'none'; // Hide the back icon initially
    }
  }

  public goBack() {
    // Go back to the previous SVG if it exists, and reset tab information
    if (this.previousSVGPath) {
      this.setDefaultTabInfo();

      this.currentSVGPath = null;
      this.loadAndModifySVG(this.previousSVGPath);
    }
  }

  private modifyGroups(svgContainer: HTMLElement) {
    // Get all 'g' elements inside the SVG container
    const groups = svgContainer.querySelectorAll('g');

    // Iterate through the groups and modify each one that has the class 'unit'
    groups.forEach(group => {
      if (group.classList.contains('unit')) {
        this.handleModifyGroups(group);  // Handle group-specific modifications

        group.style.cursor = 'pointer';  // Set the cursor to pointer for clickable groups
        group.addEventListener('click', () => {
          const selectedPath: string = this.handleGroupsClick(group);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }

        });
      }
    });
  }

  private handleModifyGroups(g: SVGGElement) {
    // Define handlers for modifying groups based on the active tab
    const tabHandlers: { [key: string]: (g: SVGGElement) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the group
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(g);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (modify group): ${this.activeTab}`);
    return '';
  }

  private handleGroupsClick(g: SVGGElement): string {
    // Define handlers for group click events based on the active tab
    const tabHandlers: { [key: string]: (id: string) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked group
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(g)] | 0;
      return handler(g.id);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (click group): ${this.activeTab}`);
    return '';
  }

  private modifyPaths(svgContainer: HTMLElement) {
    // Get all 'path' elements inside the SVG container
    const paths = svgContainer.querySelectorAll('path');

    // Iterate through the paths and modify each one that has the class 'unit'
    paths.forEach(path => {
      if (path.classList.contains('unit')) {
        this.handleModifyPaths(path);  // Handle path-specific modifications

        path.style.cursor = 'pointer';  // Set the cursor to pointer for clickable paths
        path.addEventListener('click', () => {
          const selectedPath: string = this.handlePathsClick(path);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }
        });
      }
    });
  }

  private handleModifyPaths(path: SVGPathElement) {
    // Define handlers for modifying paths based on the active tab
    const tabHandlers: { [key: string]: (path: SVGPathElement) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the path
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(path);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (modify path): ${this.activeTab}`);
    return '';
  }

  private handlePathsClick(path: SVGPathElement): string {
    const tabHandlers: { [key: string]: (id: string) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked path
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(path)] | 0;
      return handler(path.id);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (click path): ${this.activeTab}`);
    return '';
  }

  private modifyRects(svgContainer: HTMLElement) {
    // Get all 'rect' elements inside the SVG container
    const rects = svgContainer.querySelectorAll('rect');

    // Iterate through the rects and modify each one that has the class 'unit'
    rects.forEach(rect => {
      if (rect.classList.contains('unit')) {
        this.handleModifyRects(rect);  // Handle rect-specific modifications

        rect.style.cursor = 'pointer';  // Set the cursor to pointer for clickable rects
        rect.addEventListener('click', () => {
          const selectedPath: string = this.handleRectsClick(rect);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }
        });
      }
    });
  }

  private handleModifyRects(rect: SVGRectElement) {
    // Define handlers for modifying rects based on the active tab
    const tabHandlers: { [key: string]: (rect: SVGRectElement) => string } = {
      tractionSystem: this.modifyRectsForTractionSystem.bind(this),
      brakeSystem: this.modifyRectsForBrakeSystem.bind(this),
      powerVoltageSystem: this.modifyRectsForPowerVoltageSystem.bind(this),
      airConditionSystem: this.modifyRectsForAirConditionSystem.bind(this),
      doorSystem: this.modifyRectsForDoorSystem.bind(this),
      communicationSystem: this.modifyRectsForCommunicationSystem.bind(this)
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the rect
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(rect);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (modify rect): ${this.activeTab}`);
    return '';
  }

  private modifyRectsForTractionSystem(rect: SVGRectElement) {
    // Get the wagon index for the clicked rect
    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('cer')) {
      if (this.status[9] !== '') {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.status[6] !== '') {
        rect.setAttribute('fill', this.redColor);
      }

    } else if (rect.id.includes('bogi')) {
      if (this.status[5] !== '') {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.status[10] !== '') {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.status[7] !== '') {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.status[8] !== '') {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForBrakeSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('brake')) {

      if (this.majorBrWsp[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.ebcu[index] === 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.majorBrParkList[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.diagBrDirectList[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.diagBrCPress[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForPowerVoltageSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('transformer')) {
      if (this.tractionTransformerTemp[index] >= 55 && this.tractionTransformerTemp[index] <= 110) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.tractionTransformerTemp[index] > 110) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOilFlow[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOilLevel[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOverPressure[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } if (rect.id.includes('battery')) {
      if (this.measuringBatteryVoltage[index] >= 80 && this.measuringBatteryVoltage[index] <= 90) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.measuringBatteryVoltage[index] < 80) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tempBattery[index] > 40 || this.measuringBatteryVoltage[index] < -10) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  /*
  if (rect.id.includes('apu')) {
      const parentG = rect.parentElement?.closest('g');

      // Get all 'path' elements within the group and apply modifications
      const pathsInGroup = parentG.querySelectorAll('path');
      pathsInGroup.forEach(path => {
        if (rect.classList.contains('apu')) {
          this.handleModifyPaths(path);  // Handle individual path modifications
        }
      });
    }
   */

  private modifyRectsForAirConditionSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('driver-aircondition')) {

      if (this.driverMinorHvac[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.driverMajorHvac[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('passenger-aircondition1')) {
      if (this.passengerMinorHvac1[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.passengerMajorHvac1[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('passenger-aircondition2')) {
      if (this.passengerMinorHvac2[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.passengerMajorHvac2[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForDoorSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('inner')) {
      if (this.innerDoorError[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('outer')) {
      if (this.outerDoorStatus[index] === 0) {
        rect.setAttribute('fill', this.grayColor);
      } else if (this.outerDoorStatus[index] === 1) {
        rect.setAttribute('fill', this.blueColor);
      } else if (this.outerDoorStatus[index] === 2) {
        rect.setAttribute('fill', this.greenColor);
      } else {
        rect.setAttribute('fill', this.greenColor);
      }

      if (this.doorFaultPriorityBActive[index] === 1 || this.stepFaultPriorityBActive[index] === 1 ||
        this.doorFaultPriorityCActive[index] === 1 || this.stepFaultPriorityCActive[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.doorFaultPriorityAActive[index] === 1 || this.stepFaultPriorityAActive[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.doorInsulationList[index] === 1) {
        rect.setAttribute('fill', this.yellowColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForCommunicationSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('driver-communication')) {
    } else if (rect.id.includes('passenger-communication')) {
    } else {
      // default ekleme yapılacak
    }
  }

  private handleRectsClick(rect: SVGRectElement): string {
    // Define handlers for rect click events based on the active tab
    const tabHandlers: { [key: string]: (id: string) => string } = {
      tractionSystem: this.clickRectsForTractionSystem.bind(this),
      brakeSystem: this.clickRectsForBrakeSystem.bind(this),
      powerVoltageSystem: this.clickRectsForPowerVoltageSystem.bind(this),
      airConditionSystem: this.clickRectsForAirConditionSystem.bind(this),
      doorSystem: this.clickRectsForDoorSystem.bind(this),
      communicationSystem: this.clickRectsForCommunicationSystem.bind(this),
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked rect
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(rect)] | 0;
      return handler(rect.id);
    }

    // Warn if no handler is found for the active tab
    // console.warn(`No handler found for activeTab (click rect): ${this.activeTab}`);
    return '';
  }

  private clickRectsForTractionSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('cer')) {
      this.tractionTabInfo = 2;
      selectedPath = 'assets/images/cer-unit.svg';
    } else if (id.includes('bogi')) {
      this.tractionTabInfo = 3;
      selectedPath = 'assets/images/motor-bogi-unit.svg';
    } else {
      this.tractionTabInfo = 1;
      selectedPath = 'assets/images/train-traction.svg';
    }

    return selectedPath;
  }

  private clickRectsForBrakeSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('brake1')) {
      this.brakeTabInfo = 2;
      selectedPath = 'assets/images/brake-unit.svg';
    } else if (id.includes('brake2')) {
      this.brakeTabInfo = 3;
      selectedPath = 'assets/images/motor-bogi-unit.svg';
    } else {
      this.brakeTabInfo = 1;
      selectedPath = 'assets/images/train-traction.svg';
    }

    return selectedPath;
  }

  private clickRectsForPowerVoltageSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('transformer')) {
      this.powerVoltageTabInfo = 2;
      selectedPath = 'assets/images/transformer-unit.svg';
    } else if (id.includes('apu')) {
      this.powerVoltageTabInfo = 3;
      selectedPath = 'assets/images/apu-unit.svg';
    } else if (id.includes('battery')) {
      this.powerVoltageTabInfo = 4;
      selectedPath = 'assets/images/battery-unit.svg';
    } else {
      this.powerVoltageTabInfo = 1;
      selectedPath = 'assets/images/train-powervoltage.svg';
    }

    return selectedPath;
  }

  private clickRectsForAirConditionSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('ska1-driver-aircondition')) {
      this.airConditionTabInfo = 2;
      selectedPath = 'assets/images/driver-aircondition-ska1-unit.svg';
    } else if (id.includes('ska2-driver-aircondition')) {
      this.airConditionTabInfo = 2;
      selectedPath = 'assets/images/driver-aircondition-ska2-unit.svg';
    } else if (id.includes('ska1-passenger-aircondition1')) {
      this.airConditionTabInfo = 3;
      selectedPath = 'assets/images/passenger-ska1-aircondition1-unit.svg';
    } else if (id.includes('ska1-passenger-aircondition2')) {
      this.airConditionTabInfo = 4;
      selectedPath = 'assets/images/passenger-ska1-aircondition2-unit.svg';
    } else if (id.includes('ska2-passenger-aircondition1')) {
      this.airConditionTabInfo = 3;
      selectedPath = 'assets/images/passenger-ska2-aircondition1-unit.svg';
    } else if (id.includes('ska2-passenger-aircondition2')) {
      this.airConditionTabInfo = 4;
      selectedPath = 'assets/images/passenger-ska2-aircondition2-unit.svg';
    } else if (id.includes('oa') && id.includes('passenger-aircondition1')) {
      this.airConditionTabInfo = 3;
      selectedPath = 'assets/images/passenger-oa-aircondition1-unit.svg';
    } else if (id.includes('oa') && id.includes('passenger-aircondition2')) {
      this.airConditionTabInfo = 4;
      selectedPath = 'assets/images/passenger-oa-aircondition2-unit.svg';
    } else {
      this.airConditionTabInfo = 1;
      selectedPath = 'assets/images/train-aircondition.svg';
    }

    return selectedPath;
  }

  private clickRectsForDoorSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('inner')) {
      // this.doorTabInfo = 2;
      selectedPath = 'assets/images/inner-door-unit.svg';
    } else if (id.includes('outer')) {
      // this.doorTabInfo = 3;
      if (id.includes('door1') || id.includes('door4')) {
        selectedPath = 'assets/images/outer-door1-unit.svg';
      } else {
        selectedPath = 'assets/images/outer-door2-unit.svg';
      }
    } else {
      this.doorTabInfo = 1;
      selectedPath = 'assets/images/train-door.svg';
    }

    return selectedPath;
  }

  private clickRectsForCommunicationSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('driver-communication')) {
      // this.communicationTabInfo = 2;
      selectedPath = 'assets/images/communication-driver-unit.svg';
    } else if (id.includes('ska')) {
      // this.communicationTabInfo = 3;
      selectedPath = 'assets/images/communication-ska-unit.svg';
    } else if (id.includes('oa1') || id.includes('oa2')) {
      // this.communicationTabInfo = 4;
      selectedPath = 'assets/images/communication-oa-unit.svg';
    } else if (id.includes('oa3')) {
      // this.communicationTabInfo = 5;
      selectedPath = 'assets/images/communication-oa3-unit.svg';
    } else {
      this.communicationTabInfo = 1;
      selectedPath = 'assets/images/train-communication.svg';
    }

    return selectedPath;
  }

  // Main function to modify texts based on the active tab
  private modifyTexts(svgContainer: HTMLElement): string {
    // Define handlers for each tab
    const tabHandlers: { [key: string]: (svgContainer: HTMLElement) => void } = {
      brakeSystem: this.modifyTextForBrakeSystem.bind(this),
      airConditionSystem: this.modifyTextForAirConditionSystem.bind(this),
      // Other tab handlers can be added here
    };

    // Call the handler for the active tab, if it exists
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      handler(svgContainer);
    } else {
      // console.warn(`No handler found for activeTab (modify text): ${this.activeTab}`);
    }

    return '';
  }

  // Function to create and append a text element near an SVG element
  private appendTextElement(
    parentElement: Element,
    textContent: string,
    bbox: DOMRect,
    offsetX: number = 10,
    offsetY: number = 0,
    color: string
  ): void {
    const textElem = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    // Positioning the text element next to the parent element (e.g., 'g' or 'path')
    const x = bbox.x + bbox.width + offsetX; // Place to the right of the element
    const y = bbox.y + bbox.height + offsetY; // Vertically center align

    textElem.setAttribute('x', x.toString());
    textElem.setAttribute('y', y.toString());
    textElem.setAttribute('fill', color);
    textElem.setAttribute('font-size', '16');
    textElem.setAttribute('font-family', 'Arial');
    // textElem.classList.add('label-text');
    textElem.textContent = textContent;

    parentElement.after(textElem);
  }

  // Handler for the brake system tab
  private modifyTextForBrakeSystem(svgContainer: HTMLElement): void {
    let status: boolean = false;
    let color: string = '';
    let value: number = 0;
    const groups = svgContainer.querySelectorAll('g');

    groups.forEach((g) => {
      if (g.classList.contains('text-pointer')) {

        const index = this.wagonIndex[this.getWagonId(g)] | 0;
        status = (this.majorBrWsp[index] === 1 || this.ebcu[index] === 0 || this.majorBrParkList[index] === 1 || this.diagBrDirectList[index] === 1 || this.diagBrCPress[index] === 1);

        if (status) {
          color = this.redColor;
          value = this.getRandomInt(0, 5, 13, 20, this.isError);
        } else {
          color = this.greenColor;
          value = this.getRandomInt(6, 12);
        }
        const tempValue = value + ' bar';
        const bbox = g.getBBox();
        this.appendTextElement(g, tempValue, bbox, 10, 0, color);
      }
    });
  }

// Handler for the air condition system tab
  private modifyTextForAirConditionSystem(svgContainer: HTMLElement): void {
    const paths = svgContainer.querySelectorAll('path');

    paths.forEach((path) => {
      if (path.classList.contains('text-pointer')) {

        // const index = this.wagonIndex[this.getWagonId(path)] | 0;
        const value = this.getRandomInt(-10, 50) + ' \u00B0C';

        const bbox = path.getBBox();
        this.appendTextElement(path, value, bbox, 10, 0, this.whiteColor);
      }
    });

    const groups = svgContainer.querySelectorAll('g');

    groups.forEach((group) => {
      if (group.classList.contains('text-pointer')) {

        // const index = this.wagonIndex[this.getWagonId(path)] | 0;
        const value = this.getRandomInt(-10, 50) + ' \u00B0C';

        const bbox = group.getBBox();
        this.appendTextElement(group, value, bbox, 10, 0, this.whiteColor);
      }
    });
  }

  protected mapContextData(context: any): void {
    this.positionEntity = context.positionEntity ? context.positionEntity.entity : context.result.posistionEntity.entity;
    this.entity = context.entity ? context.entity.entity : context.result.entity;

    // get source
    this.isAselsanTrain = super.getValue(this.entity, 'source') === ASELSAN_TRAIN_SOURCE_URL;
    // get the values to be displayed on UI
    this.hmiVehicleNo = super.getValue(this.entity, 'hmiVehicleNo');
    this.trainRef = super.getValue(this.positionEntity, 'trainRef');
    this.fDriveApplied = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'fDriveApplied'));
    this.pressBp = super.getValue(this.positionEntity, 'pressBp');
    this.pressMr = super.getValue(this.positionEntity, 'pressMr');
    this.vAscReq = super.getValue(this.positionEntity, 'vAscReq');
    this.voltPantoSka1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'voltPantoSka1'));
    this.hmiJourneyNo = super.getValue(this.positionEntity, 'hmiJourneyNo');
    this.location = this.formattedLocation(super.getValue(this.positionEntity, 'location').coordinates.join(', '));
    const date = new Date(super.getValue(this.positionEntity, 'hmiJourneyNo', 'modifiedAt'));
    this.dotSeparatedDate = TimeUtil.serializeReadableDotSeparatedDate(date);
    this.date = TimeUtil.serializeCustomDatetime(date);
    this.trainId = this.trainRef.object.substring(this.trainRef.object.lastIndexOf(':') + 1);
    this.speed = super.getValue(this.positionEntity, 'speed');
    this.distanceTraveled = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'distanceTraveled'));
    this.ascLimit = super.getValue(this.positionEntity, 'ascLimit');
    this.tractionBar = super.getValue(this.positionEntity, 'tractionBar');
    this.tractionCutOffLed = this.boolMap.get(super.getValue(this.positionEntity, 'tractionCutOffLed'));
    this.majorUnderVoltageError = this.boolMap.get(super.getValue(this.positionEntity, 'majorUnderVoltageError'));
    this.mediumVoltageBackupEnabled2Vehicles = this.boolMap.get(super.getValue(this.positionEntity, 'mediumVoltageBackupEnabled2Vehicles'));
    this.catenaryVoltage = super.getValue(this.positionEntity, 'catenaryVoltage');
    this.pantoABar = super.getValue(this.positionEntity, 'pantoABar');
    this.pTrain = super.getValue(this.positionEntity, 'pTrain');
    this.bp = super.getValue(this.positionEntity, 'bp');
    this.mrp = super.getValue(this.positionEntity, 'mrp');
    this.driverAirConditioningTemperature = super.getValue(this.positionEntity, 'driverAirConditioningTemperature');
    this.externalTemperature = super.getValue(this.positionEntity, 'externalTemperature');
    this.door1LevelFault = this.boolMap.get(super.getValue(this.positionEntity, 'door1LevelFault'));
    this.door2LevelFault = this.boolMap.get(super.getValue(this.positionEntity, 'door2LevelFault'));
    this.doorLoopOkLed = this.boolMap.get(super.getValue(this.positionEntity, 'doorLoopOkLed'));
    this.predictiveMaintenanceCode = super.getValue(this.positionEntity, 'predictiveMaintenanceCode');
    this.motorCooling2mvcbProtection = this.predictiveMaintenanceCode === 8 ? 'error' : 'noError';
    this.checkStatus();
    this.edBar = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'edBar'));
    this.emergencyStatusBrakeLed = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'emergencyStatusBrakeLed'));
    this.tempTrcInv1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcInv1'));
    this.tempTrcInv2 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcInv2'));
    this.tempTrcRec = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcRec'));
    this.tempApsRec = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempApsRec'));
    this.tempApsInv = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempApsInv'));
    this.tempMotorAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempMotorAx3'));
    this.tempMotorAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempMotorAx4'));
    this.tractionTransformerTemp = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tractionTransformerTemp'));
    this.tractionDcLinkOk = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tractionDcLinkOk'));
    this.vTrcDclink = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'vTrcDclink'));
    this.ApsDcLinkOk = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'ApsDcLinkOk'));
    this.vApsDclink = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'vApsDclink'));
    this.forceAvmaxAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'forceAvmaxAx3'));
    this.forceAvmaxAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'forceAvmaxAx4'));
    this.velMotorAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'velMotorAx3'));
    this.velMotorAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'velMotorAx4'));
    this.lcuMaintenance1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'lcuMaintenance1'));

    this.setOtherValues();
    this.setFirstData();
    this.setCommunicationList();
    this.calculatePredictiveInfo();
    this.setData();
  }

  checkStatus() {
    if (this.predictiveMaintenanceCode > 0) {
      this.trainStatus = 'error';
      this.isError = true;
    } else {
      this.trainStatus = 'avaliable';
      this.isError = false;
    }

    if (this.ascLimit > 177) {
      this.status[0] = '-red';
      this.hasError = true;
    } else {
      this.status[0] = '';
    }

    if (this.pantoABar > 50) {
      this.status[1] = '-red';
      this.hasError = true;
    } else {
      this.status[1] = '';
    }

    if (this.bp > 6) {
      this.status[2] = '-red';
      this.hasError = true;
    } else {
      this.status[2] = '';
    }

    if (this.mrp < 7) {
      this.status[3] = '-red';
      this.hasError = true;
    } else {
      this.status[3] = '';
    }
  }

  setOtherValues() {
    this.fDriveForceAx3 = (this.getRandomInt(0, 10000) / 1000).toFixed(2);
    this.fDriveForceAx4 = (this.getRandomInt(0, 10000) / 1000).toFixed(2);
    this.requiredTractionForce = (parseFloat(this.fDriveForceAx3) + parseFloat(this.fDriveForceAx4)).toFixed(2);
    this.forceAppliedAx3 = (this.getRandomInt(0, 10000) / 1000).toFixed(2);
    this.forceAppliedAx4 = (this.getRandomInt(0, 10000) / 1000).toFixed(2);
    this.appliedTractionForce = (parseFloat(this.forceAppliedAx3) + parseFloat(this.forceAppliedAx4)).toFixed(2);
    this.cutOffTraction = this.getRandomInt(1, 1, 0, 0, this.isError);
    this.cutOffByPass = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.tractionNull = this.getRandomInt(1, 1, 0, 0, this.isError);
    /*
    this.tempTrcInv1 = Array.from({length: 5}, () => this.tempTrcInv1);
    this.tempTrcInv2 = Array.from({length: 5}, () => this.tempTrcInv2);
    this.tempTrcRec = Array.from({length: 5}, () => this.tempTrcRec);
    this.vTrcDclink = Array.from({length: 5}, () => this.vTrcDclink);
    this.tempMotorAx3 = Array.from({length: 5}, () => this.tempMotorAx3);
    this.tempMotorAx4 = Array.from({length: 5}, () => this.tempMotorAx4);
    this.forceAvmaxAx3 = Array.from({length: 5}, () => this.forceAvmaxAx3);
    this.forceAvmaxAx4 = Array.from({length: 5}, () => this.forceAvmaxAx4);
    this.velMotorAx3 = Array.from({length: 5}, () => this.velMotorAx3);
    this.velMotorAx4 = Array.from({length: 5}, () => this.velMotorAx4);
    this.edBar = Array.from({length: 5}, () => this.edBar);
     */

    this.pressureGovernorPBReleased = this.getRandomInt(1, 1, 0, 0, this.isError);
    this.pressureGovernorDetectionEmergencyBrake = this.getRandomInt(1, 1, 0, 0, this.isError);
    this.pressureGovernorIndirectBrakeControl = this.getRandomInt(1, 1, 0, 0, this.isError);
    this.diagBrDirectList = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.diagBrDirect = this.diagBrDirectList.some(value => value === 1) ? 1 : 0;
    this.majorBrParkList = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.majorBrPark = this.majorBrParkList.some(value => value === 1) ? 1 : 0;
    this.majorBrWsp = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.ebcu = Array.from({length: 5}, () => this.getRandomInt(1, 1, 0, 0, this.isError));
    this.diagBrCPress = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));

    this.yuksekAkimKaynakliVakumHatasi = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.hvConfigError = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.tumPanolarIzole = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.pantografDustu = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.diagPantoComp = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.majorMvError = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.vcbCloseSka1 = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.vcbCloseSka2 = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.hvDisconnectorCloseSKA1 = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.hvDisconnectorCloseSKA2 = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.hvDisconnectorCloseOA2 = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.pressurePs1SafeguardAddSka1 = 1;
    this.pressurePs1SafeguardAddSka2 = 0;
    this.tractionTransformerOilFlow = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.tractionTransformerOilLevel = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.tractionTransformerOverPressure = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.measuringBatteryVoltage = Array.from({ length: 5 }, () => parseFloat((this.getRandomInt(6, 160, 0, 5, this.isError) * 15.71).toFixed(2)));
    this.tempBattery = Array.from({length: 5}, () => this.getRandomInt(-10, 40, 41, 127, this.isError));
    this.aBatteryCharger = Array.from({length: 5}, () => this.getRandomInt(-128, 127));

    this.passengerAirConditioningMode = this.getRandomInt(0, 3);
    this.hvacsInhibitionList = Array.from({length: 5}, () => this.getRandomInt(0, 1));
    this.driverMajorHvac = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.passengerMajorHvac1 = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.passengerMajorHvac2 = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.driverMinorHvac = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.passengerMinorHvac1 = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.passengerMinorHvac2 = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));

    this.outerDoorStatus = Array.from({length: 5}, () => this.getRandomInt(0, 2));
    this.doorFaultPriorityAActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.doorFaultPriorityBActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.doorFaultPriorityCActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.stepFaultPriorityAActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.stepFaultPriorityBActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.stepFaultPriorityCActive = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.doorInsulationList = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.innerDoorError = Array.from({length: 5}, () => this.getRandomInt(0, 0, 1, 1, this.isError));
    this.door1LevelFault =
      this.doorFaultPriorityAActive.some(value => value === 1) ||
      this.stepFaultPriorityAActive.some(value => value === 1) ? 1 : 0;
    this.door2LevelFault =
      this.doorFaultPriorityBActive.some(value => value === 1) ||
      this.stepFaultPriorityBActive.some(value => value === 1) ||
      this.doorFaultPriorityCActive.some(value => value === 1) ||
      this.stepFaultPriorityCActive.some(value => value === 1) ? 1 : 0;
    this.ickapi1SeviyeAriza = this.innerDoorError.some(value => value === 1) ? 1 : 0;
    this.kapi1SeviyeAriza =
      this.doorFaultPriorityAActive.some(value => value === 1) ||
      this.stepFaultPriorityAActive.some(value => value === 1) ? 1 : 0;
    this.prmAsansorKapi1Devrede = this.getRandomInt(0, 0);
    this.doorLoopOkLed =
      this.doorInsulationList.some(value => value === 1) ||
      this.doorFaultPriorityAActive.some(value => value === 1) ||
      this.stepFaultPriorityAActive.some(value => value === 1) ||
      this.doorFaultPriorityBActive.some(value => value === 1) ||
      this.stepFaultPriorityBActive.some(value => value === 1) ||
      this.doorFaultPriorityCActive.some(value => value === 1) ||
      this.stepFaultPriorityCActive.some(value => value === 1) ||
      this.innerDoorError.some(value => value === 1) ? 1 : 0;
    this.slDoorControlByPass = this.getRandomInt(0, 0);

    this.contactorHalfLightList = Array.from({length: 5}, () => this.getRandomInt(0, 1));
    this.contactorEmergencyLightList = Array.from({length: 5}, () => this.getRandomInt(0, 1));
    this.interiorLightingModeList = this.contactorHalfLightList.map((halfLight, index) =>
      this.determineInteriorLightingMode(halfLight, this.contactorEmergencyLightList[index])
    );
    this.toiletIsNotActive = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.toiletWaterLevelLow = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.toiletBowlFull = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.toiletWastewaterTankFull = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.toiletSosSignal = this.getRandomInt(0, 0, 1, 1, this.isError);

    this.etcsIsolationByPass = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.ertmsCutOff = this.getRandomInt(1, 1, 0, 0, this.isError);
    this.atsByPass = this.getRandomInt(0, 0, 1, 1, this.isError);
    this.ertmsServiceBrake = this.getRandomInt(0, 0, 1, 1, this.isError);

    for (let index = 1; index < 5; index++) {
      this.tempApsRec[index] = this.getRandomInt(0, 0, 1, 1, false); // power Voltage
      this.tempApsInv[index] = this.getRandomInt(0, 0, 1, 1, false); // power Voltage
      this.vApsDclink[index] = this.getRandomInt(0, 159, 160, 250, false); // power Voltage
      this.vApsDclink[index] *= 5;
      this.tractionTransformerTemp[index] = this.getRandomInt(-128, 110, 111, 127, this.isError); // power Voltage
      // this.tractionTransformerTemp[index] += 78;
    }

    this.diagComp1 = 0;
    this.diagComp2 = 0;
    this.kismiPnomatikFrenKaybi = 0;
    this.erisilebilirKompresorYok = 0;
    this.enAz1EbcuHatali = 0;
    this.mrDusuk = 0;
    this.frenUygulamaHatasi = [0, 0, 0, 0, 0];
    this.frenBirakmaHatasi = 0;
    this.havaSuspansiyonHatas = 0;
    this.kumlamaHatasi = 0;
    this.parkFreniHatasi = [0, 0, 0, 0, 0];
    this.birdenFazlaVakumDevrede = 0;
    this.birdenFazlaPantoKaldirilamaz = 0;
    this.ortaGerilimKontaktorKontrolHatasi = 0;
    this.ortaGerilimHatasiKabinDegisikligi = 0;
    this.cutOffDongusuBaypasEdilmistir = 0;
    this.siviSogutmaBakim = 0;
    this.siviSogutmaAcilBakim = 0;
    this.minorAriza = 0;
    this.majorAriza = 0;
    this.agirAriza = 0;
    this.ickapi2SeviyeAriza = 0;
    this.kapi2SeviyeAriza = 0;
    this.prmAsansorKapi2Devrede = 0;
    this.tkysHaberlesmeHatasi = 0;
    this.tkysFrenHaberlesmeHatasi = 0;
    this.tkysCerHaberlesmeHatasi = 0;
    this.wtbHaberlesmeHatasi = 0;
  }

  protected setFirstData() {
    this.firstTrainData = new TrainDefinition({
      fDriveForceAx3: this.fDriveForceAx3,
      fDriveForceAx4: this.fDriveForceAx4,
      requiredTractionForce: this.requiredTractionForce,
      forceAppliedAx3: this.forceAppliedAx3,
      forceAppliedAx4: this.forceAppliedAx4,
      appliedTractionForce: this.appliedTractionForce,
      cutOffTraction: this.cutOffTraction,
      cutOffByPass: this.cutOffByPass,
      tractionNull: this.tractionNull,
      motorCooling2mvcbProtection: this.predictiveMaintenanceCode === 8 ? 'error' : 'noError',
      tempTrcInv1: this.tempTrcInv1,
      tempTrcInv2: this.tempTrcInv2,
      tempTrcRec: this.tempTrcRec,
      vTrcDclink: this.vTrcDclink,
      tempMotorAx3: this.tempMotorAx3,
      tempMotorAx4: this.tempMotorAx4,
      forceAvmaxAx3: this.forceAvmaxAx3,
      forceAvmaxAx4: this.forceAvmaxAx4,
      velMotorAx3: this.velMotorAx3,
      velMotorAx4: this.velMotorAx4,
      edBar: this.edBar,
      bp: this.bp,
      mrp: this.mrp,
      pressureGovernorPBReleased: this.pressureGovernorPBReleased,
      pressureGovernorDetectionEmergencyBrake: this.pressureGovernorDetectionEmergencyBrake,
      pressureGovernorIndirectBrakeControl: this.pressureGovernorIndirectBrakeControl,
      diagBrDirectList: this.diagBrDirectList,
      diagBrDirect: this.diagBrDirect,
      majorBrParkList: this.majorBrParkList,
      majorBrPark: this.majorBrPark,
      majorBrWsp: this.majorBrWsp,
      ebcu: this.ebcu,
      diagBrCPress: this.diagBrCPress,
      majorUnderVoltageError: this.majorUnderVoltageError,
      catenaryVoltage: this.catenaryVoltage,
      yuksekAkimKaynakliVakumHatasi: this.yuksekAkimKaynakliVakumHatasi,
      hvConfigError: this.hvConfigError,
      pantoABar: this.pantoABar,
      tumPanolarIzole: this.tumPanolarIzole,
      pantografDustu: this.pantografDustu,
      diagPantoComp: this.diagPantoComp,
      pTrain: this.pTrain,
      majorMvError: this.majorMvError,
      vcbCloseSka1: this.vcbCloseSka1,
      vcbCloseSka2: this.vcbCloseSka2,
      pressurePs1SafeguardAddSka1: this.pressurePs1SafeguardAddSka1,
      pressurePs1SafeguardAddSka2: this.pressurePs1SafeguardAddSka2,
      hvDisconnectorCloseSKA1: this.hvDisconnectorCloseSKA1,
      hvDisconnectorCloseSKA2: this.hvDisconnectorCloseSKA2,
      hvDisconnectorCloseOA2: this.hvDisconnectorCloseOA2,
      tractionTransformerOilFlow: this.tractionTransformerOilFlow,
      tractionTransformerOilLevel: this.tractionTransformerOilLevel,
      tractionTransformerOverPressure: this.tractionTransformerOverPressure,
      measuringBatteryVoltage: this.measuringBatteryVoltage,
      tempBattery: this.tempBattery,
      aBatteryCharger: this.aBatteryCharger,
      driverAirConditioningTemperature: this.driverAirConditioningTemperature,
      passengerAirConditioningMode: this.passengerAirConditioningMode,
      hvacsInhibitionList: this.hvacsInhibitionList,
      driverMajorHvac: this.driverMajorHvac,
      passengerMajorHvac1: this.passengerMajorHvac1,
      passengerMajorHvac2: this.passengerMajorHvac2,
      driverMinorHvac: this.driverMinorHvac,
      passengerMinorHvac1: this.passengerMinorHvac1,
      passengerMinorHvac2: this.passengerMinorHvac2,
      door1LevelFault: this.door1LevelFault,
      door2LevelFault: this.door2LevelFault,
      ickapi1SeviyeAriza: this.ickapi1SeviyeAriza,
      kapi1SeviyeAriza: this.kapi1SeviyeAriza,
      prmAsansorKapi1Devrede: this.prmAsansorKapi1Devrede,
      doorLoopOkLed: this.doorLoopOkLed,
      slDoorControlByPass: this.slDoorControlByPass,
      contactorHalfLightList: this.contactorHalfLightList,
      contactorEmergencyLightList: this.contactorEmergencyLightList,
      interiorLightingModeList: this.interiorLightingModeList,
      toiletIsNotActive: this.toiletIsNotActive,
      toiletWaterLevelLow: this.toiletWaterLevelLow,
      toiletBowlFull: this.toiletBowlFull,
      toiletWastewaterTankFull: this.toiletWastewaterTankFull,
      toiletSosSignal: this.toiletSosSignal,
      etcsIsolationByPass: this.etcsIsolationByPass,
      ertmsCutOff: this.ertmsCutOff,
      atsByPass: this.atsByPass,
      ertmsServiceBrake: this.ertmsServiceBrake,
      location: this.formattedLocation(this.location),
      ascLimit: this.ascLimit,
      tempApsRec: this.tempApsRec,
      tempApsInv: this.tempApsInv,
      vApsDclink: this.vApsDclink,
      tractionTransformerTemp: this.tractionTransformerTemp,

      voltPantoSka1: this.voltPantoSka1,
      hmiJourneyNo: this.hmiJourneyNo,
      speed: this.speed.value,
      distanceTraveled: this.distanceTraveled,
      tractionBar: this.tractionBar,
      tractionCutOffLed: this.tractionCutOffLed,
      mediumVoltageBackupEnabled2Vehicles: this.mediumVoltageBackupEnabled2Vehicles,
      emergencyStatusBrakeLed: this.emergencyStatusBrakeLed,
      externalTemperature: this.externalTemperature,
      predictiveMaintenanceCode: this.predictiveMaintenanceCode,
      tractionDcLinkOk: this.tractionDcLinkOk,
      ApsDcLinkOk: this.ApsDcLinkOk,
      lcuMaintenance1: this.lcuMaintenance1
    });
    this.liveTrainData = this.firstTrainData;
  }

  private setCommunicationList() {
    this.mvbComList.push(new MvbCommunication({
      ska1: 'bcu',
      oa1: 'bcu',
      oa2: 'bcu',
      oa3: 'bcu',
      ska2: 'bcu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom1Ec',
      oa1: 'riom1Ec',
      oa2: 'riom1Ec',
      oa3: 'riom1Ec',
      ska2: 'riom1Ec',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'tcu',
      oa1: 'tcu',
      oa2: 'tcu',
      oa3: 'tcu',
      ska2: 'tcu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom2Ec',
      oa1: 'riom2Ec',
      oa2: 'riom2Ec',
      oa3: 'riom2Ec',
      ska2: 'riom2Ec',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'wtbmvb(0521)',
      oa1: '',
      oa2: '',
      oa3: 'eventRecorder',
      ska2: 'wtbmvb(0521)',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'ccu',
      oa1: '',
      oa2: '',
      oa3: 'rdu',
      ska2: 'ccu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom1Cd',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'riom1Cd',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom2Cd',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'riom2Cd',
    }));


    this.camComList.push(new CanCommunication({
      riom1: 'hvacCab',
      riom2: 'papis',
      riom3: 'energyMeter',
      riom4: 'internalDoor',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'cctv',
      riom3: 'hvac2',
      riom4: 'hvac1',
    }));

    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'fdu',
      riom3: 'door1',
      riom4: 'door2',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'door1',
      riom3: 'wc',
      riom4: 'innerDoor2',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'innerDoor1',
      riom3: 'door2',
      riom4: '',
    }));
  }

  private calculatePredictiveInfo() {
    if (this.predictiveMaintenanceCode === 3 || this.predictiveMaintenanceCode === 5 || this.predictiveMaintenanceCode === 7 || this.predictiveMaintenanceCode === 8
      || this.predictiveMaintenanceCode === 11 || this.predictiveMaintenanceCode === 12 || this.predictiveMaintenanceCode === 13) {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + this.predictiveMaintenanceCode;
      this.status[4] = '-red';
    } else {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + 0;
      this.status[4] = '';
    }

    if (this.predictiveMaintenanceCode === 1) {
      this.status[5] = '-red';
    } else {
      this.status[5] = '';
    }
    if (this.predictiveMaintenanceCode === 2 || this.predictiveMaintenanceCode === 3) {
      this.status[6] = '-red';
    } else {
      this.status[6] = '';
    }
    if (this.predictiveMaintenanceCode === 4) {
      this.status[7] = '-red';
    } else {
      this.status[7] = '';
    }
    if (this.predictiveMaintenanceCode === 5) {
      this.status[8] = '-red';
    } else {
      this.status[8] = '';
    }
    if (this.predictiveMaintenanceCode === 6) {
      this.status[9] = '-red';
    } else {
      this.status[9] = '';
    }
    if (this.predictiveMaintenanceCode === 7) {
      this.status[10] = '-red';
    } else {
      this.status[10] = '';
    }
    if (this.predictiveMaintenanceCode === 8) {
      this.status[11] = '-red';
    } else {
      this.status[11] = '';
    }
    this.controlTractionSystemStatus();
    this.controlBrakeSystemStatus();
    this.controlPowerVoltageSystemStatus();
    this.controlAirConditionSystemStatus();
    this.controlDoorSystemStatus();
    this.controlOthersSystemStatus();
    this.controlSignalizationSystemStatus();
  }

  hasBrakeError() {
    if (this.brakeSystemRules[this.majorBrPark] === 1
      || this.brakeSystemRules[this.pressureGovernorPBReleased] === 0
      || this.brakeSystemRules[this.pressureGovernorDetectionEmergencyBrake] === 0
      || this.brakeSystemRules[this.pressureGovernorIndirectBrakeControl] === 0
      || this.brakeSystemRules[this.diagBrDirect] === this.redColor
      || this.majorBrWsp.includes(1)
      || this.ebcu.includes(0)
      || this.diagBrCPress.includes(1)) {
      return true;
    }
    return false;
  }

  subscribeWebsocket(): void {
    this.socketService.subscribe('20d7a274-337e-46e3-b85a-8bac9d157106')
      .pipe(
        filter(() => !this.shouldUnsubscribe),
      )
      .subscribe(notification => {
        this.websocketCallback(notification);
      });
  }

  protected websocketCallback(notification: Notification): void {
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.entityId.includes('TrainPosition')) {
        if (notification.eventPayload.eventType === NGSIEventType.CREATE &&
          this.trainRef &&
          this.trainRef.object &&
          this.trainRef.object === notification.eventPayload.content.trainRef.object) {

          if (this.isLiveMode) {
            this.setOtherValues();
            this.setLiveTrainInfo(notification.eventPayload.content);
            this.addNotification(notification.eventPayload.timestamp, notification.eventPayload.content.trainRef);
          }
        }
      }
    }
  }

  protected setLiveTrainInfo(content) {
    this.liveTrainData = new TrainDefinition({
      fDriveApplied: content.fDriveApplied.value,
      voltPantoSka1: content.voltPantoSka1.value,
      hmiJourneyNo: content.hmiJourneyNo.value,
      location: this.formattedLocation(content.location.value.coordinates.join(', ')),
      speed: content.speed.value,
      distanceTraveled: content.distanceTraveled.value,
      ascLimit: content.ascLimit.value,
      tractionBar: content.tractionBar.value,
      tractionCutOffLed: this.boolMap.get(content.tractionCutOffLed.value),
      majorUnderVoltageError: this.boolMap.get(content.majorUnderVoltageError.value),
      mediumVoltageBackupEnabled2Vehicles: this.boolMap.get(content.mediumVoltageBackupEnabled2Vehicles.value),
      catenaryVoltage: content.catenaryVoltage.value,
      pantoABar: content.pantoABar.value,
      pTrain: content.pTrain.value,
      bp: content.bp.value,
      mrp: content.mrp.value,
      edBar: this.edBar,
      emergencyStatusBrakeLed: this.boolMap.get(content.emergencyStatusBrakeLed.value),
      driverAirConditioningTemperature: content.driverAirConditioningTemperature.value,
      externalTemperature: content.externalTemperature.value,
      door1LevelFault: this.boolMap.get(content.door1LevelFault.value),
      door2LevelFault: this.boolMap.get(content.door2LevelFault.value),
      doorLoopOkLed: this.boolMap.get(content.doorLoopOkLed.value),
      predictiveMaintenanceCode: content.predictiveMaintenanceCode.value,
      motorCooling2mvcbProtection: content.predictiveMaintenanceCode.value === 8 ? 'Hata var' : 'Hata Yok',
      tempTrcInv1: this.tempTrcInv1,
      tempTrcInv2: this.tempTrcInv2,
      tempTrcRec: this.tempTrcRec,
      tempApsRec: this.tempApsRec,
      tempApsInv: this.tempApsInv,
      tempMotorAx3: this.tempMotorAx3,
      tempMotorAx4: this.tempMotorAx4,
      tractionTransformerTemp: this.tractionTransformerTemp,
      tractionDcLinkOk: this.tractionDcLinkOk,
      vTrcDclink: this.vTrcDclink,
      ApsDcLinkOk: this.ApsDcLinkOk,
      vApsDclink: this.vApsDclink,
      forceAvmaxAx3: this.forceAvmaxAx3,
      forceAvmaxAx4: this.forceAvmaxAx4,
      velMotorAx3: this.velMotorAx3,
      velMotorAx4: this.velMotorAx4,
      lcuMaintenance1: this.lcuMaintenance1,

      fDriveForceAx3: this.fDriveForceAx3,
      fDriveForceAx4: this.fDriveForceAx4,
      requiredTractionForce: this.requiredTractionForce,
      forceAppliedAx3: this.forceAppliedAx3,
      forceAppliedAx4: this.forceAppliedAx4,
      appliedTractionForce: this.appliedTractionForce,
      cutOffTraction: this.cutOffTraction,
      cutOffByPass: this.cutOffByPass,
      tractionNull: this.tractionNull,
      pressureGovernorPBReleased: this.pressureGovernorPBReleased,
      pressureGovernorDetectionEmergencyBrake: this.pressureGovernorDetectionEmergencyBrake,
      pressureGovernorIndirectBrakeControl: this.pressureGovernorIndirectBrakeControl,
      diagBrDirectList: this.diagBrDirectList,
      diagBrDirect: this.diagBrDirect,
      majorBrParkList: this.majorBrParkList,
      majorBrPark: this.majorBrPark,
      majorBrWsp: this.majorBrWsp,
      ebcu: this.ebcu,
      diagBrCPress: this.diagBrCPress,
      yuksekAkimKaynakliVakumHatasi: this.yuksekAkimKaynakliVakumHatasi,
      hvConfigError: this.hvConfigError,
      tumPanolarIzole: this.tumPanolarIzole,
      pantografDustu: this.pantografDustu,
      diagPantoComp: this.diagPantoComp,
      majorMvError: this.majorMvError,
      vcbCloseSka1: this.vcbCloseSka1,
      vcbCloseSka2: this.vcbCloseSka2,
      pressurePs1SafeguardAddSka1: this.pressurePs1SafeguardAddSka1,
      pressurePs1SafeguardAddSka2: this.pressurePs1SafeguardAddSka2,
      hvDisconnectorCloseSKA1: this.hvDisconnectorCloseSKA1,
      hvDisconnectorCloseSKA2: this.hvDisconnectorCloseSKA2,
      hvDisconnectorCloseOA2: this.hvDisconnectorCloseOA2,
      tractionTransformerOilFlow: this.tractionTransformerOilFlow,
      tractionTransformerOilLevel: this.tractionTransformerOilLevel,
      tractionTransformerOverPressure: this.tractionTransformerOverPressure,
      measuringBatteryVoltage: this.measuringBatteryVoltage,
      tempBattery: this.tempBattery,
      aBatteryCharger: this.aBatteryCharger,
      hvacsInhibitionList: this.hvacsInhibitionList,
      driverMajorHvac: this.driverMajorHvac,
      passengerMajorHvac1: this.passengerMajorHvac1,
      passengerMajorHvac2: this.passengerMajorHvac2,
      driverMinorHvac: this.driverMinorHvac,
      passengerMinorHvac1: this.passengerMinorHvac1,
      passengerMinorHvac2: this.passengerMinorHvac2,
      ickapi1SeviyeAriza: this.ickapi1SeviyeAriza,
      kapi1SeviyeAriza: this.kapi1SeviyeAriza,
      prmAsansorKapi1Devrede: this.prmAsansorKapi1Devrede,
      slDoorControlByPass: this.slDoorControlByPass,
      contactorHalfLightList: this.contactorHalfLightList,
      contactorEmergencyLightList: this.contactorEmergencyLightList,
      interiorLightingModeList: this.interiorLightingModeList,
      toiletIsNotActive: this.toiletIsNotActive,
      toiletWaterLevelLow: this.toiletWaterLevelLow,
      toiletBowlFull: this.toiletBowlFull,
      toiletWastewaterTankFull: this.toiletWastewaterTankFull,
      toiletSosSignal: this.toiletSosSignal,
      etcsIsolationByPass: this.etcsIsolationByPass,
      ertmsCutOff: this.ertmsCutOff,
      atsByPass: this.atsByPass,
      ertmsServiceBrake: this.ertmsServiceBrake,
    });

    this.liveTrainData.edBar[0] = content.edBar.value;
    this.liveTrainData.tempTrcInv1[0] = content.tempTrcInv1.value;
    this.liveTrainData.tempTrcInv2[0] = content.tempTrcInv2.value;
    this.liveTrainData.tempTrcRec[0] = content.tempTrcRec.value;
    this.liveTrainData.tempApsInv[0] = content.tempApsInv.value;
    this.liveTrainData.tempMotorAx3[0] = content.tempMotorAx3.value;
    this.liveTrainData.tempMotorAx4[0] = content.tempMotorAx4.value;
    this.liveTrainData.tractionTransformerTemp[0] = content.tractionTransformerTemp.value;
    this.liveTrainData.vTrcDclink[0] = content.vTrcDclink.value;
    this.liveTrainData.vApsDclink[0] = content.vApsDclink.value;
    this.liveTrainData.forceAvmaxAx3[0] = content.forceAvmaxAx3.value;
    this.liveTrainData.forceAvmaxAx4[0] = content.forceAvmaxAx4.value;
    this.liveTrainData.velMotorAx3[0] = content.velMotorAx3.value;
    this.liveTrainData.velMotorAx4[0] = content.velMotorAx4.value;
    this.liveTrainData.lcuMaintenance1[0] = content.lcuMaintenance1.value;
    this.liveTrainData.ApsDcLinkOk[0] = content.ApsDcLinkOk.value;
    this.liveTrainData.tractionDcLinkOk[0] = content.tractionDcLinkOk.value;

    this.setData();
  }


  protected addNotification(date, trainRef) {
    const refList = trainRef.object.split(':');
    const refId = refList[3];

    this.trainNotificationList.unshift(new TrainNotification({
      id: this.notificationCount++,
      observedat: this.calculateDate(date),
      description: 'Tren :' + refId,
    }));

    if (this.trainNotificationList.length > 15) {
      this.trainNotificationList.pop();
    }
  }

  // 0 = Vehicle Cannot Move
  // 1 = Vehicle Can Move
  isVehicleMove(value: number): string {
    switch (value) {
      case 0:
        return 'vehicleCannotMove';
      case 1:
        return 'vehicleCanMove';
      default:
        return 'vehicleCannotMove';
    }
  }

  isBrakeError(context: string, value: number): string {
    const contextMapping = {
      pressureGovernorPBReleased: {
        0: 'vehicleCannotMove',
        default: 'vehicleCanMove'
      },
      pressureGovernorDetectionEmergencyBrake: {
        0: 'emergencyBrakeDetected',
        default: 'vehicleCanMove'
      },
      pressureGovernorIndirectBrakeControl: {
        0: 'indirectBrakeDetected',
        default: 'vehicleCanMove'
      },
      diagBrDirect: {
        0: 'vehicleCanMove',
        1: 'directBrakeDetected'
      }
    };

    const contextConfig = contextMapping[context];
    if (contextConfig) {
      return contextConfig[value] || contextConfig.default;
    }

    return 'vehicleCannotMove';
  }

  getCutOffByPassStatus(value: number): string {
    return value === 0 ? 'active' : 'passive';
  }

  // 0 = Vacuum Active
  // 1 = Vacuum Passive
  getVacuumStatus(value: number): string {
    return value === 0 ? 'active' : 'passive';
  }

  // 0 = Pantograph Down
  // 1 = Pantograph Up
  getPantographStatus(value: number): string {
    return value === 0 ? 'pantographDown' : 'pantographUp';
  }

  // 0 = Disconnector Passive
  // 1 = Disconnector Active
  getDisconnectorStatus(value: number): string {
    return value === 0 ? 'passive' : 'active';
  }

  // mod = 0 inactive
  // mod = 1 manuel
  // mod = 2 automatic
  getAirConditionMod(mod: number): string {
    switch (mod) {
      case 1:
        return 'manualMod';
      case 2:
        return 'automaticMod';
      default:
        return 'inActiveMod';
    }
  }

  // 0 = Door Bypass inactive
  // 1 = Door Bypass active
  getDoorBypassStatus(value: number): string {
    return value === 0 ? 'bypassModeInactive' : 'bypassModeActive';
  }

  // 0 = Light Off
  // 1 = Emergency Light
  // 2 = Half Light
  // 3 = Full Lighting
  getInteriorLightingMode(mode: number): string {
    switch (mode) {
      case 0:
        return 'lightingOff'; // Off
      case 1:
        return 'emergencyLighting'; // Emergency Lighting
      case 2:
        return 'halfLighting'; // Half Lighting
      case 3:
        return 'fullLighting'; // Full Lighting
      default:
        return 'lightingOff';  // Off
    }
  }

  tractionSystemRules = {
    cutOffTraction: {0: this.redColor, default: this.whiteColor},
    cutOffByPass: {1: this.blueColor, default: this.whiteColor},
    tractionNull: {0: this.redColor, default: this.whiteColor}
  };

  brakeSystemRules = {
    majorBrPark: {1: this.redColor, default: this.whiteColor},
    pressureGovernorPBReleased: {0: this.redColor, default: this.whiteColor},
    pressureGovernorDetectionEmergencyBrake: {0: this.redColor, default: this.whiteColor},
    pressureGovernorIndirectBrakeControl: {0: this.redColor, default: this.whiteColor},
    majorBrWsp: {1: this.redColor, default: this.whiteColor},
    ebcu: {0: this.redColor, default: this.whiteColor},
    diagBrDirect: {1: this.redColor, default: this.whiteColor},
    diagBrCPress: {1: this.redColor, default: this.whiteColor}
  };

  powerVoltageSystemRules = {
    yuksekAkimKaynakliVakumHatasi: {1: this.redColor, default: this.whiteColor},
    hvConfigError: {1: this.redColor, default: this.whiteColor},
    tumPanolarIzole: {1: this.redColor, default: this.whiteColor},
    pantografDustu: {1: this.redColor, default: this.whiteColor},
    diagPantoComp: {1: this.redColor, default: this.whiteColor},
    majorMvError: {1: this.redColor, default: this.whiteColor},
    vcbCloseSka: {1: this.blueColor, default: this.whiteColor},
    pressurePs1SafeguardAddSka: {1: this.blueColor, default: this.whiteColor},
    hvDisconnectorCloseSKA: {1: this.blueColor, default: this.whiteColor},
    hvDisconnectorCloseOA: {1: this.blueColor, default: this.whiteColor},
    tractionTransformerOilFlow: {1: this.redColor, default: this.whiteColor},
    tractionTransformerOilLevel: {1: this.redColor, default: this.whiteColor},
    tractionTransformerOverPressure: {1: this.redColor, default: this.whiteColor},
    tractionTransformerTemp: (value: number) => {
      if (value < 55) return this.whiteColor;
      if (value > 110) return this.redColor;
      return this.orangeColor;
    },
    tempApsInv: {1: this.redColor, default: this.whiteColor},
    tempApsRec: {1: this.redColor, default: this.whiteColor},
    vApsDclink: (value: number) => (value < 800 ? this.whiteColor : this.redColor),
    measuringBatteryVoltage: (value: number) => {
      if (value > 90) return this.whiteColor;
      if (value < 80) return this.redColor;
      return this.orangeColor;
    },
    tempBattery: (value: number) => (value >= -10 && value <= 40 ? this.whiteColor : this.redColor)
  };

  airConditionSystemRules = {
    majorHvac: {1: this.redColor, default: this.whiteColor},
    minorHvac: {1: this.orangeColor, default: this.whiteColor},
  };

  doorSystemRules = {
    door1LevelFault: {1: this.redColor, default: this.whiteColor},
    door2LevelFault: {1: this.orangeColor, default: this.whiteColor},
    ickapi1SeviyeAriza: {1: this.redColor, default: this.whiteColor},
    kapi1SeviyeAriza: {1: this.redColor, default: this.whiteColor},
    prmAsansorKapi1Devrede: {1: this.redColor, default: this.whiteColor},
    doorLoopOkLed: {1: this.redColor, default: this.whiteColor},
    slDoorControlByPass: {1: this.blueColor, default: this.whiteColor}
  };

  otherSystemRules = {
    toiletIsNotActive: {1: this.redColor, default: this.whiteColor},
    toiletWaterLevelLow: {1: this.redColor, default: this.whiteColor},
    toiletBowlFull: {1: this.redColor, default: this.whiteColor},
    toiletWastewaterTankFull: {1: this.redColor, default: this.whiteColor},
    toiletSosSignal: {1: this.redColor, default: this.whiteColor}
  };

  signalizationSystemRules = {
    etcsIsolationByPass: {1: this.redColor, default: this.whiteColor},
    ertmsCutOff: {0: this.redColor, default: this.whiteColor},
    atsByPass: {1: this.redColor, default: this.whiteColor},
    ertmsServiceBrake: {1: this.redColor, default: this.whiteColor},
  };


  getDynamicColor(rules: any, context: string, value: number): string {
    const rule = rules[context];

    if (!rule) {
      console.error(`Context "${context}" not found in rules.`);
      return this.whiteColor;
    }

    if (typeof rule === 'function') {
      return rule(value);
    }

    return rule[value] !== undefined ? rule[value] : rule.default || this.whiteColor;
  }

  determineInteriorLightingMode(halfLight: number, emergencyLight: number): number {
    let mode: number;

    if (halfLight === 1 && emergencyLight === 1) {
      mode = 3;  // Tam aydınlatma
    } else if (halfLight === 1 && emergencyLight === 0) {
      mode = 2;  // Yarı aydınlatma
    } else if (halfLight === 0 && emergencyLight === 1) {
      mode = 1;  // Acil durum aydınlatma
    } else {
      mode = 0;  // Kapalı
    }

    return mode;
  }

  protected setData() {
    const source = this.isLiveMode ? this.liveTrainData : this.firstTrainData;

    // Traction
    this.fDriveForceAx3 = source.fDriveForceAx3;
    this.fDriveForceAx4 = source.fDriveForceAx4;
    this.requiredTractionForce = source.requiredTractionForce;
    this.forceAppliedAx3 = source.forceAppliedAx3;
    this.forceAppliedAx4 = source.forceAppliedAx4;
    this.appliedTractionForce = source.appliedTractionForce;
    this.cutOffTraction = source.cutOffTraction;
    this.cutOffByPass = source.cutOffByPass;
    this.tractionNull = source.tractionNull;
    this.motorCooling2mvcbProtection = source.motorCooling2mvcbProtection;
    this.tempTrcInv1 = source.tempTrcInv1;
    this.tempTrcInv2 = source.tempTrcInv2;
    this.tempTrcRec = source.tempTrcRec;
    this.vTrcDclink = source.vTrcDclink;
    this.tempMotorAx3 = source.tempMotorAx3;
    this.tempMotorAx4 = source.tempMotorAx4;
    this.forceAvmaxAx3 = source.forceAvmaxAx3;
    this.forceAvmaxAx4 = source.forceAvmaxAx4;
    this.velMotorAx3 = source.velMotorAx3;
    this.velMotorAx4 = source.velMotorAx4;
    this.edBar = source.edBar;

    // Brake
    this.bp = source.bp;
    this.mrp = source.mrp;
    this.pressureGovernorPBReleased = source.pressureGovernorPBReleased;
    this.pressureGovernorDetectionEmergencyBrake = source.pressureGovernorDetectionEmergencyBrake;
    this.pressureGovernorIndirectBrakeControl = source.pressureGovernorIndirectBrakeControl;
    this.diagBrDirectList = source.diagBrDirectList; // sil
    this.diagBrDirect = source.diagBrDirect;
    this.majorBrParkList = source.majorBrParkList; // sil
    this.majorBrPark = source.majorBrPark;
    this.majorBrWsp = source.majorBrWsp;
    this.ebcu = source.ebcu;
    this.diagBrCPress = source.diagBrCPress;

    // Power Voltage
    this.majorUnderVoltageError = source.majorUnderVoltageError;
    this.catenaryVoltage = source.catenaryVoltage;
    this.yuksekAkimKaynakliVakumHatasi = source.yuksekAkimKaynakliVakumHatasi;
    this.hvConfigError = source.hvConfigError;
    this.pantoABar = source.pantoABar;
    this.tumPanolarIzole = source.tumPanolarIzole;
    this.pantografDustu = source.pantografDustu;
    this.diagPantoComp = source.diagPantoComp;
    this.pTrain = source.pTrain;
    this.majorMvError = source.majorMvError;
    this.vcbCloseSka1 = source.vcbCloseSka1;
    this.vcbCloseSka2 = source.vcbCloseSka2;
    this.pressurePs1SafeguardAddSka1 = source.pressurePs1SafeguardAddSka1;
    this.pressurePs1SafeguardAddSka2 = source.pressurePs1SafeguardAddSka2;
    this.hvDisconnectorCloseSKA1 = source.hvDisconnectorCloseSKA1;
    this.hvDisconnectorCloseSKA2 = source.hvDisconnectorCloseSKA2;
    this.hvDisconnectorCloseOA2 = source.hvDisconnectorCloseOA2;
    this.tractionTransformerOilFlow = source.tractionTransformerOilFlow;
    this.tractionTransformerOilLevel = source.tractionTransformerOilLevel;
    this.tractionTransformerOverPressure = source.tractionTransformerOverPressure;
    this.measuringBatteryVoltage = source.measuringBatteryVoltage;
    this.tempBattery = source.tempBattery;
    this.aBatteryCharger = source.aBatteryCharger;
    this.tempApsRec = source.tempApsRec;
    this.tempApsInv = source.tempApsInv;
    this.vApsDclink = source.vApsDclink;
    this.tractionTransformerTemp = source.tractionTransformerTemp;

    // Air Condition
    this.driverAirConditioningTemperature = source.driverAirConditioningTemperature;
    this.passengerAirConditioningMode = source.passengerAirConditioningMode;
    this.hvacsInhibitionList = source.hvacsInhibitionList;
    this.driverMajorHvac = source.driverMajorHvac;
    this.passengerMajorHvac1 = source.passengerMajorHvac1;
    this.passengerMajorHvac2 = source.passengerMajorHvac2;
    this.driverMinorHvac = source.driverMinorHvac;
    this.passengerMinorHvac1 = source.passengerMinorHvac1;
    this.passengerMinorHvac2 = source.passengerMinorHvac2;

    // Door
    this.door1LevelFault = source.door1LevelFault;
    this.door2LevelFault = source.door2LevelFault;
    this.ickapi1SeviyeAriza = source.ickapi1SeviyeAriza;
    this.kapi1SeviyeAriza = source.kapi1SeviyeAriza;
    this.prmAsansorKapi1Devrede = source.prmAsansorKapi1Devrede;
    this.doorLoopOkLed = source.doorLoopOkLed;
    this.slDoorControlByPass = source.slDoorControlByPass;

    // Other
    this.contactorHalfLightList = source.contactorHalfLightList; // sil
    this.contactorEmergencyLightList = source.contactorEmergencyLightList; // sil
    this.interiorLightingModeList = source.interiorLightingModeList;
    this.toiletIsNotActive = source.toiletIsNotActive;
    this.toiletWaterLevelLow = source.toiletWaterLevelLow;
    this.toiletBowlFull = source.toiletBowlFull;
    this.toiletWastewaterTankFull = source.toiletWastewaterTankFull;
    this.toiletSosSignal = source.toiletSosSignal;

    // Signalization
    this.etcsIsolationByPass = source.etcsIsolationByPass;
    this.ertmsCutOff = source.ertmsCutOff;
    this.atsByPass = source.atsByPass;
    this.ertmsServiceBrake = source.ertmsServiceBrake;
    this.location = this.isLiveMode ? this.formattedLocation(source.location) : this.formattedLocation(source.location);
    this.ascLimit = source.ascLimit;

    // Unknown
    this.tractionBar = source.tractionBar;
    this.tractionCutOffLed = source.tractionCutOffLed;
    this.hmiJourneyNo = source.hmiJourneyNo;
    this.mediumVoltageBackupEnabled2Vehicles = source.mediumVoltageBackupEnabled2Vehicles;
    this.externalTemperature = source.externalTemperature;
    this.predictiveMaintenanceCode = source.predictiveMaintenanceCode;

    this.checkStatus();
    this.calculatePredictiveInfo();
    this.setDiagnosticSystem();
    this.loadAndModifySVG(this.currentSVGPath);
  }

  getRandomInt(min: number, max: number, errorMin = 0, errorMax = 0, isError = false): number {
    if (isError) {
      // If no error value has been generated yet or a random error has been generated
      const shouldReturnError = !this.hasProducedError || Math.random() < 0.1;

      if (shouldReturnError) {
        this.hasProducedError = true; // Signals that an incorrect value was generated
        return Math.floor(Math.random() * (errorMax - errorMin + 1)) + errorMin;
      }
    }

    // If the incorrect value has already been generated or a random correct value will be generated
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public refreshData() {
    if (!this.isLiveMode) {
      this.isLiveMode = 1;
      this.setData();
    }
  }

  public getFirstData() {
    if (this.isLiveMode) {
      this.isLiveMode = 0;
      this.setData();
    }
  }

  public setDiagnosticSystem() {
    if (this.tractionSystem || this.brakeSystem || this.powerVoltageSystem || this.otherSystem ||
      this.signalizationSystem || this.airConditionSystem || this.doorSystem) {
      this.diagnosticSystem = 1;
    } else {
      this.diagnosticSystem = 0;
    }
  }


  public controlTractionSystemStatus() {
    if (this.predictiveMaintenanceCode === 1 || this.predictiveMaintenanceCode === 2 ||
      this.predictiveMaintenanceCode === 3 || this.predictiveMaintenanceCode === 4 ||
      this.predictiveMaintenanceCode === 5 || this.predictiveMaintenanceCode === 6 ||
      this.predictiveMaintenanceCode === 7 || this.predictiveMaintenanceCode === 8) {
      this.tractionSystem = true;
    } else {
      this.tractionSystem = false;
    }
  }

  public controlBrakeSystemStatus() {
    if (this.status[2] === '-red ' || this.status[3] === '-red' || this.hasBrakeError()) {
      this.brakeSystem = true;
    } else {
      this.brakeSystem = false;
    }
  }

  public controlPowerVoltageSystemStatus() {
    if (this.powerVoltageSystemRules[this.yuksekAkimKaynakliVakumHatasi] === 1 || this.powerVoltageSystemRules[this.hvConfigError] === 1
      || this.powerVoltageSystemRules[this.tumPanolarIzole] === 1 || this.powerVoltageSystemRules[this.pantografDustu] === 1
      || this.powerVoltageSystemRules[this.diagPantoComp] === 1 || this.powerVoltageSystemRules[this.majorMvError] === 1
      || this.tractionTransformerOverPressure.includes(1) || this.measuringBatteryVoltage.some(value => value < 80)
      || this.tractionTransformerTemp.some(value => value > 110) || this.tempBattery.some(value => value > 40) || this.tempBattery.some(value => value < -10)
      || this.tempApsRec.includes(1) || this.tempApsInv.includes(1) || this.vApsDclink.some(value => value > 800)
      || this.tractionTransformerOilFlow.includes(1) || this.tractionTransformerOilLevel.includes(1)) {
      this.powerVoltageSystem = true;
    } else {
      this.powerVoltageSystem = false;
    }
  }

  public controlAirConditionSystemStatus() {
    if (this.driverMajorHvac.includes(1) || this.passengerMajorHvac1.includes(1) || this.passengerMajorHvac2.includes(1)) {
      this.airConditionSystem = true;
    } else {
      this.airConditionSystem = false;
    }
  }

  public controlDoorSystemStatus() {
    if (this.ickapi1SeviyeAriza === 1 || this.kapi1SeviyeAriza === 1 || this.prmAsansorKapi1Devrede === 1) {
      this.doorSystem = true;
    } else {
      this.doorSystem = false;
    }
  }

  public controlOthersSystemStatus() {
    if (this.toiletIsNotActive === 1 || this.toiletWaterLevelLow === 1 || this.toiletBowlFull === 1
      || this.toiletWastewaterTankFull === 1 || this.toiletSosSignal === 1) {
      this.otherSystem = true;
    } else {
      this.otherSystem = false;
    }
  }

  public controlSignalizationSystemStatus() {
    if (this.etcsIsolationByPass === 1 || this.ertmsCutOff === 0 || this.atsByPass === 1
      || this.ertmsServiceBrake === 1) {
      this.signalizationSystem = true;
    } else {
      this.signalizationSystem = false;
    }
  }

  public calculateDate(date) {
    const [datePart, timePart] = date.split('T');
    const cleanedTimePart = timePart.replace('Z', '');
    const [year, month, day] = datePart.split('-');
    const formattedDate = `${day}.${month}.${year} ${cleanedTimePart}`;
    return formattedDate;
  }

  public formattedLocation(location) {
    const [lat, lon] = location.split(', ');
    const formattedLat = parseFloat(lat).toFixed(3);
    const formattedLon = parseFloat(lon).toFixed(3);
    return `${formattedLat}, ${formattedLon}`;
  }
}
