import {BaseMetaModel} from './base/base-meta-model.model';
export class TrainDefinition extends BaseMetaModel {

  fDriveApplied: string;
  voltPantoSka1: string;
  hmiJourneyNo: string;
  location: string;
  speed: string;
  distanceTraveled: string;
  ascLimit: string;
  tractionBar: string;
  tractionCutOffLed: string;
  majorUnderVoltageError: string;
  mediumVoltageBackupEnabled2Vehicles: string;
  catenaryVoltage: string;
  pantoABar: string;
  pTrain: string;
  bp: string;
  mrp: string;
  edBar: any[];
  emergencyStatusBrakeLed: string;
  driverAirConditioningTemperature: string;
  externalTemperature: string;
  door1LevelFault: string;
  door2LevelFault: string;
  doorLoopOkLed: string;
  predictiveMaintenanceCode: string;
  tempTrcInv1: any[];
  tempTrcInv2: any[];
  tempTrcRec: any[];
  tempApsRec: any[];
  tempApsInv: any[];
  tempMotorAx3: any[];
  tempMotorAx4: any[];
  tractionTransformerTemp: any[];
  tractionDcLinkOk: any[];
  vTrcDclink: any[];
  ApsDcLinkOk: any[];
  vApsDclink: any[];
  forceAvmaxAx3: any[];
  forceAvmaxAx4: any[];
  velMotorAx3: any[];
  velMotorAx4: any[];
  lcuMaintenance1: any[];
  motorCooling2mvcbProtection: string;
  fDriveForceAx3: any;
  fDriveForceAx4: any;
  requiredTractionForce: any;
  forceAppliedAx3: any;
  forceAppliedAx4: any;
  appliedTractionForce: any;
  cutOffTraction: any;
  cutOffByPass: any;
  tractionNull: any;
  pressureGovernorPBReleased: any;
  pressureGovernorDetectionEmergencyBrake: any;
  pressureGovernorIndirectBrakeControl: any;
  diagBrDirectList: any[];
  majorBrParkList: any[];
  majorBrWsp: any[];
  ebcu: any[];
  diagBrCPress: any[];
  diagBrDirect: any;
  majorBrPark: any;
  yuksekAkimKaynakliVakumHatasi: any;
  hvConfigError: any;
  tumPanolarIzole: any;
  pantografDustu: any;
  diagPantoComp: any;
  majorMvError: any;
  vcbCloseSka1: any;
  vcbCloseSka2: any;
  pressurePs1SafeguardAddSka1: any;
  pressurePs1SafeguardAddSka2: any;
  hvDisconnectorCloseSKA1: any;
  hvDisconnectorCloseSKA2: any;
  hvDisconnectorCloseOA2: any;
  tractionTransformerOilFlow: any[];
  tractionTransformerOilLevel: any[];
  tractionTransformerOverPressure: any[];
  measuringBatteryVoltage: any[];
  tempBattery: any[];
  aBatteryCharger: any[];
  passengerAirConditioningMode: any;
  hvacsInhibitionList: any[];
  driverMajorHvac: any[];
  passengerMajorHvac1: any[];
  passengerMajorHvac2: any[];
  driverMinorHvac: any[];
  passengerMinorHvac1: any[];
  passengerMinorHvac2: any[];
  ickapi1SeviyeAriza: any;
  kapi1SeviyeAriza: any;
  prmAsansorKapi1Devrede: any;
  slDoorControlByPass: any;
  contactorHalfLightList: any[];
  contactorEmergencyLightList: any[];
  interiorLightingModeList: any[];
  toiletIsNotActive: any;
  toiletWaterLevelLow: any;
  toiletBowlFull: any;
  toiletWastewaterTankFull: any;
  toiletSosSignal: any;
  etcsIsolationByPass: any;
  ertmsCutOff: any;
  atsByPass: any;
  ertmsServiceBrake: any;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.fDriveApplied = data.fDriveApplied;
    this.voltPantoSka1 = data.voltPantoSka1;
    this.hmiJourneyNo = data.hmiJourneyNo;
    this.location = data.location;
    this.speed = data.speed;
    this.distanceTraveled = data.distanceTraveled;
    this.ascLimit = data.ascLimit;
    this.tractionBar = data.tractionBar;
    this.tractionCutOffLed = data.tractionCutOffLed;
    this.majorUnderVoltageError = data.majorUnderVoltageError;
    this.mediumVoltageBackupEnabled2Vehicles = data.mediumVoltageBackupEnabled2Vehicles;
    this.catenaryVoltage = data.catenaryVoltage;
    this.pantoABar = data.pantoABar;
    this.pTrain = data.pTrain;
    this.bp = data.bp;
    this.mrp = data.mrp;
    this.edBar = data.edBar;
    this.emergencyStatusBrakeLed = data.emergencyStatusBrakeLed;
    this.driverAirConditioningTemperature = data.driverAirConditioningTemperature;
    this.externalTemperature = data.externalTemperature;
    this.door1LevelFault = data.door1LevelFault;
    this.door2LevelFault = data.door2LevelFault;
    this.doorLoopOkLed = data.doorLoopOkLed;
    this.predictiveMaintenanceCode = data.predictiveMaintenanceCode;
    this.tempTrcInv1 = data.tempTrcInv1;
    this.tempTrcInv2 = data.tempTrcInv2;
    this.tempTrcRec = data.tempTrcRec;
    this.tempApsRec = data.tempApsRec;
    this.tempApsInv = data.tempApsInv;
    this.tempMotorAx3 = data.tempMotorAx3;
    this.tempMotorAx4 = data.tempMotorAx4;
    this.tractionTransformerTemp = data.tractionTransformerTemp;
    this.tractionDcLinkOk = data.tractionDcLinkOk;
    this.vTrcDclink = data.vTrcDclink;
    this.ApsDcLinkOk = data.ApsDcLinkOk;
    this.vApsDclink = data.vApsDclink;
    this.forceAvmaxAx3 = data.forceAvmaxAx3;
    this.forceAvmaxAx4 = data.forceAvmaxAx4;
    this.velMotorAx3 = data.velMotorAx3;
    this.velMotorAx4 = data.velMotorAx4;
    this.lcuMaintenance1 = data.lcuMaintenance1;
    this.motorCooling2mvcbProtection = data.motorCooling2mvcbProtection;
    this.fDriveForceAx3 = data.fDriveForceAx3;
    this.fDriveForceAx4 = data.fDriveForceAx4;
    this.forceAppliedAx3 = data.forceAppliedAx3;
    this.forceAppliedAx4 = data.forceAppliedAx4;
    this.cutOffTraction = data.cutOffTraction;
    this.cutOffByPass = data.cutOffByPass;
    this.tractionNull = data.tractionNull;
    this.pressureGovernorPBReleased = data.pressureGovernorPBReleased;
    this.pressureGovernorDetectionEmergencyBrake = data.pressureGovernorDetectionEmergencyBrake;
    this.pressureGovernorIndirectBrakeControl = data.pressureGovernorIndirectBrakeControl;
    this.diagBrDirectList = data.diagBrDirectList;
    this.majorBrParkList = data.majorBrParkList;
    this.majorBrWsp = data.majorBrWsp;
    this.ebcu = data.ebcu;
    this.diagBrCPress = data.diagBrCPress;
    this.diagBrDirect = data.diagBrDirect;
    this.majorBrPark = data.majorBrPark;
    this.yuksekAkimKaynakliVakumHatasi = data.yuksekAkimKaynakliVakumHatasi;
    this.hvConfigError = data.hvConfigError;
    this.tumPanolarIzole = data.tumPanolarIzole;
    this.pantografDustu = data.pantografDustu;
    this.diagPantoComp = data.diagPantoComp;
    this.majorMvError = data.majorMvError;
    this.vcbCloseSka1 = data.vcbCloseSka1;
    this.vcbCloseSka2 = data.vcbCloseSka2;
    this.pressurePs1SafeguardAddSka1 = data.pressurePs1SafeguardAddSka1;
    this.pressurePs1SafeguardAddSka2 = data.pressurePs1SafeguardAddSka2;
    this.hvDisconnectorCloseSKA1 = data.hvDisconnectorCloseSKA1;
    this.hvDisconnectorCloseSKA2 = data.hvDisconnectorCloseSKA2;
    this.hvDisconnectorCloseOA2 = data.hvDisconnectorCloseOA2;
    this.tractionTransformerOilFlow = data.tractionTransformerOilFlow;
    this.tractionTransformerOilLevel = data.tractionTransformerOilLevel;
    this.tractionTransformerOverPressure = data.tractionTransformerOverPressure;
    this.measuringBatteryVoltage = data.measuringBatteryVoltage;
    this.tempBattery = data.tempBattery;
    this.aBatteryCharger = data.aBatteryCharger;
    this.passengerAirConditioningMode = data.passengerAirConditioningMode;
    this.hvacsInhibitionList = data.hvacsInhibitionList;
    this.driverMajorHvac = data.driverMajorHvac;
    this.passengerMajorHvac1 = data.passengerMajorHvac1;
    this.passengerMajorHvac2 = data.passengerMajorHvac2;
    this.driverMinorHvac = data.driverMinorHvac;
    this.passengerMinorHvac1 = data.passengerMinorHvac1;
    this.passengerMinorHvac2 = data.passengerMinorHvac2;
    this.ickapi1SeviyeAriza = data.ickapi1SeviyeAriza;
    this.kapi1SeviyeAriza = data.kapi1SeviyeAriza;
    this.prmAsansorKapi1Devrede = data.prmAsansorKapi1Devrede;
    this.slDoorControlByPass = data.slDoorControlByPass;
    this.contactorHalfLightList = data.contactorHalfLightList;
    this.contactorEmergencyLightList = data.contactorEmergencyLightList;
    this.interiorLightingModeList = data.interiorLightingModeList;
    this.toiletIsNotActive = data.toiletIsNotActive;
    this.toiletWaterLevelLow = data.toiletWaterLevelLow;
    this.toiletBowlFull = data.toiletBowlFull;
    this.toiletWastewaterTankFull = data.toiletWastewaterTankFull;
    this.toiletSosSignal = data.toiletSosSignal;
    this.etcsIsolationByPass = data.etcsIsolationByPass;
    this.ertmsCutOff = data.ertmsCutOff;
    this.atsByPass = data.atsByPass;
    this.ertmsServiceBrake = data.ertmsServiceBrake;
    this.requiredTractionForce = data.requiredTractionForce;
    this.appliedTractionForce = data.appliedTractionForce;
  }
}
