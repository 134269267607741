import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {ChartType} from '../../../enums/chart-type.enum';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';
import {ColorUtil} from '../../../utils/color-util';
import {DataFormatUtil} from '../../../utils/data-format-util';

@Component({
  selector: 'uruk-pie-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class PieChartComponent extends ChartComponent implements OnInit {

  // Type of the chart: either pie or doughnut. Default is 'pie'
  @Input() type: 'pie' | 'doughnut' = 'pie';

  // Radius of the pie/doughnut chart
  @Input() radius: string | string[];

  // Determines whether to display the labels around the pie chart
  @Input() displayLabels;

  // responsiveness parameters
  baseFontSize = 9;
  baseDimension = 180;
  private fontScaleFactor = 20; // a scale factor to determine the responsive font size depending on the dimension
  private doughnutInnerRadiusScaleFactor = 1.75; // a scale factor to determine the responsive inner radius of doughnut charts
  private tooltipFontScaleFactor = 1.25; // a scale factor to determine the responsive font size for tooltips

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    const defaultOptions: any = {
      color: this.colors || ColorUtil.colorPalette,
      series: [{
        type: 'pie',
        name: this.seriesName || this.defaultSeriesName,
        label: {
          formatter: this.labelFormatter.bind(this),
          // formatter: '{b}\n({c})',
          align: 'center',
          color: '#FFFFFF'
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: this.tooltipFormatter.bind(this)
        },
      }]
    };

    // set the data if the chart is initialized with some data
    if (this.data) {
      defaultOptions.series[0].data = this.data;
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    const viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);
    const responsiveFontSize = this.baseFontSize + (viewportMin - this.baseDimension) / this.fontScaleFactor;
    const responsiveLabelVisibility = viewportMin < this.baseDimension ? false : true;

    let responsiveRadius;
    if (viewportMin < this.baseDimension) {
      responsiveRadius = this.type === ChartType.Doughnut ? ['40%', '80%'] : '80%';
    } else {/*if (this.baseDimension <= viewportMin && viewportMin < 195) {
      responsiveRadius = 20;
    } else*/
      const radius = 0.0956 * viewportMin + 24.262; // calculated with linear regression of the best values for specific viewportMins
      responsiveRadius = this.type === ChartType.Doughnut ? [(radius / this.doughnutInnerRadiusScaleFactor) + '%', radius + '%'] : radius + '%';
    }

    const responsiveOptions: any = {
      series: [{
        radius: this.radius || responsiveRadius,
        label: {
          show: this.displayLabels || responsiveLabelVisibility,
          fontSize: responsiveFontSize
        },
      }],
      tooltip: {
        textStyle: {
          fontSize: responsiveFontSize * this.tooltipFontScaleFactor
        }
      },
    };

    return responsiveOptions;
  }

  /**
   * A label formatter method to format pie chart labels
   * @param params
   */
  labelFormatter(params) {
    let label = '';

    if (this.chartSettings.seriesSettings.length > 0) {
      const chartSeries = this.chartSettings.seriesSettings[0];
      const kpiSeries = this.kpi.series[chartSeries.index];

      // set value
      const value = DataFormatUtil.formatValue(params.value, kpiSeries.format, chartSeries.precision, chartSeries.thousandSeparator);

      // set label
      label += `${params.name}\n(${value})`;
    }

    return label;
  }

  setData(data: NGSIResult[]) {
    this.rawData = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);

    // TODO: handle multidimensional/multiseries charts
    let dimensionData, seriesData;
    this.rawData.forEach(axis => {
      if (axis.dimension) {
        dimensionData = axis;
      } else if (axis.series) {
        seriesData = axis;
      }
    });

    this.seriesName = seriesData.series.label;
    this.data = seriesData.data.map((value, index) => {
      return {
        name: dimensionData.data[index],
        value: value
      };
    });

    this.setSeriesData(this.data);
  }
}
