/**
 * Represents the user on the platform.
 */
export class User {
  /**
   * Unique user identifier assigned to the user
   */
  id: string;

  /**
   * Username of the user
   */
  username: string;

  /**
   * First name of the user
   */
  firstName?: string;

  /**
   * Last name of the user
   */
  lastName?: string;

  /**
   * If user is enabled
   * */
  enabled: boolean;

  /**
   * Role assigned to the user
   * */
  roles: string[] = [];

  /**
   * Company id of the user
   */
  companyId?: number;


  /**
   * Company name of the user
   */
  companyName?: string;


  /**
   * Phone Number of the user
   */
  phoneNumber?: string;

  /**
   * Domain user is assigned to
   * */
  domains: string[] = [];

  /**
   * Creation date
   * */
  creationDate: Date;

  // UI-specific fields //

  /**
   * Name of the user.
   * */
  name: string;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.username = data.username;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.enabled = data.enabled;
    this.roles = data.roles;
    this.companyId = data.attributes['companyId'] ? data.attributes['companyId'] : "";
    this.companyName = data.attributes['companyName'] ? data.attributes['companyName'] : "";
    this.phoneNumber = data.attributes['phoneNumber'] ? data.attributes['phoneNumber'] : "";
    this.domains = data.domains;
    this.name = this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.username;
    if (data.createdTimestamp || data.creationDate) {
      this.creationDate = new Date(data.createdTimestamp ? data.createdTimestamp : data.creationDate);
    }
  }

  getName() {
    return (this.firstName || '') + ' ' + (this.lastName || '')
  }
}
