import {MenuIcon} from './menu-icon';
import {StringUtil} from '../../utils/string-util';

/**
 * Menu item
 * */
export class MenuItem {
  /**
   * Unique identifier of menu item
   * */
  public id: string = null;
  /**
   * Name of menu item
   * */
  public title: string = null;
  /**
   * Icon of menu item
   * */
  public icon?: MenuIcon = null;
  /**
   * Item relative link for routerLink
   * */
  public link?: string = null;
  /**
   * URL of the page that should be navigated by this menu item
   */
  public url?: string = null;
  /**
   * Children items
   * */
  public children?: MenuItem[] = [];
  /**
   * Function to call when menu item is clicked
   * */
  public callbackFunction?: any;
  /** 
   * Whether item is draggable or not
   * */
  public dragDisabled: any = false;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    // generate a random id
    this.id = StringUtil.generateRandomString(24);
    this.title = data.title;
    this.link = data.link;
    this.url = data.url;
    this.dragDisabled = data.dragDisabled;
    this.callbackFunction = data.callbackFunction;
    if (data.icon) {
      this.icon = new MenuIcon(data.icon);
    }
    if (data.children) {
      this.children = data.children.map(menuItem => new MenuItem(menuItem));
    }
  }
}
