import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Layer} from '../../models/layer.model';
import {BaseComponent} from '../base.component';
import {LayerService} from '../../../core/services/meta/layer.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {TagTypeUtil} from '../../enums/tag-type.enum';
import {SearchQuery} from '../../models/query/search-query.model';

// layers excluded from selection
const EXCLUDED_LAYERS = ['Alert'];

/**
 * Displays the available layers for selection.
 * */
@Component({
  selector: 'uruk-layer-selection',
  templateUrl: './layer-selection.component.html',
  styleUrls: ['./layer-selection.component.scss']
})
export class LayerSelectionComponent extends BaseComponent implements OnInit {

  // the list of domains for layers
  _domains: string[];

  get domains(): string[] {
    return this._domains;
  }

  @Input() set domains(domains: string[]) {
    // retrieve domain codes
    this._domains = domains.map(domain => TagTypeUtil.extractTagCode(domain));
    // emit the event
    this.searchLayers.next();
  }
  // the list of layers which are already selected
  @Input() layerIds: string[] = [];
  // emits the selected layers
  @Output() onLayerSelection: EventEmitter<string[]> = new EventEmitter<string[]>();
  // selected layers
  selectedLayerIds: string[];
  // the list of available layers retrieved from the server
  layers: Layer[] = [];
  public layerService: LayerService;
  // Subscription object for domain selection
  private searchLayers = new Subject<void>();

  constructor(protected injector: Injector) {
    super(injector);
    this.layerService = injector.get(LayerService);
    // subscribe to domain selection events
    this.handleSubscriptionForSearching();
  }

  ngOnInit() {
    // initialize selected layer ids
    this.selectedLayerIds = [].concat(this.layerIds);
  }

  /**
   * Checks whether the layer is selected
   * @param layerId the layer id
   * */
  isLayerSelected(layerId: string) {
    return this.selectedLayerIds.includes(layerId);
  }

  /**
   * Handles the click on layer. It marks the given layer as selected if it is not selected. Otherwise, it unselects it.
   * @param layerId the layer id
   * */
  public onLayerClicked(layerId: string) {
    const index = this.selectedLayerIds.indexOf(layerId);
    if (index === -1) {
      this.selectedLayerIds.push(layerId);
    } else {
      this.selectedLayerIds.splice(index, 1);
    }
    this.onLayerSelection.emit(this.selectedLayerIds);
  }

  /**
   * Subscribes to selection events when user wants to filter layer list by selecting some domains
   */
  private handleSubscriptionForSearching() {
    this.searchLayers.pipe(debounceTime(environment.timeouts.debounceTimes.searchQueries)).subscribe(_ => {
      this.getLayers();
    });
  }

  /**
   * Retrieves the available layers for the given domains.
   * */
  private getLayers() {
    const searchQuery: SearchQuery = new SearchQuery();
    searchQuery.domains = this.domains;
    this.layerService.getLayers(searchQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe(layers => {
        this.layers = layers.filter(layer => !EXCLUDED_LAYERS.includes(layer.name));
        // validate selected layers
        this.validateSelectedLayers();
      });
  }

  /**
   * Validates the selected layers by discarding the ones which are not available in the current layer list.
   * */
  private validateSelectedLayers() {
    // filter out the layers which are not available anymore
    this.selectedLayerIds = this.selectedLayerIds.filter(layerId => this.layers.findIndex(layer => layer.id === layerId) !== -1);
    // emit the selected layers
    this.onLayerSelection.emit(this.selectedLayerIds);
  }

}
