import {AfterViewInit, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {TimeUtil} from '../../../../../shared/utils/time-util';
import {MvbCommunication} from '../../../../../shared/models/mvbCommunication.model';
import {CanCommunication} from '../../../../../shared/models/canCommunication.model';
import {NGSIEventType, Notification} from '../../../../../shared/models/notification.model';
import {TrainNotification} from '../../../../../shared/models/trainNotification.model';
import {TrainDefinition} from '../../../../../shared/models/trainDefinition.model';

const ASELSAN_TRAIN_SOURCE_URL = 'http://aselsan.com.tr';

@Component({
  selector: 'train-dialog-template',
  templateUrl: './train-dialog-template.component.html',
  styleUrls: ['./train-dialog-template.component.scss']
})
export class TrainDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

  // Context variables
  entity: any;
  positionEntity: any;
  hmiVehicleNo: any;
  location: any;
  fDriveApplied: any[] = [];
  pressBp: any;
  pressMr: any;
  vAscReq: any;
  voltPantoSka1: any[] = [];
  hmiJourneyNo: any;
  dotSeparatedDate: string;
  date: any;
  trainId: any;
  isAselsanTrain = undefined;
  trainRef: any;
  status: string[] = ['', '', '', '', '', '', '', '', '', '', ''];
  speed: any;
  distanceTraveled: any[] = [];
  ascLimit: any;
  tractionBar: any;
  tractionCutOffLed: any;
  majorUnderVoltageError: any; // power voltage 1
  mediumVoltageBackupEnabled2Vehicles: any;
  catenaryVoltage: any; // power voltage 1
  pantoABar: any; // power voltage 1
  pTrain: any; // power voltage 1
  vcbCloseSkax: any; // power voltage 1
  pressurePs1SafeguardAddSkax: any; // power voltage 1
  majorMvError: any; // power voltage 1
  hvDisconnectorCloseSKA1: any; // power voltage 1
  hvDisconnectorCloseSKA2: any; // power voltage 1
  hvDisconnectorCloseOA2: any; // power voltage 1
  tractionTransformerOilFlow: any [] = []; // power voltage 2
  tractionTransformerOilLevel: any [] = []; // power voltage 2
  tractionTransformerOverPressure: any [] = []; // power voltage 2
  tractionTransformerTemp: any[] = []; // power voltage 2
  bp: any; // brake 1
  mrp: any; // brake 1
  pressureGovernorPBReleased: any; // brake 1
  pressureGovernorDetectionEmergencyBrake: any; // brake 1
  pressureGovernorIndirectBrakeControl: any; // brake 1
  diagBrDirect: any; // brake 1
  majorBrPark: any; // brake 1
  edBar: any[] = []; // traction 3
  emergencyStatusBrakeLed: any[] = [];
  driverAirConditioningTemperature: any; // air condition 1
  passengerAirConditioningMode: any; // air condition 1
  externalTemperature: any;
  door1LevelFault: any; // door 1
  door2LevelFault: any; // door 1
  doorLoopOkLed: any; // door 1
  slDoorControlByPass: any; // door 1
  hasError: boolean = false;
  activeTab: string = '';
  errorMap: string[] = ['Yok', 'Var'];
  diagComp1: any;
  diagComp2: any;
  kismiPnomatikFrenKaybi: any;
  erisilebilirKompresorYok: any;
  enAz1EbcuHatali: any;
  mrDusuk: any;
  frenUygulamaHatasi: any[] = []; // brake 2
  frenBirakmaHatasi: any;
  diagBrCPress: any[] = []; // brake 2
  havaSuspansiyonHatas: any;
  majorBrWsp: any[] = []; // brake 2
  kumlamaHatasi: any;
  parkFreniHatasi: any[] = []; // brake 2
  birdenFazlaVakumDevrede: any; // power voltage
  tumPanolarIzole: any; // power voltage 1
  birdenFazlaPantoKaldirilamaz: any;
  pantografDustu: any; // power voltage 1
  yuksekAkimKaynakliVakumHatasi: any; // power voltage 1
  diagPantoComp: any; // power voltage 1
  hvConfigError: any; // power voltage 1
  ortaGerilimKontaktorKontrolHatasi: any;
  ortaGerilimHatasiKabinDegisikligi: any;
  cutOffDongusuBaypasEdilmistir: any;
  siviSogutmaBakim: any;
  siviSogutmaAcilBakim: any;
  minorAriza: any;
  majorAriza: any;
  agirAriza: any;
  ickapi1SeviyeAriza: any; // door 1
  ickapi2SeviyeAriza: any;
  kapi1SeviyeAriza: any; // door 1
  kapi2SeviyeAriza: any;
  prmAsansorKapi1Devrede: any; // door 1
  prmAsansorKapi2Devrede: any;
  driverMajorHvac: any[] = []; // air condition 2
  passengerMajorHvac1: any[] = []; // air condition 3
  passengerMajorHvac2: any[] = []; // air condition 4
  driverMinorHvac: any[] = []; // air condition 2
  passengerMinorHvac1: any[] = []; // air condition 3
  passengerMinorHvac2: any[] = []; // air condition 4
  hvacsInhibition_X: any; // air condition 1
  tkysHaberlesmeHatasi: any;
  tkysFrenHaberlesmeHatasi: any;
  tkysCerHaberlesmeHatasi: any;
  wtbHaberlesmeHatasi: any;
  predictiveMaintenanceCode: any;
  predictiveMaintenanceInfo: any;
  mvbComList: MvbCommunication[] = [];
  camComList: CanCommunication[] = [];
  trainNotificationList: TrainNotification[] = [];
  tempTrcInv1: any[] = []; // traction 2
  tempTrcInv2: any[] = []; // traction 2
  tempTrcRec: any[] = []; // traction 2
  tempApsRec: any[] = []; // power voltage 3
  tempApsInv: any[] = []; // power voltage 3
  tempMotorAx3: any[] = []; // traction 3
  tempMotorAx4: any[] = []; // traction 3
  tractionDcLinkOk: any[] = [];
  vTrcDclink: any[] = []; // traction 2
  ApsDcLinkOk: any[] = [];
  vApsDclink: any[] = []; // power voltage 3
  measuringBatteryVoltage: any[] = []; // power voltage 4
  tempBattery: any[] = []; // power voltage 4
  aBatteryCharger: any[] = []; // power voltage 4
  forceAvmaxAx3: any[] = []; // traction 3
  forceAvmaxAx4: any[] = []; // traction 3
  velMotorAx3: any[] = []; // traction 3
  velMotorAx4: any[] = []; // traction 3
  lcuMaintenance1: any[] = [];
  requiredTractionForce: any; // traction 1
  appliedTractionForce: any; // traction 1
  fDriveForceAx3: any; // traction 1
  fDriveForceAx4: any; // traction 1
  forceAppliedAx3: any; // traction 1
  forceAppliedAx4: any; // traction 1
  cutOffTraction: any; // traction 1
  cutOffByPass: any; // traction 1
  tractionNull: any; // traction 1
  etcsIsolationByPass: any; // signalization 1
  ertmsCutOff: any; // signalization 1
  atsByPass: any; // signalization 1
  ertmsServiceBrake: any; // signalization 1
  interiorLightingMode: any;
  contactorLightOn: any;
  contactorHalfLight: any;
  contactorEmergencyLight: any;
  toiletIsNotActive: any; // other 1
  toiletWaterLevelLow: any; // other 1
  toiletBowlFull: any; // other 1
  toiletWastewaterTankFull: any; // other 1
  toiletSosSignal: any; // other 1
  notificationCount: number = 1;
  isLiveMode: number = 0;
  firstTrainData: TrainDefinition;
  liveTrainData: TrainDefinition;
  tractionSystem: any = false;
  brakeSystem: any = false;
  powerVoltageSystem: any = false;
  airConditionSystem: any = false;
  doorSystem: any = false;
  otherSystem: any = false;
  communicationSystem: any = false;
  signalizationSystem: any = false;
  currentSVGPath: string | null = null;
  previousSVGPath: string | null = null;
  defaultSVGPath: string = '';
  selectedWagonId: string = '';
  selectedWagonIndex: number = 1;
  tractionTabInfo: number = 1;
  brakeTabInfo: number = 1;
  powerVoltageTabInfo: number = 1;
  airConditionTabInfo: number = 1;
  doorTabInfo: number = 1;
  otherTabInfo: number = 1;
  communicationTabInfo: number = 1;
  signalizationTabInfo: number = 1;
  redColor = '#FF6B6B';
  greenColor = '#31C048';
  blueColor = '#4D96FF';
  orangeColor = '#FFA556';
  whiteColor = '#FFFFFF';

  boolMap = new Map<number, string>([
    [0, 'Yok'],
    [1, 'Var'],
  ]);

  // Define SVG file paths
  svgPaths = {
    'tractionSystem': 'assets/images/train-traction.svg',
    'brakeSystem': 'assets/images/train-brake.svg',
    'powerVoltageSystem': 'assets/images/train-powervoltage.svg',
    'airConditionSystem': 'assets/images/train-aircondition.svg',
    'doorSystem': 'assets/images/train-door.svg',
    'otherSystem': 'assets/images/train-other.svg',
    'communicationSystem': 'assets/images/train-other.svg', // communication system image eksik
    'signalizationSystem': 'assets/images/train-signalization.svg',
  };

  wagonIndex = {
    'ska1': 0,
    'oa1': 1,
    'oa2': 2,
    'oa3': 3,
    'ska2': 4
  };

  constructor(injector: Injector) {
    super(injector);

    this.activeTab = 'tractionSystem'; // Default active tab value
    this.defaultSVGPath = 'assets/images/train-traction.svg'; // Default svg path
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeWebsocket();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onTabChange(event) {
    // Reset the tab information to default values when a tab change event occurs
    this.setDefaultTabInfo();

    // Set the activeTab variable to the ID of the selected tab
    this.activeTab = event.tabId;

    // Load the corresponding SVG and modify it based on the selected tab
    this.loadAndModifySVG(this.getSvgPath(), true);
  }

  private setDefaultTabInfo() {
    // Set default values for all system information related to the tabs
    this.selectedWagonId = 'ska1';
    this.tractionTabInfo = 1;
    this.brakeTabInfo = 1;
    this.powerVoltageTabInfo = 1;
    this.airConditionTabInfo = 1;
    this.doorTabInfo = 1;
    this.otherTabInfo = 1;
    this.communicationTabInfo = 1;
    this.signalizationTabInfo = 1;
  }

  private getSvgPath(): string {
    // Return the SVG path corresponding to the active tab, or the default path if none is found
    return this.svgPaths[this.activeTab] || this.defaultSVGPath;
  }

  private getWagonId(element: SVGGraphicsElement): string {
    // Find the parent 'g' element of the clicked SVG element and return its ID
    const parentG = element.parentElement?.closest('g');
    if (parentG && parentG.id) {
      return parentG.id;
    } else {
      console.log('No ID or no parent g element found');
    }
  }

  private loadAndModifySVG(svgPath: string, resetFlags: boolean = false) {
    // Store the previous SVG path and load the new one
    this.previousSVGPath = this.currentSVGPath;
    this.currentSVGPath = svgPath;

    // Optionally reset the previousSVGPath if resetFlags is true
    if (resetFlags) {
      this.previousSVGPath = null;
    }

    // Fetch the SVG file from the given path
    fetch(svgPath)
      .then(response => response.text())
      .then(data => this.handleSVGData(data))
      .catch(error => console.error('An error occurred while loading the SVG: ', error));
  }

  private handleSVGData(data: string) {
    // Get the container to load the SVG and the back icon element
    const svgContainer = document.getElementById('svgContainer');
    const backIcon = document.querySelector('.back-icon') as HTMLElement;

    // If the container is found, load the SVG and modify it
    if (svgContainer) {
      svgContainer.innerHTML = data;
      this.updateBackIconVisibility(backIcon); // Show or hide the back icon
      this.modifyGroups(svgContainer);        // Modify 'g' elements in the SVG
      this.modifyPaths(svgContainer);         // Modify 'path' elements in the SVG
      this.modifyRects(svgContainer);         // Modify 'rect' elements in the SVG
      this.modifyTexts(svgContainer);         // Modify 'text' elements in the SVG
    }
  }

  // Show the back icon if there is a previous SVG loaded
  private updateBackIconVisibility(backIcon: HTMLElement) {
    if (this.previousSVGPath) {
      backIcon.style.display = 'block'; // Make the back icon visible
    } else {
      backIcon.style.display = 'none'; // Hide the back icon initially
    }
  }

  public goBack() {
    // Go back to the previous SVG if it exists, and reset tab information
    if (this.previousSVGPath) {
      this.setDefaultTabInfo();

      this.currentSVGPath = null;
      this.loadAndModifySVG(this.previousSVGPath);
    }
  }

  private modifyGroups(svgContainer: HTMLElement) {
    // Get all 'g' elements inside the SVG container
    const groups = svgContainer.querySelectorAll('g');

    // Iterate through the groups and modify each one that has the class 'unit'
    groups.forEach(group => {
      if (group.classList.contains('unit')) {
        this.handleModifyGroups(group);  // Handle group-specific modifications

        group.style.cursor = 'pointer';  // Set the cursor to pointer for clickable groups
        group.addEventListener('click', () => {
          const selectedPath: string = this.handleGroupsClick(group);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }

          console.log('Group clicked:', group);
        });
      }
    });
  }

  private handleModifyGroups(g: SVGGElement) {
    // Define handlers for modifying groups based on the active tab
    const tabHandlers: { [key: string]: (g: SVGGElement) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the group
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(g);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (modify group): ${this.activeTab}`);
    return '';
  }

  private handleGroupsClick(g: SVGGElement): string {
    // Define handlers for group click events based on the active tab
    const tabHandlers: { [key: string]: (id: string) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked group
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(g)] | 0;
      return handler(g.id);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (click group): ${this.activeTab}`);
    return '';
  }

  private modifyPaths(svgContainer: HTMLElement) {
    // Get all 'path' elements inside the SVG container
    const paths = svgContainer.querySelectorAll('path');

    // Iterate through the paths and modify each one that has the class 'unit'
    paths.forEach(path => {
      if (path.classList.contains('unit')) {
        this.handleModifyPaths(path);  // Handle path-specific modifications

        path.style.cursor = 'pointer';  // Set the cursor to pointer for clickable paths
        path.addEventListener('click', () => {
          const selectedPath: string = this.handlePathsClick(path);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }
          console.log('Path clicked:', path);
        });
      }
    });
  }

  private handleModifyPaths(path: SVGPathElement) {
    // Define handlers for modifying paths based on the active tab
    const tabHandlers: { [key: string]: (path: SVGPathElement) => string } = {
      powerVoltageSystem: this.modifyPathsForPowerVoltageSystem.bind(this),
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the path
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(path);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (modify path): ${this.activeTab}`);
    return '';
  }

  private modifyPathsForPowerVoltageSystem(path: SVGPathElement) {
    // Get the wagon index for the clicked path
    const index = this.wagonIndex[this.getWagonId(path)] | 0;

    if (path.id.includes('apu')) {

      if (this.tempApsInv[index] === 1) {
        path.setAttribute('stroke', this.redColor);  // Set stroke to red for error state
      }

      if (this.tempApsRec[index] === 1) {
        path.setAttribute('stroke', this.redColor);  // Set stroke to red for error state
      }

      if (this.vApsDclink[index] > 800) {
        path.setAttribute('stroke', this.redColor);  // Set stroke to red for error state
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private handlePathsClick(path: SVGPathElement): string {
    const tabHandlers: { [key: string]: (id: string) => string } = {
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked path
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(path)] | 0;
      return handler(path.id);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (click path): ${this.activeTab}`);
    return '';
  }

  private modifyRects(svgContainer: HTMLElement) {
    // Get all 'rect' elements inside the SVG container
    const rects = svgContainer.querySelectorAll('rect');

    // Iterate through the rects and modify each one that has the class 'unit'
    rects.forEach(rect => {
      if (rect.classList.contains('unit')) {
        this.handleModifyRects(rect);  // Handle rect-specific modifications

        rect.style.cursor = 'pointer';  // Set the cursor to pointer for clickable rects
        rect.addEventListener('click', () => {
          const selectedPath: string = this.handleRectsClick(rect);  // Handle click event
          if (selectedPath) {
            this.loadAndModifySVG(selectedPath);  // Load the new SVG on click
          }
          console.log('Rect clicked:', rect);
        });
      }
    });
  }

  private handleModifyRects(rect: SVGRectElement) {
    // Define handlers for modifying rects based on the active tab
    const tabHandlers: { [key: string]: (rect: SVGRectElement) => string } = {
      tractionSystem: this.modifyRectsForTractionSystem.bind(this),
      brakeSystem: this.modifyRectsForBrakeSystem.bind(this),
      powerVoltageSystem: this.modifyRectsForPowerVoltageSystem.bind(this),
      airConditionSystem: this.modifyRectsForAirConditionSystem.bind(this),
      doorSystem: this.modifyRectsForDoorSystem.bind(this)
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab, if it exists, and apply it to the rect
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      return handler(rect);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (modify rect): ${this.activeTab}`);
    return '';
  }

  private modifyRectsForTractionSystem(rect: SVGRectElement) {
    // Get the wagon index for the clicked rect
    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('cer')) {

      if (this.tempTrcInv1[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tempTrcInv2[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tempTrcRec[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.vTrcDclink[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('bogi')) {

      if (this.tempMotorAx3[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tempMotorAx4[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.forceAvmaxAx3[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.forceAvmaxAx4[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.velMotorAx3[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.velMotorAx4[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.edBar[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForBrakeSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('brake')) {

      if (this.majorBrWsp[index] < 0) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.diagBrCPress[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForPowerVoltageSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('transformer')) {
      if (this.tractionTransformerTemp[index] >= 55 && this.tractionTransformerTemp[index] <= 110) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.tractionTransformerTemp[index] > 110) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOilFlow[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOilLevel[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }

      if (this.tractionTransformerOverPressure[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  /*
  if (rect.id.includes('apu')) {
      const parentG = rect.parentElement?.closest('g');

      // Get all 'path' elements within the group and apply modifications
      const pathsInGroup = parentG.querySelectorAll('path');
      pathsInGroup.forEach(path => {
        if (rect.classList.contains('apu')) {
          this.handleModifyPaths(path);  // Handle individual path modifications
        }
      });
    }
   */

  private modifyRectsForAirConditionSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('driver-aircondition')) {

      if (this.driverMinorHvac[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.driverMajorHvac[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('passenger-aircondition1')) {
      if (this.passengerMinorHvac1[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.passengerMajorHvac1[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else if (rect.id.includes('passenger-aircondition2')) {
      if (this.passengerMinorHvac2[index] === 1) {
        rect.setAttribute('fill', this.orangeColor);
      }

      if (this.passengerMajorHvac2[index] === 1) {
        rect.setAttribute('fill', this.redColor);
      }
    } else {
      // default ekleme yapılacak
    }
  }

  private modifyRectsForDoorSystem(rect: SVGRectElement) {

    const index = this.wagonIndex[this.getWagonId(rect)] | 0;

    if (rect.id.includes('door1')) {
    } else if (rect.id.includes('door2')) {
    } else if (rect.id.includes('door3')) {
    } else if (rect.id.includes('door4')) {
    } else if (rect.id.includes('door5')) {
    } else if (rect.id.includes('door6')) {
    } else if (rect.id.includes('door7')) {
    } else {
      // default ekleme yapılacak
    }
  }

  private handleRectsClick(rect: SVGRectElement): string {
    // Define handlers for rect click events based on the active tab
    const tabHandlers: { [key: string]: (id: string) => string } = {
      tractionSystem: this.clickRectsForTractionSystem.bind(this),
      brakeSystem: this.clickRectsForBrakeSystem.bind(this),
      powerVoltageSystem: this.clickRectsForPowerVoltageSystem.bind(this),
      airConditionSystem: this.clickRectsForAirConditionSystem.bind(this),
      // Additional tab handlers can be added here
    };

    // Get the handler for the active tab and apply it to the clicked rect
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      this.selectedWagonIndex = this.wagonIndex[this.getWagonId(rect)] | 0;
      return handler(rect.id);
    }

    // Warn if no handler is found for the active tab
    console.warn(`No handler found for activeTab (click rect): ${this.activeTab}`);
    return '';
  }

  private clickRectsForTractionSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('cer')) {
      this.tractionTabInfo = 2;
      selectedPath = 'assets/images/cer-unit.svg';
    } else if (id.includes('bogi')) {
      this.tractionTabInfo = 3;
      selectedPath = 'assets/images/motor-bogi-unit.svg';
    } else {
      this.tractionTabInfo = 1;
      selectedPath = 'assets/images/train-traction.svg';
    }

    return selectedPath;
  }

  private clickRectsForBrakeSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('brake1')) {
      this.brakeTabInfo = 2;
      selectedPath = 'assets/images/brake-unit.svg';
    } else if (id.includes('brake2')) {
      this.brakeTabInfo = 3;
      selectedPath = 'assets/images/motor-bogi-unit.svg';
    } else {
      this.brakeTabInfo = 1;
      selectedPath = 'assets/images/train-traction.svg';
    }

    return selectedPath;
  }

  private clickRectsForPowerVoltageSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('transformer')) {
      this.powerVoltageTabInfo = 2;
      selectedPath = 'assets/images/transformer-unit.svg';
    } else if (id.includes('apu')) {
      this.powerVoltageTabInfo = 3;  // Set tab info for APU (auxiliary power unit)
      selectedPath = 'assets/images/apu-unit.svg';
    } else {
      this.powerVoltageTabInfo = 1;
      selectedPath = 'assets/images/train-powervoltage.svg';
    }

    return selectedPath;
  }

  private clickRectsForAirConditionSystem(id: string): string {
    let selectedPath: string;
    if (id.includes('driver-aircondition')) {
      this.airConditionTabInfo = 2;
      selectedPath = 'assets/images/aircondition-unit.svg';
    } else if (id.includes('passenger-aircondition1')) {
      this.airConditionTabInfo = 3;
      selectedPath = 'assets/images/aircondition-unit.svg';
    } else if (id.includes('passenger-aircondition2')) {
      this.airConditionTabInfo = 4;
      selectedPath = 'assets/images/aircondition-unit.svg';
    } else {
      this.airConditionTabInfo = 1;
      selectedPath = 'assets/images/train-aircondition.svg';
    }

    return selectedPath;
  }

  // Main function to modify texts based on the active tab
  private modifyTexts(svgContainer: HTMLElement): string {
    // Define handlers for each tab
    const tabHandlers: { [key: string]: (svgContainer: HTMLElement) => void } = {
      brakeSystem: this.modifyTextForBrakeSystem.bind(this),
      airConditionSystem: this.modifyTextForAirConditionSystem.bind(this),
      // Other tab handlers can be added here
    };

    // Call the handler for the active tab, if it exists
    const handler = tabHandlers[this.activeTab];
    if (handler) {
      handler(svgContainer);
    } else {
      console.warn(`No handler found for activeTab (modify text): ${this.activeTab}`);
    }

    return '';
  }

  // Function to create and append a text element near an SVG element
  private appendTextElement(
    parentElement: Element,
    textContent: string,
    bbox: DOMRect,
    offsetX: number = 10,
    offsetY: number = 0
  ): void {
    const textElem = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    // Positioning the text element next to the parent element (e.g., 'g' or 'path')
    const x = bbox.x + bbox.width + offsetX; // Place to the right of the element
    const y = bbox.y + bbox.height + offsetY; // Vertically center align

    textElem.setAttribute('x', x.toString());
    textElem.setAttribute('y', y.toString());
    textElem.setAttribute('fill', this.whiteColor);
    textElem.setAttribute('font-size', '16');
    textElem.setAttribute('font-family', 'Arial');
    // textElem.classList.add('label-text');
    textElem.textContent = textContent;

    parentElement.after(textElem);
  }

  // Handler for the brake system tab
  private modifyTextForBrakeSystem(svgContainer: HTMLElement): void {
    const groups = svgContainer.querySelectorAll('g');

    groups.forEach((g) => {
      if (g.classList.contains('text-pointer')) {

        const index = this.wagonIndex[this.getWagonId(g)] | 0; // index göre value belirlenecek!!!
        const value = this.getRandomInt(0, 10) + ' bar';

        const bbox = g.getBBox();
        this.appendTextElement(g, value, bbox);
      }
    });
  }

// Handler for the air condition system tab
  private modifyTextForAirConditionSystem(svgContainer: HTMLElement): void {
    const paths = svgContainer.querySelectorAll('path');

    paths.forEach((path) => {
      if (path.classList.contains('text-pointer')) {

        const index = this.wagonIndex[this.getWagonId(path)] | 0; // index göre value belirlenecek!!!
        const value = this.getRandomInt(-10, 50) + ' \u00B0C';

        const bbox = path.getBBox();
        this.appendTextElement(path, value, bbox);
      }
    });
  }

  protected mapContextData(context: any): void {
    this.positionEntity = context.positionEntity ? context.positionEntity.entity : context.result.posistionEntity.entity;
    this.entity = context.entity ? context.entity.entity : context.result.entity;

    // get source
    this.isAselsanTrain = super.getValue(this.entity, 'source') === ASELSAN_TRAIN_SOURCE_URL;
    // get the values to be displayed on UI
    this.hmiVehicleNo = super.getValue(this.entity, 'hmiVehicleNo');
    this.trainRef = super.getValue(this.positionEntity, 'trainRef');
    this.fDriveApplied[0] = super.getValue(this.positionEntity, 'fDriveApplied');
    this.pressBp = super.getValue(this.positionEntity, 'pressBp');
    this.pressMr = super.getValue(this.positionEntity, 'pressMr');
    this.vAscReq = super.getValue(this.positionEntity, 'vAscReq');
    this.voltPantoSka1[0] = super.getValue(this.positionEntity, 'voltPantoSka1');
    this.hmiJourneyNo = super.getValue(this.positionEntity, 'hmiJourneyNo');
    this.location = this.formattedLocation(super.getValue(this.positionEntity, 'location').coordinates.join(', '));
    const date = new Date(super.getValue(this.positionEntity, 'hmiJourneyNo', 'modifiedAt'));
    this.dotSeparatedDate = TimeUtil.serializeReadableDotSeparatedDate(date);
    this.date = TimeUtil.serializeCustomDatetime(date);
    this.trainId = this.trainRef.object.substring(this.trainRef.object.lastIndexOf(':') + 1);
    this.speed = super.getValue(this.positionEntity, 'speed');
    this.distanceTraveled[0] = super.getValue(this.positionEntity, 'distanceTraveled');
    this.ascLimit = super.getValue(this.positionEntity, 'ascLimit');
    this.tractionBar = super.getValue(this.positionEntity, 'tractionBar');
    this.tractionCutOffLed = this.boolMap.get(super.getValue(this.positionEntity, 'tractionCutOffLed'));
    this.majorUnderVoltageError = this.boolMap.get(super.getValue(this.positionEntity, 'majorUnderVoltageError'));
    this.mediumVoltageBackupEnabled2Vehicles = this.boolMap.get(super.getValue(this.positionEntity, 'mediumVoltageBackupEnabled2Vehicles'));
    this.catenaryVoltage = super.getValue(this.positionEntity, 'catenaryVoltage');
    this.pantoABar = super.getValue(this.positionEntity, 'pantoABar');
    this.pTrain = super.getValue(this.positionEntity, 'pTrain');
    this.bp = super.getValue(this.positionEntity, 'bp');
    this.mrp = super.getValue(this.positionEntity, 'mrp');
    this.edBar[0] = super.getValue(this.positionEntity, 'edBar');
    this.emergencyStatusBrakeLed[0] = this.boolMap.get(super.getValue(this.positionEntity, 'emergencyStatusBrakeLed'));
    this.driverAirConditioningTemperature = super.getValue(this.positionEntity, 'driverAirConditioningTemperature');
    this.externalTemperature = super.getValue(this.positionEntity, 'externalTemperature');
    this.door1LevelFault = this.boolMap.get(super.getValue(this.positionEntity, 'door1LevelFault'));
    this.door2LevelFault = this.boolMap.get(super.getValue(this.positionEntity, 'door2LevelFault'));
    this.doorLoopOkLed = this.boolMap.get(super.getValue(this.positionEntity, 'doorLoopOkLed'));
    this.predictiveMaintenanceCode = super.getValue(this.positionEntity, 'predictiveMaintenanceCode');
    this.checkStatus();
    this.setOtherValues();
    this.calculatePredictiveInfo();
    this.tempTrcInv1[0] = super.getValue(this.positionEntity, 'tempTrcInv1');
    this.tempTrcInv2[0] = super.getValue(this.positionEntity, 'tempTrcInv2');
    this.tempTrcRec[0] = super.getValue(this.positionEntity, 'tempTrcRec');
    this.tempApsRec[0] = super.getValue(this.positionEntity, 'tempApsRec');
    this.tempApsInv[0] = super.getValue(this.positionEntity, 'tempApsInv');
    this.tempMotorAx3[0] = super.getValue(this.positionEntity, 'tempMotorAx3');
    this.tempMotorAx4[0] = super.getValue(this.positionEntity, 'tempMotorAx4');
    this.tractionTransformerTemp[0] = super.getValue(this.positionEntity, 'tractionTransformerTemp');
    this.tractionDcLinkOk[0] = super.getValue(this.positionEntity, 'tractionDcLinkOk');
    this.vTrcDclink[0] = super.getValue(this.positionEntity, 'vTrcDclink');
    this.ApsDcLinkOk[0] = super.getValue(this.positionEntity, 'ApsDcLinkOk');
    this.vApsDclink[0] = super.getValue(this.positionEntity, 'vApsDclink');
    this.forceAvmaxAx3[0] = super.getValue(this.positionEntity, 'forceAvmaxAx3');
    this.forceAvmaxAx4[0] = super.getValue(this.positionEntity, 'forceAvmaxAx4');
    this.velMotorAx3[0] = super.getValue(this.positionEntity, 'velMotorAx3');
    this.velMotorAx4[0] = super.getValue(this.positionEntity, 'velMotorAx4');
    this.lcuMaintenance1[0] = super.getValue(this.positionEntity, 'lcuMaintenance1');
    this.setFirstData();
    this.setCommunicationList();
    this.setData();
  }

  checkStatus() {
    if (this.ascLimit > 177) {
      this.status[0] = '-red';
      this.hasError = true;
    } else {
      this.status[0] = '';
    }

    if (this.pantoABar > 50) {
      this.status[1] = '-red';
      this.hasError = true;
    } else {
      this.status[1] = '';
    }

    if (this.bp > 6) {
      this.status[2] = '-red';
      this.hasError = true;
    } else {
      this.status[2] = '';
    }

    if (this.mrp < 7) {
      this.status[3] = '-red';
      this.hasError = true;
    } else {
      this.status[3] = '';
    }
  }

  setOtherValues() {
    this.diagComp1 = 0;
    this.diagComp2 = 0;
    this.kismiPnomatikFrenKaybi = 0;
    this.erisilebilirKompresorYok = 0;
    this.enAz1EbcuHatali = 0;
    this.mrDusuk = 0;
    this.frenUygulamaHatasi = [0, 0, 0, 0, 0];
    this.frenBirakmaHatasi = 0;
    this.havaSuspansiyonHatas = 0;
    this.kumlamaHatasi = 0;
    this.parkFreniHatasi = [0, 0, 0, 0, 0];
    this.birdenFazlaVakumDevrede = 0;
    this.birdenFazlaPantoKaldirilamaz = 0;
    this.ortaGerilimKontaktorKontrolHatasi = 0;
    this.ortaGerilimHatasiKabinDegisikligi = 0;
    this.cutOffDongusuBaypasEdilmistir = 0;
    this.siviSogutmaBakim = 0;
    this.siviSogutmaAcilBakim = 0;
    this.minorAriza = 0;
    this.majorAriza = 0;
    this.agirAriza = 0;
    this.ickapi2SeviyeAriza = 0;
    this.kapi2SeviyeAriza = 0;
    this.prmAsansorKapi2Devrede = 0;
    this.tkysHaberlesmeHatasi = 0;
    this.tkysFrenHaberlesmeHatasi = 0;
    this.tkysCerHaberlesmeHatasi = 0;
    this.wtbHaberlesmeHatasi = 0;
  }

  private calculatePredictiveInfo() {
    if (this.predictiveMaintenanceCode === 3 || this.predictiveMaintenanceCode === 5 || this.predictiveMaintenanceCode === 7
      || this.predictiveMaintenanceCode === 11 || this.predictiveMaintenanceCode === 12 || this.predictiveMaintenanceCode === 13) {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + this.predictiveMaintenanceCode;
      this.status[4] = '-red';
    } else {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + 0;
      this.status[4] = '';
    }

    if (this.predictiveMaintenanceCode === 1) {
      this.status[5] = '-red';
    } else {
      this.status[5] = '';
    }
    if (this.predictiveMaintenanceCode === 2 || this.predictiveMaintenanceCode === 3) {
      this.status[6] = '-red';
    } else {
      this.status[6] = '';
    }
    if (this.predictiveMaintenanceCode === 4) {
      this.status[7] = '-red';
    } else {
      this.status[7] = '';
    }
    if (this.predictiveMaintenanceCode === 5) {
      this.status[8] = '-red';
    } else {
      this.status[8] = '';
    }
    if (this.predictiveMaintenanceCode === 6) {
      this.status[9] = '-red';
    } else {
      this.status[9] = '';
    }
    if (this.predictiveMaintenanceCode === 7) {
      this.status[10] = '-red';
    } else {
      this.status[10] = '';
    }
    this.controlBrakeSystemStatus();
  }

  private setCommunicationList() {
    this.mvbComList.push(new MvbCommunication({
      ska1: 'BCU',
      oa1: 'BCU',
      oa2: 'BCU',
      oa3: 'BCU',
      ska2: 'BCU'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'RIOM 1 EC',
      oa1: 'RIOM 1 EC',
      oa2: 'RIOM 1 EC',
      oa3: 'RIOM 1 EC',
      ska2: 'RIOM 1 EC',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'TCU',
      oa1: 'TCU',
      oa2: 'TCU',
      oa3: 'TCU',
      ska2: 'TCU'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'RIOM 2 EC',
      oa1: 'RIOM 2 EC',
      oa2: 'RIOM 2 EC',
      oa3: 'RIOM 2 EC',
      ska2: 'RIOM 2 EC',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'WTBMVB(0521)',
      oa1: '',
      oa2: '',
      oa3: 'OLAY KAYDEDİCİ',
      ska2: 'WTBMVB(0521)',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'CCU',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'CCU'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'RIOM 1 CD',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'RIOM 1 CD',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'RIOM 2 CD',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'RIOM 2 CD',
    }));


    this.camComList.push(new CanCommunication({
      riom1: 'Klima-Cab',
      riom2: 'PAPIS',
      riom3: 'Enerji Metre',
      riom4: 'Al. Duv. Kapı',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'CCTV',
      riom3: 'Klima-2',
      riom4: 'Klima-1',
    }));

    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'FDU',
      riom3: 'Kapı-4',
      riom4: 'Kapı-3',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'Kapı-1',
      riom3: 'WC',
      riom4: 'İç Kapı-2',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'İç Kapı-1',
      riom3: 'Kapı-2',
      riom4: '',
    }));
  }

  hasAnyError() {
    if (this.status[0] || this.status[1] || this.status[2]
      || this.status[3] || this.status[4]) {
      return true;
    }
  }

  subscribeWebsocket(): void {
    this.socketService.subscribe('20d7a274-337e-46e3-b85a-8bac9d157106')
      .pipe(
      )
      .subscribe(notification => {
        this.websocketCallback(notification);
      });
  }

  protected websocketCallback(notification: Notification): void {
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.entityId.includes('TrainPosition')) {
        if (notification.eventPayload.eventType === NGSIEventType.CREATE && this.trainRef.object === notification.eventPayload.content.trainRef.object) {
          this.setLiveTrainInfo(notification.eventPayload.content);
          this.addNotification(notification.eventPayload.timestamp, notification.eventPayload.content.trainRef);
        }
      }
    }
  }

  protected setLiveTrainInfo(content) {
    this.liveTrainData = new TrainDefinition({
      fDriveApplied: content.fDriveApplied.value,
      voltPantoSka1: content.voltPantoSka1.value,
      hmiJourneyNo: content.hmiJourneyNo.value,
      location: this.formattedLocation(content.location.value.coordinates.join(', ')),
      speed: content.speed.value,
      distanceTraveled: content.distanceTraveled.value,
      ascLimit: content.ascLimit.value,
      tractionBar: content.tractionBar.value,
      tractionCutOffLed: this.boolMap.get(content.tractionCutOffLed.value),
      majorUnderVoltageError: this.boolMap.get(content.majorUnderVoltageError.value),
      mediumVoltageBackupEnabled2Vehicles: this.boolMap.get(content.mediumVoltageBackupEnabled2Vehicles.value),
      catenaryVoltage: content.catenaryVoltage.value,
      pantoABar: content.pantoABar.value,
      pTrain: content.pTrain.value,
      bp: content.bp.value,
      mrp: content.mrp.value,
      edBar: content.edBar.value,
      emergencyStatusBrakeLed: this.boolMap.get(content.emergencyStatusBrakeLed.value),
      driverAirConditioningTemperature: content.driverAirConditioningTemperature.value,
      externalTemperature: content.externalTemperature.value,
      door1LevelFault: this.boolMap.get(content.door1LevelFault.value),
      door2LevelFault: this.boolMap.get(content.door2LevelFault.value),
      doorLoopOkLed: this.boolMap.get(content.doorLoopOkLed.value),
      predictiveMaintenanceCode: content.predictiveMaintenanceCode.value,
      tempTrcInv1: content.tempTrcInv1.value,
      tempTrcInv2: content.tempTrcInv2.value,
      tempTrcRec: content.tempTrcRec.value,
      tempApsRec: content.tempApsRec.value,
      tempApsInv: content.tempApsInv.value,
      tempMotorAx3: content.tempMotorAx3.value,
      tempMotorAx4: content.tempMotorAx4.value,
      tractionTransformerTemp: content.tractionTransformerTemp.value,
      tractionDcLinkOk: content.tractionDcLinkOk.value,
      vTrcDclink: content.vTrcDclink.value,
      ApsDcLinkOk: content.ApsDcLinkOk.value,
      vApsDclink: content.vApsDclink.value,
      forceAvmaxAx3: content.forceAvmaxAx3.value,
      forceAvmaxAx4: content.forceAvmaxAx4.value,
      velMotorAx3: content.velMotorAx3.value,
      velMotorAx4: content.velMotorAx4.value,
      lcuMaintenance1: content.lcuMaintenance1.value,
    });
    this.setData();
  }

  protected setFirstData() {
    this.firstTrainData = new TrainDefinition({
      fDriveApplied: this.fDriveApplied[0],
      voltPantoSka1: this.voltPantoSka1[0],
      hmiJourneyNo: this.hmiJourneyNo,
      location: this.formattedLocation(this.location),
      speed: this.speed.value,
      distanceTraveled: this.distanceTraveled[0],
      ascLimit: this.ascLimit,
      tractionBar: this.tractionBar,
      tractionCutOffLed: this.tractionCutOffLed,
      majorUnderVoltageError: this.majorUnderVoltageError,
      mediumVoltageBackupEnabled2Vehicles: this.mediumVoltageBackupEnabled2Vehicles,
      catenaryVoltage: this.catenaryVoltage,
      pantoABar: this.pantoABar,
      pTrain: this.pTrain,
      bp: this.bp,
      mrp: this.mrp,
      edBar: this.edBar[0],
      emergencyStatusBrakeLed: this.emergencyStatusBrakeLed[0],
      driverAirConditioningTemperature: this.driverAirConditioningTemperature,
      externalTemperature: this.externalTemperature,
      door1LevelFault: this.door1LevelFault,
      door2LevelFault: this.door2LevelFault,
      doorLoopOkLed: this.doorLoopOkLed,
      predictiveMaintenanceCode: this.predictiveMaintenanceCode,
      tempTrcInv1: this.tempTrcInv1[0],
      tempTrcInv2: this.tempTrcInv2[0],
      tempTrcRec: this.tempTrcRec[0],
      tempApsRec: this.tempApsRec[0],
      tempApsInv: this.tempApsInv[0],
      tempMotorAx3: this.tempMotorAx3[0],
      tempMotorAx4: this.tempMotorAx4[0],
      tractionTransformerTemp: this.tractionTransformerTemp[0],
      tractionDcLinkOk: this.tractionDcLinkOk[0],
      vTrcDclink: this.vTrcDclink[0],
      ApsDcLinkOk: this.ApsDcLinkOk[0],
      vApsDclink: this.vApsDclink[0],
      forceAvmaxAx3: this.forceAvmaxAx3[0],
      forceAvmaxAx4: this.forceAvmaxAx4[0],
      velMotorAx3: this.velMotorAx3[0],
      velMotorAx4: this.velMotorAx4[0],
      lcuMaintenance1: this.lcuMaintenance1[0]
    });
    this.liveTrainData = this.firstTrainData;
  }

  protected addNotification(date, trainRef) {
    const refList = trainRef.object.split(':');
    const refId = refList[3];

    this.trainNotificationList.unshift(new TrainNotification({
      id: this.notificationCount++,
      observedat: this.calculateDate(date),
      description: 'Tren :' + refId,
    }));

    if (this.trainNotificationList.length > 15) {
      this.trainNotificationList.pop();
    }
  }

  // 0 = Vehicle Cannot Move
  // 1 = Vehicle Can Move
  isVehicleMove(value: number): string {
    switch (value) {
      case 0:
        return 'vehicleCannotMove';
      case 1:
        return 'vehicleCanMove';
      default:
        return 'vehicleCannotMove';
    }
  }

  isBrakeError(context: string, value: number): string {
    switch (value) {
      case 0:
        if (context === 'pressureGovernorDetectionEmergencyBrake') {
          return 'emergencyBrakeDetected';
        } else if (context === 'pressureGovernorIndirectBrakeControl') {
          return 'indirectBrakeDetected';
        } else {
          return 'vehicleCannotMove';
        }
      case 1:
        return 'vehicleCanMove';
    }
  }

  // 0 = Pantograph Down
  // 1 = Pantograph Up
  getPantographStatus(value: number): string {
    return value === 0 ? 'pantographDown' : 'pantographUp';
  }

  // mod = 0 inactive
  // mod = 1 manuel
  // mod = 2 automatic
  getAirConditionMod(mod: number): string {
    switch (mod) {
      case 1:
        return 'manualMod';
      case 2:
        return 'automaticMod';
      default:
        return 'inActiveMod';
    }
  }

  // 0 = Door Bypass inactive
  // 1 = Door Bypass active
  getDoorBypassStatus(value: number): string {
    return value === 0 ? 'bypassModeInactive' : 'bypassModeActive';
  }

  // 0 = Light Off
  // 1 = Emergency Light
  // 2 = Half Light
  // 3 = Full Lighting
  getInteriorLightingMode(mode: number): string {
    switch (mode) {
      case 0:
        return 'lightingOff'; // Off
      case 1:
        return 'emergencyLighting'; // Emergency Lighting
      case 2:
        return 'halfLighting'; // Half Lighting
      case 3:
        return 'fullLighting'; // Full Lighting
      default:
        return 'lightingOff';  // Off
    }
  }

  tractionSystemRules = {
    cutOffTraction: {0: this.redColor, default: this.whiteColor},
    cutOffByPass: {1: this.blueColor, default: this.whiteColor},
    tractionNull: {0: this.redColor, default: this.whiteColor}
  };

  brakeSystemRules = {
    pressureGovernorPBReleased: {0: this.redColor, default: this.whiteColor},
    pressureGovernorDetectionEmergencyBrake: {0: this.redColor, default: this.whiteColor},
    pressureGovernorIndirectBrakeControl: {0: this.redColor, default: this.whiteColor},
    diagBrDirect: {1: this.redColor, default: this.whiteColor},
    diagBrCPress: {1: this.redColor, default: this.whiteColor}
  };

  powerVoltageSystemRules = {
    vcbCloseSkax: {1: this.blueColor, default: this.whiteColor},
    pressurePs1SafeguardAddSkax: {1: this.blueColor, default: this.whiteColor},
    hvDisconnectorCloseSKA1: {1: this.blueColor, default: this.whiteColor},
    hvDisconnectorCloseSKA2: {1: this.blueColor, default: this.whiteColor},
    hvDisconnectorCloseOA2: {1: this.blueColor, default: this.whiteColor},
    tractionTransformerOilFlow: {1: this.redColor, default: this.whiteColor},
    tractionTransformerOilLevel: {1: this.redColor, default: this.whiteColor},
    tractionTransformerOverPressure: {1: this.redColor, default: this.whiteColor},
    tractionTransformerTemp: (value: number) => {
      if (value < 55) return this.whiteColor;
      if (value > 110) return this.redColor;
      return this.orangeColor;
    },
    tempApsInv: {1: this.redColor, default: this.whiteColor},
    tempApsRec: {1: this.redColor, default: this.whiteColor},
    vApsDclink: (value: number) => (value < 800 ? this.whiteColor : this.redColor),
    measuringBatteryVoltage: (value: number) => {
      if (value > 90) return this.whiteColor;
      if (value < 80) return this.redColor;
      return this.orangeColor;
    },
    tempBattery: (value: number) => (value > -10 && value < 40 ? this.whiteColor : this.redColor)
  };

  airConditionSystemRules = {
    majorHvac: {1: this.redColor, default: this.whiteColor},
    minorHvac: {1: this.orangeColor, default: this.whiteColor},
  };

  doorSystemRules = {
    slDoorControlByPass: {1: this.blueColor, default: this.whiteColor}
  };

  otherSystemRules = {
    toiletIsNotActive: {1: this.redColor, default: this.whiteColor},
    toiletWaterLevelLow: {1: this.redColor, default: this.whiteColor},
    toiletBowlFull: {1: this.redColor, default: this.whiteColor},
    toiletWastewaterTankFull: {1: this.redColor, default: this.whiteColor},
    toiletSosSignal: {1: this.redColor, default: this.whiteColor}
  };

  signalizationSystemRules = {
    etcsIsolationByPass: {1: this.redColor, default: this.whiteColor},
    ertmsCutOff: {0: this.redColor, default: this.whiteColor},
    atsByPass: {1: this.redColor, default: this.whiteColor},
    ertmsServiceBrake: {1: this.redColor, default: this.whiteColor},
  };

  getDynamicColor(rules: any, context: string, value: number): string {
    const rule = rules[context];

    // context'in rule'lar arasında tanımlı olup olmadığını kontrol edin
    if (!rule) {
      console.error(`Context "${context}" not found in rules.`);
      return this.whiteColor;  // Varsayılan bir renk döndürün
    }

    if (typeof rule === 'function') {
      return rule(value);
    }

    return rule[value] !== undefined ? rule[value] : rule.default || this.whiteColor;
  }

  determineInteriorLightingMode(halfLight: number, emergencyLight: number): number {
    let mode: number;

    if (halfLight === 1 && emergencyLight === 1) {
      mode = 3;  // Tam aydınlatma
    } else if (halfLight === 1 && emergencyLight === 0) {
      mode = 2;  // Yarı aydınlatma
    } else if (halfLight === 0 && emergencyLight === 1) {
      mode = 1;  // Acil durum aydınlatma
    } else {
      mode = 0;  // Kapalı
    }

    // Contactor light status is determined by the lighting system
    this.contactorLightOn = mode === 3 ? 1 : 0;

    return mode;
  }

  protected setData() {

    // Traction
    this.fDriveForceAx3 = this.getRandomInt(0, 10000);
    this.fDriveForceAx4 = this.getRandomInt(0, 10000);
    this.requiredTractionForce = (this.fDriveForceAx3 + this.fDriveForceAx4) / 1000;

    this.forceAppliedAx3 = this.getRandomInt(0, 10000);
    this.forceAppliedAx4 = this.getRandomInt(0, 10000);
    this.appliedTractionForce = (this.forceAppliedAx3 + this.forceAppliedAx4) / 1000;

    this.cutOffTraction = this.getRandomInt(0, 1);
    this.cutOffByPass = this.getRandomInt(0, 1);
    this.tractionNull = this.getRandomInt(0, 1);

    // Brake
    this.bp = this.isLiveMode ? this.liveTrainData.bp : this.firstTrainData.bp;
    this.mrp = this.isLiveMode ? this.liveTrainData.mrp : this.firstTrainData.mrp;
    this.pressureGovernorPBReleased = this.getRandomInt(0, 1);
    this.pressureGovernorDetectionEmergencyBrake = this.getRandomInt(0, 1);
    this.pressureGovernorIndirectBrakeControl = this.getRandomInt(0, 1);
    this.diagBrDirect = this.getRandomInt(0, 1);
    this.majorBrPark = this.getRandomInt(0, 1);
    this.majorBrWsp = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.diagBrCPress = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];

    // Power Voltage
    this.majorUnderVoltageError = this.isLiveMode ? this.liveTrainData.majorUnderVoltageError : this.firstTrainData.majorUnderVoltageError;
    this.catenaryVoltage = this.isLiveMode ? this.liveTrainData.catenaryVoltage : this.firstTrainData.catenaryVoltage;
    this.yuksekAkimKaynakliVakumHatasi = this.getRandomInt(0, 1);
    this.hvConfigError = this.getRandomInt(0, 1);
    this.pantoABar = this.isLiveMode ? this.liveTrainData.pantoABar : this.firstTrainData.pantoABar;
    this.tumPanolarIzole = this.getRandomInt(0, 1);
    this.pantografDustu = this.getRandomInt(0, 1);
    this.diagPantoComp = this.getRandomInt(0, 1);
    this.pTrain = this.isLiveMode ? this.liveTrainData.pTrain : this.firstTrainData.pTrain;
    this.vcbCloseSkax = this.getRandomInt(0, 1);
    this.pressurePs1SafeguardAddSkax = this.getRandomInt(0, 1);
    this.majorMvError = this.getRandomInt(0, 1);
    this.hvDisconnectorCloseSKA1 = this.getRandomInt(0, 1);
    this.hvDisconnectorCloseSKA2 = this.getRandomInt(0, 1);
    this.hvDisconnectorCloseOA2 = this.getRandomInt(0, 1);
    this.tractionTransformerOilFlow = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.tractionTransformerOilLevel = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.tractionTransformerOverPressure = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.measuringBatteryVoltage = [(this.getRandomInt(0, 160)) * 15.71, (this.getRandomInt(0, 160)) * 15.71, (this.getRandomInt(0, 160)) * 15.71, (this.getRandomInt(0, 160)) * 15.71, (this.getRandomInt(0, 160)) * 15.71];
    this.tempBattery = [this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127)];
    this.aBatteryCharger = [this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127), this.getRandomInt(-128, 127)];

    // Air Condition
    this.driverAirConditioningTemperature = this.isLiveMode ? this.liveTrainData.driverAirConditioningTemperature : this.firstTrainData.driverAirConditioningTemperature;
    this.passengerAirConditioningMode = this.getRandomInt(0, 3);
    this.hvacsInhibition_X = this.getRandomInt(0, 1);
    this.driverMajorHvac = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.passengerMajorHvac1 = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.passengerMajorHvac2 = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.driverMinorHvac = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.passengerMinorHvac1 = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];
    this.passengerMinorHvac2 = [this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1), this.getRandomInt(0, 1)];

    // Door
    this.door1LevelFault = this.isLiveMode ? this.liveTrainData.door1LevelFault : this.firstTrainData.door1LevelFault;
    this.door2LevelFault = this.isLiveMode ? this.liveTrainData.door2LevelFault : this.firstTrainData.door2LevelFault;
    this.ickapi1SeviyeAriza = this.getRandomInt(0, 1);
    this.kapi1SeviyeAriza = this.getRandomInt(0, 1);
    this.prmAsansorKapi1Devrede = this.getRandomInt(0, 1);
    this.doorLoopOkLed = this.isLiveMode ? this.liveTrainData.doorLoopOkLed : this.firstTrainData.doorLoopOkLed;
    this.slDoorControlByPass = this.getRandomInt(0, 1);
    this.externalTemperature = this.isLiveMode ? this.liveTrainData.externalTemperature : this.firstTrainData.externalTemperature;
    this.predictiveMaintenanceCode = this.isLiveMode ? this.liveTrainData.predictiveMaintenanceCode : this.firstTrainData.predictiveMaintenanceCode;

    // Other
    this.contactorHalfLight = this.getRandomInt(0, 1);
    this.contactorEmergencyLight = this.getRandomInt(0, 1);
    this.interiorLightingMode = this.determineInteriorLightingMode(
      this.contactorHalfLight,
      this.contactorEmergencyLight
    );

    this.toiletIsNotActive = this.getRandomInt(0, 1);
    this.toiletWaterLevelLow = this.getRandomInt(0, 1);
    this.toiletBowlFull = this.getRandomInt(0, 1);
    this.toiletWastewaterTankFull = this.getRandomInt(0, 1);
    this.toiletSosSignal = this.getRandomInt(0, 1);

    // Signalization
    this.etcsIsolationByPass = this.getRandomInt(0, 1);
    this.ertmsCutOff = this.getRandomInt(0, 1);
    this.atsByPass = this.getRandomInt(0, 1);
    this.ertmsServiceBrake = this.getRandomInt(0, 1);

    // Unknown
    this.tractionBar = this.isLiveMode ? this.liveTrainData.tractionBar : this.firstTrainData.tractionBar;
    this.tractionCutOffLed = this.isLiveMode ? this.liveTrainData.tractionCutOffLed : this.firstTrainData.tractionCutOffLed;
    this.hmiJourneyNo = this.isLiveMode ? this.liveTrainData.hmiJourneyNo : this.firstTrainData.hmiJourneyNo;
    this.location = this.isLiveMode ? this.formattedLocation(this.liveTrainData.location) : this.formattedLocation(this.firstTrainData.location);
    this.ascLimit = this.isLiveMode ? this.liveTrainData.ascLimit : this.firstTrainData.ascLimit;
    this.mediumVoltageBackupEnabled2Vehicles = this.isLiveMode ? this.liveTrainData.mediumVoltageBackupEnabled2Vehicles : this.firstTrainData.mediumVoltageBackupEnabled2Vehicles;

    for (let index = 0; index < 5; index++) {
      this.fDriveApplied[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.fDriveApplied : this.firstTrainData.fDriveApplied) : this.getRandomInt(0, 10);
      this.voltPantoSka1[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.voltPantoSka1 : this.firstTrainData.voltPantoSka1) : this.getRandomInt(0, 10);
      this.distanceTraveled[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.distanceTraveled : this.firstTrainData.distanceTraveled) : this.getRandomInt(0, 10);
      this.edBar[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.edBar : this.firstTrainData.edBar) : this.getRandomInt(-10, 50); // traction
      this.emergencyStatusBrakeLed[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.emergencyStatusBrakeLed : this.firstTrainData.emergencyStatusBrakeLed) : this.getRandomInt(0, 10);
      this.tempTrcInv1[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempTrcInv1 : this.firstTrainData.tempTrcInv1) : this.getRandomInt(-10, 50); // traction
      this.tempTrcInv2[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempTrcInv2 : this.firstTrainData.tempTrcInv2) : this.getRandomInt(-10, 50); // traction
      this.tempTrcRec[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempTrcRec : this.firstTrainData.tempTrcRec) : this.getRandomInt(-10, 50); // traction
      this.tempApsRec[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempApsRec : this.firstTrainData.tempApsRec) : this.getRandomInt(0, 1); // power Voltage
      this.tempApsInv[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempApsInv : this.firstTrainData.tempApsInv) : this.getRandomInt(0, 1); // power Voltage
      this.tempMotorAx3[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempMotorAx3 : this.firstTrainData.tempMotorAx3) : this.getRandomInt(-10, 50); // traction
      this.tempMotorAx4[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tempMotorAx4 : this.firstTrainData.tempMotorAx4) : this.getRandomInt(-10, 50); // traction
      this.tractionTransformerTemp[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tractionTransformerTemp : this.firstTrainData.tractionTransformerTemp) : this.getRandomInt(-128, 127); // power Voltage
      this.tractionTransformerTemp[index] += this.tractionTransformerTemp[index] + 78;
      this.tractionDcLinkOk[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.tractionDcLinkOk : this.firstTrainData.tractionDcLinkOk) : this.getRandomInt(0, 10);
      this.vTrcDclink[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.vTrcDclink : this.firstTrainData.vTrcDclink) : this.getRandomInt(-10, 50); // traction
      this.vApsDclink[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.vApsDclink : this.firstTrainData.vApsDclink) : this.getRandomInt(0, 255); // power Voltage
      this.vApsDclink[index] += this.vApsDclink[index] * 5;
      this.ApsDcLinkOk[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.ApsDcLinkOk : this.firstTrainData.ApsDcLinkOk) : this.getRandomInt(0, 10);
      this.forceAvmaxAx3[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.forceAvmaxAx3 : this.firstTrainData.forceAvmaxAx3) : this.getRandomInt(-10, 50); // traction
      this.forceAvmaxAx4[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.forceAvmaxAx4 : this.firstTrainData.forceAvmaxAx4) : this.getRandomInt(-10, 50); // traction
      this.velMotorAx3[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.velMotorAx3 : this.firstTrainData.velMotorAx3) : this.getRandomInt(-10, 50); // traction
      this.velMotorAx4[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.velMotorAx4 : this.firstTrainData.velMotorAx4) : this.getRandomInt(-10, 50); // traction
      this.lcuMaintenance1[index] = index === 0 ? (this.isLiveMode ? this.liveTrainData.lcuMaintenance1 : this.firstTrainData.lcuMaintenance1) : this.getRandomInt(0, 10);
    }

    this.checkStatus();
    this.calculatePredictiveInfo();
  }

  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public refreshData() {
    if (!this.isLiveMode) {
      this.isLiveMode = 1;
      this.setData();
    }
  }

  public getFirstData() {
    if (this.isLiveMode) {
      this.isLiveMode = 0;
      this.setData();
    }
  }

  public controlBrakeSystemStatus() {
    if (this.predictiveMaintenanceCode === 1 || this.predictiveMaintenanceCode === 2 ||
      this.predictiveMaintenanceCode === 3 || this.predictiveMaintenanceCode === 4 ||
      this.predictiveMaintenanceCode === 5 || this.predictiveMaintenanceCode === 6 ||
      this.predictiveMaintenanceCode === 7 || this.status[2] === '-red ' ||
      this.status[3] === '-red') {
      this.tractionSystem = true;
    } else {
      this.tractionSystem = false;
    }
  }

  public calculateDate(date) {
    const [datePart, timePart] = date.split('T');
    const cleanedTimePart = timePart.replace('Z', '');
    const [year, month, day] = datePart.split('-');
    const formattedDate = `${day}.${month}.${year} ${cleanedTimePart}`;
    return formattedDate;
  }

  public formattedLocation(location) {
    const [lat, lon] = location.split(', ');
    const formattedLat = parseFloat(lat).toFixed(3);
    const formattedLon = parseFloat(lon).toFixed(3);
    return `${formattedLat}, ${formattedLon}`;
  }
}
