import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from '../../../shared/models/menu/menu-item';
import {BaseComponent} from '../../../shared/components/base.component';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { UserPreference } from 'app/shared/models/user-preference.model';
import { Subject } from 'rxjs';
import { ObjectUtil } from 'app/shared/utils/object-util';

/**
 * Displays the given menu item with its children in a recursive manner.
 * */
@Component({
  selector: 'uruk-menu-item',
  styleUrls: ['./menu-item.component.scss'],
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent extends BaseComponent implements OnInit {

  @Input() menuItem: MenuItem;
  // keeps whether the icon of menu item will be displayed or not
  @Input() displayMenuItemIcon: boolean = true;
  // emits event to mark parent menu item
  @Output() markParentMenuItem: EventEmitter<void> = new EventEmitter<void>();

  // keeps the path to selected menu item
  public pathToSelectedMenuItem: string[] = [];
  // whether the children menu items should be displayed
  public displayChildrenMenuItems: boolean = false;
  // whether the menu item title should be marked. If so, there will be a mark next to menu item title
  public marked: boolean = false;

  // keeps the children of menu item as follows:
  //  [0] : the child menu items which are draggable
  //  [1] : the child menu items which can not be dragged
  public childrenItems: [MenuItem[],MenuItem[]];

  // user preferences
  private userPreferences: UserPreference;
  // subject to keep track of update user preference requests
  private updateUserPreferenceSubject:Subject<void> = new Subject();

  constructor(injector: Injector,
              private router: Router) {
    super(injector);
  }

  ngOnInit() {
    this.subscribeToData();
    // filter the children items
    this.childrenItems = [this.menuItem.children.filter(i => !i.dragDisabled),this.menuItem.children.filter(i => i.dragDisabled)];
  }

  /**
   * Handles the click on menu item.
   * */
  public onMenuItemClicked() {
    // if the menu item has a url, navigate the user to it
    if (this.menuItem.url) {
      // open the url in a new tab
      window.open(this.menuItem.url, '_blank');
    }
    // if the menu item has a url, navigate the user to it
    else if (this.menuItem.link) {
      this.router.navigate([this.menuItem.link]);
    } else if (this.menuItem.callbackFunction) {
      this.menuItem.callbackFunction();
    }

    // whether the menu item is expanded before the click
    const isMenuItemExpandedPreviously = this.pathToSelectedMenuItem.includes(this.menuItem.id);
    // collapse or expand menu item according to isMenuItemExpandedPreviously variable
    this.menuService.onMenuItemSelected(this.menuItem.id, isMenuItemExpandedPreviously);
  }

  /**
   * Marks the menu item.
   * */
  public markMenuItem() {
    setTimeout(() => {
        this.marked = true;
    });
  }

  /**
   * Handles the reordering of menu items.
   * @param event CdkDragDrop event
   */
  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.childrenItems[0], event.previousIndex, event.currentIndex);
    this.updateUserPreferenceSubject.next();
  }

  /**
   * Updates the user preferences to apply the new ordering of menu items.
  */
  private updateUserPreference(){
    // get the page ids
    const pageIds = this.childrenItems[0].map(item => item.link.substring(item.link.lastIndexOf("/")+1));

    // copy user preference
    const userPreference = ObjectUtil.deepCopy(UserPreference,this.userPreferences);
    if (userPreference.pageIds.includes(pageIds[0])) {
      userPreference.pageIds = pageIds;
    }
    else {
      const index = userPreference.pageGroups.findIndex(group => group.pageIds.includes(pageIds[0]));
      userPreference.pageGroups[index].pageIds = pageIds;
    }

    this.userPreferenceService.updateUserPreference(this.userService.userId.getValue(), userPreference).subscribe();
  }

  private subscribeToData() {
    this.menuService.pathToSelectedMenuItem.pipe(takeUntil(this.destroy$)).subscribe(pathToSelectedMenuItem => {
      this.pathToSelectedMenuItem = pathToSelectedMenuItem;
      // check whether we need to display children menu items
      this.displayChildrenMenuItems = this.menuItem.children?.length && this.pathToSelectedMenuItem.includes(this.menuItem.id);
      // clear the mark
      this.marked = false;
      // mark the menu item if it is the last one in the path
      if (this.pathToSelectedMenuItem[this.pathToSelectedMenuItem.length - 1] === this.menuItem.id) {
        this.markMenuItem();
      }
    });
    this.userService.onUserPreferencesChanged().pipe(
      takeUntil(this.destroy$)
    ).subscribe(userPreferences => {
      this.userPreferences = userPreferences;
    });

    this.updateUserPreferenceSubject.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.updateUserPreference();
    })
  }
}
