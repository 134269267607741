import {BaseMetaModel} from './base/base-meta-model.model';
export class TrainNotification extends BaseMetaModel {

  observedat: string;
  description: string;
  id: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.observedat = data.observedat;
    this.description = data.description;
    this.id = data.id;
  }

}
