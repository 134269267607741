export class StorageUtil {
  private static KEY_STATE = 'state';
  private static KEY_NONCE = 'nonce';
  private static KEY_ID_TOKEN = 'id_token';
  private static KEY_UMA_TICKET = 'uma_token'; // includes all permissions granted by the server unlike id_token
  private static REFRESH_TOKEN = 'refresh_token';
  private static PRELOADER_DISPLAYED = 'preloader_displayed';

  public static getJWT(): string {
    return localStorage.getItem(this.KEY_ID_TOKEN);
  }

  public static setJWT(data: string) {
    localStorage.setItem(this.KEY_ID_TOKEN, data);
  }

  public static getUMATicket(): string {
    return localStorage.getItem(this.KEY_UMA_TICKET);
  }

  public static setUMATicket(data: string) {
    localStorage.setItem(this.KEY_UMA_TICKET, data);
  }

  public static removeUMATicket() {
    localStorage.removeItem(this.KEY_UMA_TICKET);
  }

  public static getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public static setRefreshToken(data: string) {
    localStorage.setItem(this.REFRESH_TOKEN, data);
  }

  public static removeRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  public static removeJWT() {
    localStorage.removeItem(this.KEY_ID_TOKEN);
  }


  public static getState(): string {
    return sessionStorage.getItem(this.KEY_STATE);
  }

  public static setState(data: string) {
    sessionStorage.setItem(this.KEY_STATE, data);
  }

  public static removeState() {
    sessionStorage.removeItem(this.KEY_STATE);
  }


  public static getNonce(): string {
    return sessionStorage.getItem(this.KEY_NONCE);
  }

  public static setNonce(data: string) {
    sessionStorage.setItem(this.KEY_NONCE, data);
  }

  public static removeNonce() {
    sessionStorage.removeItem(this.KEY_NONCE);
  }

  public static getPreloaderDisplayed() {
    return sessionStorage.getItem(this.PRELOADER_DISPLAYED);
  }

  public static setPreloaderDisplayed(data: string) {
    sessionStorage.setItem(this.PRELOADER_DISPLAYED, data);
  }

}
